import ReactApexChart from 'react-apexcharts';

const LineChart = (props) => {
    const bg_color = "#aca6ff";
    const series = props.series;
    const categories = props.categories;

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false
            }
        },
        colors: [bg_color],
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    fontSize: '10px',
                    colors: '#74767e',
                }
            },
        },
        yaxis: {
            show: false,
        },
        grid: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '9px',
                fontWeight: 'bold',
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                borderRadius: 5,
            },
        },
        tooltip: {
            enabled: false
        }
    };

    return (
        <div className="h-100">
            <div id="chart" className="h-100">
                <ReactApexChart options={options} series={series} type="line" height={'100%'} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default LineChart;
