import {useEffect, useState} from "react";
import ColumnChart from "./ColumnChart";
import {MdRemoveRedEye} from "react-icons/md";
import {analyticsService} from "../../../_services";

const StoreVisits = (props) => {
    const [total, setTotal] = useState(0);
    const [storeVisitsData, setStoreVisitsData] = useState([]);
    const period = props.period;
    const periodNum = period === 'sevenDays' ? 7 : 14;
    let series;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storeVisitsData = await analyticsService.getStoreVisit(periodNum);
                setStoreVisitsData(storeVisitsData);
                const chart_values = Object.values(storeVisitsData);
                const sum = chart_values.reduce((acc, curr) => acc + curr, 0);
                setTotal(sum);
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [period, total]);

    const chart_categories = Object.keys(storeVisitsData);
    const chart_values = Object.values(storeVisitsData);

    const dataByPeriod = {
        sevenDays: {
            // categories: ['Feb / 23', 'Feb / 24', 'Feb / 25', 'Feb / 26', 'Feb / 27', 'Feb / 28', 'Feb / 29'],
            categories: props.new_7_categories,
            series: [{
                name: 'Store Visits',
                data: [0, 0, 0, 0, 0, 0, 0]
            }]
        },
        fourteenDays: {
            // categories: ['Feb / 16', 'Feb / 17', 'Feb / 18', 'Feb / 19', 'Feb / 20', 'Feb / 21', 'Feb / 22', 'Feb / 23', 'Feb / 24', 'Feb / 25', 'Feb / 26', 'Feb / 27', 'Feb / 28', 'Feb / 29'],
            categories: props.new_14_categories,
            series: [{
                name: 'Store Visits',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }]
        }
    };

    series = [
        {
            name: 'Store Visits',
            data: chart_values,
        }
    ];

    while (storeVisitsData.length === 0) {
        return (
            <div className="total-revenue-section">
                <div className="bg-white p-3 pb-0 p-lg-4 rounded-3 mb-3">
                    {!props.summary && (
                        <>
                            <div className="title-holder d-flex align-items-center light-text-color">
                                <div className="d-flex justify-content-center align-items-center">
                                    <MdRemoveRedEye size="20"/></div>
                                <div className="ps-1 fw-bold-500">Store Visits</div>
                            </div>
                            <div className="total main-dark-color fw-bold-600 fs-3 my-2 ps-1">{total}</div>
                        </>
                    )}
                    <ColumnChart
                        categories={dataByPeriod[period].categories}
                        series={dataByPeriod[period].series}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="total-revenue-section">
            <div className="bg-white p-3 pb-0 p-lg-4 rounded-3 mb-3">
                {!props.summary && (
                    <>
                        <div className="title-holder d-flex align-items-center light-text-color">
                            <div className="d-flex justify-content-center align-items-center">
                                <MdRemoveRedEye size="20"/></div>
                            <div className="ps-1 fw-bold-500">Store Visits</div>
                        </div>
                        <div className="total main-dark-color fw-bold-600 fs-3 my-2 ps-1">{total}</div>
                    </>
                )}
                <ColumnChart
                    categories={chart_categories}
                    series={series}
                />
            </div>
        </div>
    );
};

export default StoreVisits;