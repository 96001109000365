import React, {useEffect, useState} from "react";
import {accountService} from "../../../_services";
import {PhoneInput} from "react-international-phone";

const WiseEdit = () => {
    const [refresh, setRefresh] = useState(false);
    const [oldValues, setOldValues] = useState({
        full_name: "",
        email: "",
    });
    const [params, setParamsValues] = useState({
        full_name: "",
        email: "",
    });

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const personalInfoData = await accountService.getUserPersonalInfo();
    //             if (personalInfoData.PersonalInfo) {
    //                 setParamsValues({
    //                     full_name: personalInfoData.PersonalInfo.full_name,
    //                     email: personalInfoData.PersonalInfo.email,
    //                 });
    //                 setOldValues({
    //                     full_name: personalInfoData.PersonalInfo.full_name,
    //                     email: personalInfoData.PersonalInfo.email,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //             // setIsLoading(false);
    //         } finally {
    //             // setIsLoading(false);
    //         }
    //     };
    //
    //     fetchData();
    // }, [refresh]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdatePersonalInfo = async (e) => {
        e.preventDefault();
        // try {
        //     const formData = new FormData();
        //     formData.append("full_name", params.full_name);
        //     formData.append("email", params.email);
        //     await accountService.updatePersonalInfo(formData)
        //         .then(res => {
        //             if (res.status === 200) {
        //                 accountService.saveToken(res.jwt);
        //             }
        //         })
        //         .catch(error => console.log(error))
        //     setRefresh(!refresh)
        // } catch (error) {
        //     console.error("Error While Updating Profile Personal Info:", error);
        // }
    };

    return (
        <div className="p-2 p-lg-3 light-shadow rounded-3 profile-step-two mt-3">
            <form onSubmit={onSubmitUpdatePersonalInfo}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="p-2 p-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="full_name">
                                        Full name:
                                    </label>
                                    <div className="full-name-holder d-flex align-items-center">
                                        <div className="full-name-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.full_name}
                                                onChange={onChange}
                                                name="full_name"
                                                id="full_name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="email">
                                        Email:
                                    </label>
                                    <div className="email-holder d-flex align-items-center">
                                        <div className="email-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.email}
                                                onChange={onChange}
                                                name="email"
                                                id="email"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={
                                !params.full_name ||
                                !params.email || (
                                    params.full_name === oldValues.full_name &&
                                    params.email === oldValues.email)
                            }
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default WiseEdit;