import {useEffect, useState} from "react";
import {accountService} from "../../../_services";
import DefaultProfilePicture from "../../../assets/images/global/default-profile-pic.webp"
import {BiRightArrowAlt} from "react-icons/bi";

const ProfileStepOne = (props) => {
    const [stepNumber, setStepNumber] = useState(props.step_number);
    const [file, setFile] = useState(null);

    useEffect(() => {
        setStepNumber(props.step_number);
    }, [props.step_number])

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmitUpdateProfile = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("image_file", file);
            await accountService.updateProfilePicture(formData);
            props.sendStepNumberToParent(2);
        } catch (error) {
            console.error("Error While Updating Profile Picture:", error);
        }
    };

    return (
        <div className="p-3 light-shadow rounded-3 profile-step-one">
            <form onSubmit={onSubmitUpdateProfile}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-lg-8">
                        <div className="main-dark-color fw-bold-600 fs-4 mb-2">Add Your Profile Picture</div>
                        <div className="gray-text-color small lh-lg pe-5">Personalize your Sellinbio by adding your favorite
                            headshot.
                        </div>
                        <div className="text-center text-lg-start">
                            {!file && (
                                <label className="mb-1 mw-100" htmlFor="file">
                                    <div
                                        className="d-flex justify-content-center align-items-center main-button main-button-color c-pointer text-white rounded-3 mt-4 fw-bold-500">Add
                                        Profle Picture
                                    </div>
                                </label>
                            )}
                            <div className="file-holder d-flex align-items-center">
                                <div className="file-input flex-grow-1">
                                    <input
                                        type="file"
                                        className="form-control py-2 d-none"
                                        onChange={onFileChange}
                                        name="file"
                                        id="file"
                                        accept="image/*"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {file && (
                            <div className="mb-1">
                                <button disabled={!file} type="submit" className="next-step-button border-0 bg-transparent mt-4">
                                    <div className="d-flex align-items-center">
                                        <div>Next</div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <BiRightArrowAlt size="25"/>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-4 mb-3 mb-lg-0 virtual-tel-holder">
                        <div className="border virtual-tel border-3 border-bottom-0 rounded-top-5 h-100 p-3 mx-auto">
                            {file ? (
                                <div className="selected-image-holder">
                                    <div className="selected-image">
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="Selected Profile Picture"
                                            className="selected-image rounded-circle"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="selected-image-holder">
                                    <div className="selected-image">
                                        <img
                                            src={DefaultProfilePicture}
                                            alt="Selected Profile Picture"
                                            className="selected-image rounded-circle border border-1 border-light-subtle"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default ProfileStepOne;