import {Footer, FreeAccessAlert, Header} from "./Private";
import {Link} from "react-router-dom";
import eBookIcon from "../../assets/images/admin-products/e-book-icon.png"
import digitalDownloadIcon from "../../assets/images/products/digital_download_icon.png"
import bookAppointmentIcon from "../../assets/images/products/book_appointment_icon.png"
import askMeAnythingIcon from "../../assets/images/products/ask_me_anything_icon.png"
import externalLinkIcon from "../../assets/images/products/external_link_icon.png"
import {BiArrowBack, BiLockOpen} from "react-icons/bi";
import React, {useId} from "react";
import {useQuery} from "react-query";
import {accountService, pubProductService} from "../../_services";
import {ThreeDots} from "react-loader-spinner";

const StoreProducts = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getProductsLength());
    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="Store Products"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="store-products-page bg-white p-3 rounded-3">
                    <div className="back-link-holder mb-3">
                        <Link to="/user/store" className="d-flex align-items-center c-pointer">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiArrowBack size="20"/>
                            </div>
                            <div className="fw-bold-500">Back to Store</div>
                        </Link>
                    </div>
                    <div className="products-holder row">
                        {data > 2 && (
                            <div className="upgrade-holder mb-3">
                                <div>
                                    <div className="text-center fw-bold-600 main-dark-color mb-2">
                                        Want to add more products to your store?
                                    </div>
                                    <Link to="/user/pricing"
                                          className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                        <div className="d-flex align-items-center justify-content-center px-1">
                                            <BiLockOpen size="18"/>
                                        </div>
                                        <div className="px-1 small fw-bold-500">Upgrade Now</div>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className="col-xl-6 mb-4">
                            <Link to={`${data < 3 ? "/user/store/pdf-books" : "#"}`}
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3 text-center">
                                        <img src={eBookIcon} alt="E-Book PDFs" className="mx-auto"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold main-dark-color">Promote a Digital
                                            Product
                                        </div>
                                        <div className="product-desc gray-text-color small">Promote our ready made
                                            ebooks and start earning right away
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-6 mb-4">
                            <Link to={`${data < 3 ? "/user/store/digital-downloads" : "#"}`}
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3 text-center">
                                        <img src={digitalDownloadIcon} alt="Sell a Digital Download"
                                             className="mx-auto"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold main-dark-color">
                                            Sell a Digital Download
                                        </div>
                                        <div className="product-desc gray-text-color small">
                                            PDFs, Spreadsheets, Templates, Exclusive Content, etc.
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-6 mb-4">
                            <Link to={`${data < 3 ? "/user/store/book-appointment" : "#"}`}
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3 text-center">
                                        <img src={bookAppointmentIcon} alt="SBook an Appointment" className="mx-auto"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold main-dark-color">
                                            Book an Appointment
                                        </div>
                                        <div className="product-desc gray-text-color small">
                                            Discovery Calls, Paid Coaching
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-6 mb-4">
                            <Link to={`${data < 3 ? "/user/store/ask-me-anything" : "#"}`}
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3 text-center">
                                        <img src={askMeAnythingIcon} alt="Ask me Anything" className="mx-auto"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold main-dark-color">
                                            Ask me Anything
                                        </div>
                                        <div className="product-desc gray-text-color small">
                                            “Ask Me Anything” requests, Audits/Analyses, Video Reviews
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-6 mb-4">
                            <Link to={`${data < 3 ? "/user/store/external-links" : "#"}`}
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3 text-center">
                                        <img src={externalLinkIcon} alt="External Link" className="mx-auto"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold main-dark-color">
                                            External Link
                                        </div>
                                        <div className="product-desc gray-text-color small">
                                            Redirect users to an external site or add a Creator’s affiliate link
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default StoreProducts;