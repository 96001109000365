import {Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {LuGalleryThumbnails} from "react-icons/lu";
import {BiEnvelope, BiListCheck, BiTrash, BiUser} from "react-icons/bi";
import {RxButton} from "react-icons/rx";
import {BsCardText} from "react-icons/bs";
import askMeAnyThinIcon from "../../../assets/images/products/ask_me_anything_icon.png"
import checkoutCover from "../../../assets/images/products/checkout_ask_me_cover.webp"
import ReactQuill from "react-quill";
import {productService} from "../../../_services";
import {useNavigate} from "react-router-dom";

const AskMeAnyThingForm = ({
                               params,
                               setParamsValues,
                               checkout_description,
                               setCheckoutDescription,
                               file,
                               setFile,
                               iconImageUrl,
                               cover,
                               setCover,
                               coverImageUrl,
                               iconImage,
                               setIconImage,
                               coverImage,
                               setCoverImage,
                               activeTab,
                               setActiveTab,
                               onTabChange,
                               isCreatingMode,
                               userProductsLength,
                               setIsCreatingMode
                           }) => {
    const [hideDeleteImg, setHideDeleteImg] = useState(false);
    const [hideDeleteCover, setHideDeleteCover] = useState(false);

    let navigate = useNavigate();
    function CustomTabThumbnail() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><LuGalleryThumbnails className="tab-icon"/></div>
                <div className="tab-title px-1">Thumbnail</div>
            </div>
        );
    }

    function CustomTabCheckout() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiListCheck className="tab-icon"/></div>
                <div className="tab-title px-1">Checkout Page</div>
            </div>
        );
    }

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    const onFileChange = (e) => {
        setIconImage('')
        setHideDeleteImg(true);
        setFile(e.target.files[0]);
    };

    const resetImage = () => {
        setIconImage('')
        setFile(null);
        setParamsValues({...params, thumbnail_icon_image: ""});
        setHideDeleteImg(false);
    };

    const onCoverChange = (e) => {
        setCoverImage('');
        setHideDeleteCover(true);
        setCover(e.target.files[0]);
    };

    const resetCover = () => {
        setCoverImage('');
        setCover(null);
        setParamsValues({...params, checkout_cover_image: ""});
        setHideDeleteCover(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("thumbnail_style_option", params.thumbnail_style_option);
            formData.append("thumbnail_icon_image", file);
            formData.append("thumbnail_title", params.thumbnail_title);
            formData.append("thumbnail_subtitle", params.thumbnail_subtitle);
            formData.append("thumbnail_button", params.thumbnail_button);
            formData.append("checkout_cover_image", cover);
            formData.append("checkout_short_description", params.checkout_short_description);
            formData.append("checkout_description", checkout_description);
            formData.append("checkout_bottom_title", params.checkout_bottom_title);
            formData.append("checkout_call_to_action_button", params.checkout_call_to_action_button);
            formData.append("checkout_price", params.checkout_price);

            if (isCreatingMode) {
                const result = await productService.createAskMeAnyThingProduct(formData);
                setParamsValues({
                    thumbnail_style_option: "button",
                    thumbnail_icon_image: "",
                    thumbnail_title: "Custom Video or Product",
                    thumbnail_subtitle: "I’ll send you a custom video/product addressing your unique request!",
                    thumbnail_button: "Submit Your Request",
                    checkout_cover_image: "",
                    checkout_short_description: "Personalized Video Response",
                    checkout_bottom_title: "Get Your Video!",
                    checkout_call_to_action_button: "PURCHASE",
                    checkout_price: 0,
                });
                setCheckoutDescription("<p>This&nbsp;[Template/eBook/Course] will teach you everything you need to achieve your goals.</p><p></p><p><strong>This guide is for you if you’re looking to:</strong></p><p></p><ul><li>Achieve your Dream</li><li>Find Meaning in Your Work</li><li>Be Happy</li></ul>")
                resetImage();
                resetCover();
                if (result.status === 201) {
                    navigate('/user/store', {replace: true})
                }
            } else {
                formData.append("id", params.product_id);
                const result = await productService.updateAskMeAnyThingProduct(formData);
                if (result.status === 201) {
                    navigate('/user/store', {replace: true})
                }
            }

        } catch (error) {
            console.error("Error Creating/Updating The Ask Me Anything Product:", error);
        } finally {
            // setIsCreating(false);
        }
    };

    const nextToCheckout = (e) => {
        e.preventDefault();
        setActiveTab('checkout');
    };

    const MAX_TITLE_CHARACTERS = 50;
    const MAX_SUBTITLE_CHARACTERS = 100;
    const MAX_BUTTON_CHARACTERS = 30;
    const MAX_SHORT_DESCRIPTION_CHARACTERS = 50;
    const MAX_BOTTOM_TITLE_CHARACTERS = 50;
    const MAX_CALL_TO_ACTION_BUTTON_CHARACTERS = 30;

    const goToPricing = () => {
        navigate("/user/pricing", {replace: true});
    };

    return (
        <div className="main-custom-tabs-holder main-product-custom-inputs">
            <form onSubmit={onSubmit} encType="multipart/form-data">
                <Tabs
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={(tabKey) => onTabChange(tabKey)}
                    className="mb-3"
                >
                    <Tab eventKey="thumbnail" title={<CustomTabThumbnail/>}>
                        <div className="thumbnail-section">
                            <div className="style-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                    <div className="step-number-holder d-flex align-items-center me-4">
                                        <div
                                            className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                            1
                                        </div>
                                        <div className="step-title main-dark-color fw-bold small">
                                            Pick a style
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xl-8">
                                    <div className="step-options d-flex">
                                        <div className="">
                                            <input type="radio" value="button"
                                                   onChange={onChange}
                                                   id="style_option_button"
                                                   name="thumbnail_style_option"
                                                   checked={params.thumbnail_style_option === "button"}/>
                                            <label
                                                className="c-pointer rounded-3 choice-content"
                                                htmlFor="style_option_button">
                                                <div className="text-center p-3 rounded-2 text-real-small me-2 value">
                                                    <div><RxButton size="20"/></div>
                                                    <div className="">Button</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input type="radio" value="callout"
                                                   onChange={onChange}
                                                   id="style_option_callout"
                                                   name="thumbnail_style_option"
                                                   checked={params.thumbnail_style_option === "callout"}/>
                                            <label
                                                className="c-pointer rounded-3 choice-content"
                                                htmlFor="style_option_callout">
                                                <div className="text-center p-3 rounded-2 text-real-small ms-2 value">
                                                    <div><BsCardText size="20"/></div>
                                                    <div className="">Callout</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                    <div className="step-number-holder d-flex align-items-center me-4">
                                        <div
                                            className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                            2
                                        </div>
                                        <div className="step-title main-dark-color fw-bold small">
                                            Select image
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xl-6">
                                    <div
                                        className="img-input-holder border border-light-subtle rounded-2 p-3 d-flex align-items-center justify-content-between">
                                        <div className="img-holder position-relative p-2 rounded-2">
                                            {iconImage ? (
                                                <img src={`${iconImage}`} alt="Ask Me Anything"/>
                                            ) : iconImageUrl ? (
                                                <img src={iconImageUrl || askMeAnyThinIcon}
                                                     alt="Ask Me Anything"/>
                                            ) : (
                                                <img src={iconImageUrl || askMeAnyThinIcon}
                                                     alt="Ask Me Anything"/>
                                            )}
                                            {hideDeleteImg && (
                                                <div className="position-absolute d-flex delete-holder">
                                                    <BiTrash size="15" className="c-pointer" onClick={resetImage}/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-holder text-center d-flex flex-column">
                                            <div className="title text-real-small light-text-color">Thumbnail</div>
                                            <div className="size text-black-50">400x400</div>
                                            <label htmlFor="icon_image" className="icon-image mt-auto">
                                                <div
                                                    className="text-real-small btn-text fw-bold-500 rounded-2 c-pointer">
                                                    Choose image
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onFileChange}
                                                name="icon_image"
                                                id="icon_image"
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        3
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Add text
                                    </div>
                                </div>
                                <div className="inputs-holder col-xl-10 ps-xl-4">
                                    {params.thumbnail_style_option === "callout" && (
                                        <div className="optional-inputs">
                                            <div className="form-group mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label htmlFor="thumbnail_title"
                                                           className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                        Title
                                                    </label>
                                                    <div className="text-black-50 small">
                                                        {params.thumbnail_title.length}/{MAX_TITLE_CHARACTERS}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <input type="text" className="form-control"
                                                           placeholder="Title heading here"
                                                           name="thumbnail_title"
                                                           id="thumbnail_title"
                                                           value={params.thumbnail_title}
                                                           maxLength={MAX_TITLE_CHARACTERS}
                                                           onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label htmlFor="thumbnail_subtitle"
                                                           className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                        Subtitle
                                                    </label>
                                                    <div className="text-black-50 small">
                                                        {params.thumbnail_subtitle.length}/{MAX_SUBTITLE_CHARACTERS}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <input type="text" className="form-control"
                                                           placeholder="Enter a description here"
                                                           name="thumbnail_subtitle"
                                                           id="thumbnail_subtitle"
                                                           value={params.thumbnail_subtitle}
                                                           onChange={onChange}
                                                           maxLength={MAX_SUBTITLE_CHARACTERS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="thumbnail_button"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                Button*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.thumbnail_button.length}/{MAX_BUTTON_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Button text here"
                                                   name="thumbnail_button"
                                                   id="thumbnail_button"
                                                   value={params.thumbnail_button}
                                                   onChange={onChange}
                                                   maxLength={MAX_BUTTON_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="checkout" title={<CustomTabCheckout/>}>
                        <div className="checkout-section">
                            <div className="image-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                    <div className="step-number-holder d-flex align-items-center me-4">
                                        <div
                                            className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                            1
                                        </div>
                                        <div className="step-title main-dark-color fw-bold small">
                                            Select image
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xl-6">
                                    <div
                                        className="img-input-holder border border-light-subtle rounded-2 p-3 d-flex align-items-center justify-content-between">
                                        <div className="img-holder position-relative p-2 rounded-2">
                                            {coverImage ? (
                                                <img src={`${coverImage}`} alt="Ask Me Anything"/>
                                            ) : coverImageUrl ? (
                                                <img src={coverImageUrl || checkoutCover} alt="Ask Me Anything"/>
                                            ) : (
                                                <img src={coverImageUrl || checkoutCover} alt="Ask Me Anything"/>
                                            )}
                                            {hideDeleteCover && (
                                                <div className="position-absolute d-flex delete-holder">
                                                    <BiTrash size="15" className="c-pointer" onClick={resetCover}/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-holder text-center d-flex flex-column">
                                            <div className="title text-real-small light-text-color">Cover</div>
                                            <div className="size text-black-50">1920x1080</div>
                                            <label htmlFor="checkout_cover_image" className="icon-image mt-auto">
                                                <div
                                                    className="text-real-small btn-text fw-bold-500 rounded-2 c-pointer">
                                                    Choose image
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onCoverChange}
                                                name="checkout_cover_image"
                                                id="checkout_cover_image"
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        2
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Write Description
                                    </div>
                                </div>
                                <div className="short-description-holder col-xl-10 ps-xl-4">
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="checkout_short_description"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                Short description*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.checkout_short_description.length}/{MAX_SHORT_DESCRIPTION_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Title Here"
                                                   name="checkout_short_description"
                                                   id="checkout_short_description"
                                                   value={params.checkout_short_description}
                                                   onChange={onChange}
                                                   maxLength={MAX_SHORT_DESCRIPTION_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div
                                            className="description-holder d-flex align-items-center">
                                            <div className="description-input flex-grow-1">
                                                <ReactQuill
                                                    value={checkout_description}
                                                    onChange={(value) => setCheckoutDescription(value)}
                                                    modules={{
                                                        toolbar: [
                                                            ["bold", "italic"],
                                                            [{header: 1}, {header: 2}],
                                                            [{list: "ordered"}, {list: "bullet"}],
                                                            ["link", "image", "video"],
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="checkout_bottom_title"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                Bottom Title*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.checkout_bottom_title.length}/{MAX_BOTTOM_TITLE_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Your bottom title"
                                                   name="checkout_bottom_title"
                                                   id="checkout_bottom_title"
                                                   value={params.checkout_bottom_title}
                                                   onChange={onChange}
                                                   maxLength={MAX_BOTTOM_TITLE_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="checkout_call_to_action_button"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                Call-to-Action Button*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.checkout_call_to_action_button.length}/{MAX_CALL_TO_ACTION_BUTTON_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Button text"
                                                   name="checkout_call_to_action_button"
                                                   id="checkout_call_to_action_button"
                                                   value={params.checkout_call_to_action_button}
                                                   onChange={onChange}
                                                   maxLength={MAX_CALL_TO_ACTION_BUTTON_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="price-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        3
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Set Price
                                    </div>
                                </div>
                                <div className="price-holder col-xl-10 ps-xl-4">
                                    <div className="form-group mb-3">
                                        <label htmlFor="checkout_price"
                                               className="black-text-color mb-1 small fw-bold-500 ps-1">
                                            Price ($)
                                        </label>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Your product price"
                                                   name="checkout_price"
                                                   id="checkout_price"
                                                   value={params.checkout_price}
                                                   onChange={onChange}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="infos-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        4
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Collect Info
                                    </div>
                                </div>
                                <div className="collect-info-holder col-xl-10 ps-xl-4">
                                    <div className="form-group mb-3">
                                        <div className="black-text-color mb-1 small fw-bold-500">
                                            Fields
                                        </div>
                                        <div className="position-relative mb-3">
                                            <div className="position-absolute top-50 translate-middle-y ps-2">
                                                <BiUser size="25"
                                                        className="gray-text-color social-logo-icon pe-1 border-end"/>
                                            </div>
                                            <input type="text" className="ps-5 form-control"
                                                   placeholder="Name"
                                                   value="Name"
                                                   disabled
                                                   readOnly
                                            />
                                        </div>
                                        <div className="position-relative">
                                            <div className="position-absolute top-50 translate-middle-y ps-2">
                                                <BiEnvelope size="25"
                                                            className="gray-text-color social-logo-icon pe-1 border-end"/>
                                            </div>
                                            <input type="text" className="ps-5 form-control"
                                                   placeholder="Email"
                                                   value="Email"
                                                   disabled
                                                   readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
                <div className="publish-button-holder mt-4 text-end col-xl-10 ps-xl-4">
                    {activeTab === 'thumbnail' ? (
                        <button type="button" className="main-button-color border-0 rounded-3 py-2 p-5 text-white"
                                onClick={nextToCheckout}>
                            Next
                        </button>
                    ) : (
                        <>
                            {userProductsLength < 3 ? (
                                <button type="submit"
                                        className="main-button-color border-0 rounded-3 py-2 p-5 text-white">
                                    Publish
                                </button>
                            ) : (
                                <button type="button" onClick={goToPricing}
                                        className="main-button-color py-2 small px-4 border-0 rounded-2 text-white">
                                    Upgrade To Publish
                                </button>
                            )}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

export default AskMeAnyThingForm;