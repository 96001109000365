import React, {useEffect, useState} from "react";
import {academyService} from "../../../_services";
import AcademyRow from "./AcademyRow";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import {BiLockOpen} from "react-icons/bi";

const AcademyFilteredData = (props) => {
    let category_id = props.category_id;
    const [academyData, setAcademyData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await academyService.getAcademyVideos(category_id);
                setAcademyData(data);
            } catch (error) {
                console.error("Error fetching academy data:", error);
            }
        };

        fetchData();
    }, [category_id]);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    return (
        <div className="row academy-card-videos">
            {academyData.length > 0 ? (
                academyData.map((academy) => (
                    <AcademyRow
                        key={academy.id}
                        title={academy.title}
                        name={academy.name}
                        description={academy.description}
                        video_url={academy.video_url}
                        access_type={academy.access_type}
                        accessible={academy.accessible}
                        video_cover={academy.video_cover}
                        showOne={props.showOne}
                        setShowOne={props.setShowOne}
                        academyVideoData={props.academyVideoData}
                        setAcademyVideoData={props.setAcademyVideoData}
                        handleShow={handleShow}
                    />
                ))
            ) : (
                <></>
            )}
            <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="py-3 upgrade-holder">
                        <div className="text-center fw-bold-600 main-dark-color mb-2">
                            Want to view Pro & Premium Trainings?
                        </div>
                        <Link to="/user/pricing"
                              className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                            <div className="d-flex align-items-center justify-content-center px-1">
                                <BiLockOpen size="18"/>
                            </div>
                            <div className="px-1 small fw-bold-500">Upgrade Now</div>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AcademyFilteredData;