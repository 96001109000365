import {EbookCategoriesForm, EbookCategoriesRow, Footer, Header} from "./Admin";
import {useEffect, useState} from "react";
import {ebookService} from "../../_services";
import {Link} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";

const EbookCategories = () => {
    const [ebookCategoriesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [ebook_categoriesToEdit, setEbookCategoriesToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ebookService.getEbookCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Ebook Categories data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditEbookCategories = (ebook_categories) => {
        setEditMode(true);
        setEbookCategoriesToEdit(ebook_categories);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return(
        <>
            <Header title="Admin - Ebook Categories"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-ebook-categories-page bg-white p-3 rounded-3">
                    <div className="back-link-holder mb-3">
                        <Link to="/admin/products/pdf-books" className="d-flex align-items-center c-pointer">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiArrowBack size="20"/>
                            </div>
                            <div className="fw-bold-500">Back to Ebook</div>
                        </Link>
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">Ebook Categories List</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-outline-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>Add New Ebook Category
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <EbookCategoriesForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            ebook_categories={ebook_categoriesToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Name</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {ebookCategoriesData.length > 0 ? (
                                ebookCategoriesData.map((ebook_categories) => (
                                    <EbookCategoriesRow
                                        key={ebook_categories.id}
                                        ebook_category_id={ebook_categories.id}
                                        name={ebook_categories.name}
                                        created_date={ebook_categories.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditEbookCategories={handleEditEbookCategories}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default EbookCategories;