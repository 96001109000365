import React, {useId} from "react";
import {useQuery} from "react-query";
import {accountService} from "../../../_services";
import {ThreeDots} from "react-loader-spinner";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
};

const SubscriptionHistory = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserSubscriptionHistory());

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <td className="text-black-50 small">Name</td>
                    <td className="text-black-50 small">Start</td>
                    <td className="text-black-50 small">End</td>
                    <td className="text-black-50 small">Amount</td>
                    <td className="text-black-50 small">Payment</td>
                </tr>
                </thead>
                <tbody>
                {data.length > 0 ? (
                    data.map((subscription) => (
                        <tr key={subscription.id}>
                            <td className="small text-nowrap">{subscription.name}</td>
                            <td className="small text-nowrap">{formatDate(subscription.start_date)}</td>
                            <td className="small text-nowrap">{formatDate(subscription.end_date)}</td>
                            <td className="small text-nowrap">${subscription.price}</td>
                            <td className="small text-nowrap">{subscription.payment_method}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" className="small text-center">There is no subscription history for your account</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default SubscriptionHistory;