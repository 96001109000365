import {accountService} from "../../../_services";
import DefaultProfilePicture from "../../../assets/images/global/default-profile-pic.webp";
import {BiLogoInstagram, BiLogoTiktok, BiUser} from "react-icons/bi";

const StoreSectionInfoEdit = ({
                                  params,
                                  setParamsValues,
                                  file,
                                  setFile,
                                  iconImageUrl,
                                  hidden,
                                  sendHiddenToParent,
                              }) => {

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("username", params.username);
            formData.append("full_name", params.full_name);
            formData.append("instagram_username", params.instagram_username);
            formData.append("tiktok_username", params.tiktok_username);
            formData.append("profile_picture", file);

            await accountService.updateProfileData(formData);

            sendHiddenToParent(!hidden);
        } catch (error) {
            console.error("Error While Updating The User Data:", error);
        }
    };

   let baseURL = 'https://api.sellinb.io/';

    return (
        <div>
            <form onSubmit={onSubmit} encType="multipart/form-data">
                <div className="selected-image-holder text-center position-relative">
                    {iconImageUrl ? (
                        <img
                            src={iconImageUrl}
                            alt="Selected Profile Picture"
                            className="selected-image rounded-circle"
                        />
                    ) : params.profile_picture ? (
                            <img
                                src={`${baseURL}thedss/images/profiles/${params.profile_picture}`}
                                alt="Selected Profile Picture"
                                className="selected-image rounded-circle"
                            />
                        )
                        : (
                            <img
                                src={DefaultProfilePicture}
                                alt="Selected Profile Picture"
                                className="selected-image rounded-circle border border-1 border-light-subtle"
                            />
                        )}
                    <div className="mx-auto" style={{width: "80px"}}>
                        <label
                            className="mb-1 text-real-small main-button-color text-white c-pointer px-3 py-1 rounded-2 mt-1"
                            htmlFor="profile_picture">
                            Update
                        </label>
                    </div>
                    <input
                        type="file"
                        className="d-none"
                        onChange={onFileChange}
                        name="profile_picture"
                        id="profile_picture"
                        accept="image/*"
                    />
                </div>
                <div className="form-group mb-3 mt-4">
                    <label htmlFor="full_name"
                           className="main-dark-color mb-1 small ps-1">
                        Full Name
                    </label>
                    <div className="position-relative">
                        <div
                            className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                            <BiUser size="25" color="#b7b7b7"/>
                        </div>
                        <input type="text" className="form-control"
                               placeholder="Your full name"
                               name="full_name"
                               id="full_name"
                               value={params.full_name}
                               onChange={onChange}
                               required
                        />
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-lg-6 p-0 pe-lg-2">
                        <div className="form-group mb-3">
                            <label htmlFor="tiktok_username"
                                   className="main-dark-color mb-1 small ps-1">
                                Tiktok Username
                            </label>
                            <div className="position-relative">
                                <div
                                    className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                    <BiLogoTiktok size="25" color="#b7b7b7"/>
                                </div>
                                <input type="text" className="form-control"
                                       placeholder="Tiktok username"
                                       name="tiktok_username" id="tiktok_username"
                                       value={params.tiktok_username} onChange={onChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0 ps-lg-2">
                        <div className="form-group mb-3">
                            <label htmlFor="instagram_username"
                                   className="main-dark-color mb-1 small ps-1">
                                Instagram Username
                            </label>
                            <div className="position-relative">
                                <div
                                    className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                    <BiLogoInstagram size="25" color="#b7b7b7"/>
                                </div>
                                <input type="text" className="form-control"
                                       placeholder="Instagram username"
                                       name="instagram_username" id="instagram_username"
                                       value={params.instagram_username}
                                       onChange={onChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save-btn-holder text-end mt-3">
                    <button type="submit"
                            className="main-button-color border-0 text-white rounded-2 px-5 py-2">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default StoreSectionInfoEdit;