import {ExternalLinkForm, Footer, FreeAccessAlert, Header} from "./Private";
import externalLinkIcon from "../../assets/images/products/external_link_icon.png"
import React, {useEffect, useId, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {accountService, productService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";

const ExternalLinks = () => {
    let {pid} = useParams();
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getProductsLength());

    const [params, setParamsValues] = useState({
        thumbnail_style_option: "button",
        thumbnail_icon_image: "",
        thumbnail_title: "Check it out!",
        thumbnail_subtitle: "Visit my Affiliate Link",
        thumbnail_button: "Click Me!",
        thumbnail_url: ""
    });

    const [isCreatingMode, setIsCreatingMode] = useState(true);

    const [iconImageUrl, setIconImageUrl] = useState(externalLinkIcon);
    const [file, setFile] = useState(null);
    const [iconImage, setIconImage] = useState('');

    const handleFileChange = (newFile) => {
        if (newFile) {
            setFile(newFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                setIconImageUrl(e.target.result);
            };
            reader.readAsDataURL(newFile);
        } else {
            setIconImageUrl(externalLinkIcon);
        }
    };

    const handleParamsChange = (newParams) => {
        setParamsValues(newParams);
    };

    useEffect(() => {
        if (pid && pid > 0) {
            const product_id = pid;
            const fetchData = async () => {
                try {
                    const data = await productService.getExternalLinkProductById(product_id);
                    if (data) {
                        setIsCreatingMode(false)
                        setParamsValues({
                            thumbnail_style_option: data.thumbnail_style_option,
                            thumbnail_icon_image: data.thumbnail_icon_image,
                            thumbnail_title: data.thumbnail_title,
                            thumbnail_subtitle: data.thumbnail_subtitle,
                            thumbnail_button: data.thumbnail_button,
                            thumbnail_url: data.thumbnail_url,
                            product_id: pid,
                        });
                        let baseURL= 'https://api.sellinb.io';
                        if (data.thumbnail_icon_image) {
                            let thumbnail_icon_image = `${baseURL}/thedss/images/ex_li_pr/${data.thumbnail_icon_image}`
                            setIconImage(thumbnail_icon_image)
                        }

                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchData();
        }
    }, [pid]);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="External Links"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="external-links-products-page bg-white p-3 rounded-3">
                    <div className="back-link-holder mb-3">
                        <Link to="/user/store/store-products" className="d-flex align-items-center c-pointer">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiArrowBack size="20"/>
                            </div>
                            <div className="fw-bold-500">Back to Store Products</div>
                        </Link>
                    </div>
                    <div className="black-text-color fw-bold-600 mb-3">Customize Your External
                        Link
                    </div>
                    <div className="row m-0 flex-column-reverse flex-xl-row">
                        <div className="col-xl-8">
                            <ExternalLinkForm
                                params={params}
                                setParamsValues={handleParamsChange}
                                setFile={handleFileChange}
                                file={file}
                                iconImageUrl={iconImageUrl}
                                iconImage={iconImage}
                                setIconImage={setIconImage}
                                isCreatingMode={isCreatingMode}
                                setIsCreatingMode={setIsCreatingMode}
                                userProductsLength={data}
                            />
                        </div>
                        <div className="col-xl-4 mb-3 mb-xl-0 main-virtual-tel-holder">
                            <div
                                className="border virtual-tel border-3 border-bottom-0 rounded-top-5 h-100 p-3 mx-auto d-flex flex-column align-items-center justify-content-center">
                                <div className="main-virtual-product-preview light-shadow p-2 w-100 rounded-2">
                                    {params.thumbnail_style_option === "button" ? (
                                        <div className="d-flex align-items-center">
                                            <div className="img-holder p-2 rounded-2 me-2">
                                                {iconImage ? (
                                                    <img src={`${iconImage}`} alt="External Links"/>
                                                ) : iconImageUrl ? (
                                                    <img src={iconImageUrl || externalLinkIcon}
                                                         alt="External Links"/>
                                                ) : (
                                                    <img src={iconImageUrl || externalLinkIcon}
                                                         alt="External Links"/>
                                                )}
                                            </div>
                                            <div className="button-text small fw-bold-600">
                                                {params.thumbnail_button}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="callout-preview">
                                            <div className="d-flex align-items-center">
                                                <div className="img-holder p-2 rounded-2 me-2">
                                                    {iconImage ? (
                                                        <img src={`${iconImage}`} alt="External Links"/>
                                                    ) : iconImageUrl ? (
                                                        <img src={iconImageUrl || externalLinkIcon}
                                                             alt="External Links"/>
                                                    ) : (
                                                        <img src={iconImageUrl || externalLinkIcon}
                                                             alt="External Links"/>
                                                    )}
                                                </div>
                                                <div>
                                                    <div className="title-text small fw-bold-600">
                                                        {params.thumbnail_title}
                                                    </div>
                                                    <div className="thumbnail_subtitle-text text-black-50 small">
                                                        {params.thumbnail_subtitle}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="main-button-color text-white rounded-3 text-center py-2 mt-2 small fw-bold-500">
                                                {params.thumbnail_button}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
};

export default ExternalLinks;