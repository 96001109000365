import React, {useState} from "react";
import {Offcanvas, Tab, Tabs} from "react-bootstrap";
import checkoutCover from "../../../assets/images/products/checkout_ask_me_cover.webp";
import {PhoneInput} from "react-international-phone";
import Modal from "react-bootstrap/Modal";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {PaypalPaymentButton, TranzakPaymentButton} from "../../../pages/public/Public";
import paypalLogo from "../../../assets/images/global/paypal_logo.png";
import stripeLogo from "../../../assets/images/global/stripe_logo.png";
import tranzakLogo from "../../../assets/images/global/tranzak_logo.png";
import {ThreeDots} from "react-loader-spinner";
import CheckoutModal from "./CheckoutModal";

const CheckoutOffCanvas = (props) => {
    const {offCanvasShow, handleCloseOffCanvas, productParams, username} = props

    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setModalShow(false);

    const handleShow = () => {
        setModalShow(true);
    }
    const [activeTab, setActiveTab] = useState("paypal");
    const [completedOrder, setCompletedOrder] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        name: '',
        email: ''
    });
    const [phoneNumber, setPhoneNumber] = useState('');
    const onChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value
        });
    }

    function CustomTabPaypal() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={paypalLogo} alt="Paypal" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabStripe() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={stripeLogo} alt="Stripe" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabTranzak() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={tranzakLogo} alt="Stripe" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    const setOrderCompleted = () => {
        setCompletedOrder(true);
    };

    const onTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };
    let baseURL = 'https://api.sellinb.io';
    const initialOptions = {
        // clientId: `${props.client_id}`,
        clientId: 'AVVmFYppMwPz489P-tKxqV9YsmMaEDK5VrfrGU8BqAj6KrZCiCcQ5mU3_er5do5tD8giI6h-KsmAGXO2',
        currency: "USD",
        intent: "capture",
    };

    return (
        <Offcanvas show={offCanvasShow} onHide={handleCloseOffCanvas} placement="end">
            <Offcanvas.Header closeButton
                              className="shadow-sm">
                <div className="fw-bold-600">Checkout</div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="off-canvas-checkout-content mt-3">
                    <div className="checkout-content">
                        <div className="text-center main-dark-color fw-bold-600 fs-4 px-3 mb-3">
                            {productParams.checkout_bottom_title}
                        </div>
                        <div className="checkout-description-holder fw-bold-500 mb-3">
                            Contact Information
                        </div>
                        <div className="form-holder mb-4">
                            <form>
                                <div className="inputs-holder mb-3">
                                    <label htmlFor="name" className="small mb-1 gray-text-color">Name</label>
                                    <input type="text" className="form-control"
                                           placeholder="Enter your name" name="name" id="name"
                                           value={customerDetails.name} onChange={onChange}/>
                                </div>
                                <div className="inputs-holder mb-3">
                                    <label htmlFor="email" className="small mb-1 gray-text-color">Email</label>
                                    <input type="text" className="form-control"
                                           placeholder="Your email address" name="email" id="email"
                                           value={customerDetails.email} onChange={onChange}/>
                                </div>
                                <div className="inputs-holder mb-3">
                                    <label htmlFor="email" className="small mb-1 gray-text-color">Phone Number</label>
                                    <PhoneInput
                                        className="custom-phone-input"
                                        defaultCountry="ua"
                                        value={phoneNumber}
                                        onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="price-holder d-flex align-items-center justify-content-between px-2">
                            <div className="text-black-50 fs-5">
                                Total:
                            </div>
                            <div className="black-text-color fw-bold-600 fs-5">
                                {productParams.product_price > 0 ? (
                                    <>
                                        {localStorage.getItem('inbio_chosen_currency_name') && localStorage.getItem('inbio_chosen_currency') && localStorage.getItem('inbio_chosen_currency') !== '1' ? (
                                            <>
                                                {`${productParams.product_price / localStorage.getItem('inbio_chosen_currency')} ${localStorage.getItem('inbio_chosen_currency_name')}`}
                                            </>
                                        ) : (
                                            <>
                                                <span>${productParams.product_price}</span>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <span>FREE</span>
                                )}
                            </div>
                        </div>
                        {productParams.product_price > 0 ? (
                            <div className="call-to-action-btn px-3 mb-3 mt-4 main-custom-tabs-holder">
                                <button type="button" onClick={() => handleShow()}
                                        disabled={!customerDetails.name || !customerDetails.email || !phoneNumber}
                                        className={`main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500
                                            ${(!customerDetails.name || !customerDetails.email || !phoneNumber) ? 'disabled' : ''}
                                        `}>
                                    {productParams.checkout_call_to_action_button}
                                </button>
                            </div>
                        ) : (
                            <button type="button" onClick={() => handleShow()}
                                    disabled={!customerDetails.name || !customerDetails.email || !phoneNumber}
                                    className={`main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500
                                            ${(!customerDetails.name || !customerDetails.email || !phoneNumber) ? 'disabled' : ''}
                                        `}>
                                {productParams.checkout_call_to_action_button}
                            </button>
                        )}
                    </div>
                    <CheckoutModal
                        modalShow={modalShow}
                        handleClose={handleClose}
                        productData={productParams}
                        setOrderCompleted={setOrderCompleted}
                        username={username}
                        completedOrder={completedOrder}
                        customerDetails={customerDetails}
                        pid={productParams.id}
                        phoneNumber={phoneNumber}
                    />
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CheckoutOffCanvas;