import {NavLink} from "react-router-dom";
import {GoGear, GoProject, GoStack} from "react-icons/go";
import {BiCart, BiUser} from "react-icons/bi";
import {HiOutlineAcademicCap} from "react-icons/hi2";


const BottomNavbar = () => {
    const menuItem = [
        {
            path: "/admin/dashboard",
            name: "Dashboard",
            icon: <GoStack/>
        },
        {
            path: "/admin/products",
            name: "Products",
            icon: <GoProject/>
        },
        {
            path: "/admin/users",
            name: "Users",
            icon: <BiUser/>
        },
        {
            path: "/admin/orders",
            name: "Orders",
            icon: <BiCart/>
        },
        {
            path: "/admin/academy",
            name: "Academy",
            icon: <HiOutlineAcademicCap size="25"/>
        },
        {
            path: "/admin/settings",
            name: "Settings",
            icon: <GoGear/>
        },
    ]

    return (
        <div
            className="the-main-bottom-navbar d-lg-none position-fixed bottom-0 w-100 py-2 px-3 d-flex align-items-center holder-of-main-page">
            <div className="bottom-navbar-links w-100 d-flex justify-content-between">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            <NavLink to={item.path} key={index}
                                     className="nav-link-item d-flex flex-column align-items-center">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                <span className="nav-text">{item.name}</span>
                            </NavLink>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default BottomNavbar;