import React, {useEffect, useState} from "react";
import {accountService} from "../../../_services";

const BankAccountEdit = () => {
    const [refresh, setRefresh] = useState(false);
    const [oldValues, setOldValues] = useState({
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        account_number: "",
        account_holder: "",
        account_type: "",
    });
    const [params, setParamsValues] = useState({
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        account_number: "",
        account_holder: "",
        account_type: "",
    });

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const addressInfoData = await accountService.getUserAddressInfo();
    //             if (addressInfoData.AddressInfo) {
    //                 setParamsValues({
    //                     street: addressInfoData.AddressInfo.street,
    //                     city: addressInfoData.AddressInfo.city,
    //                     state: addressInfoData.AddressInfo.state,
    //                     zipcode: addressInfoData.AddressInfo.zipcode,
    //                     country: addressInfoData.AddressInfo.country,
    //                 });
    //                 setOldValues({
    //                     street: addressInfoData.AddressInfo.street,
    //                     city: addressInfoData.AddressInfo.city,
    //                     state: addressInfoData.AddressInfo.state,
    //                     zipcode: addressInfoData.AddressInfo.zipcode,
    //                     country: addressInfoData.AddressInfo.country,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //             // setIsLoading(false);
    //         } finally {
    //             // setIsLoading(false);
    //         }
    //     };
    //
    //     fetchData();
    // }, [refresh]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdateAddressInfo = async (e) => {
        e.preventDefault();
        // try {
        //     const formData = new FormData();
        //     formData.append("street", params.street);
        //     formData.append("city", params.city);
        //     formData.append("state", params.state);
        //     formData.append("zipcode", params.zipcode);
        //     formData.append("country", params.country);
        //     await accountService.updateAddressInfo(formData);
        //     setRefresh(!refresh)
        // } catch (error) {
        //     console.error("Error While Updating Profile Address Info:", error);
        // }
    };

    return (
        <div className="p-2 p-lg-3 light-shadow rounded-3 profile-step-two mt-3">
            <form onSubmit={onSubmitUpdateAddressInfo}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="p-2 p-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="account_number">
                                        Account number:
                                    </label>
                                    <div className="account_number-holder d-flex align-items-center">
                                        <div className="account_number-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.account_number}
                                                onChange={onChange}
                                                name="account_number"
                                                id="account_number"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="account_holder">
                                        Account holder:
                                    </label>
                                    <div className="account_holder-holder d-flex align-items-center">
                                        <div className="account_holder-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.account_holder}
                                                onChange={onChange}
                                                name="account_holder"
                                                id="account_holder"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="account_type">
                                        Account Type:
                                    </label>
                                    <div className="account_type-holder d-flex align-items-center">
                                        <div className="account_type-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.account_type}
                                                onChange={onChange}
                                                name="account_type"
                                                id="account_type"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="country">
                                        Country:
                                    </label>
                                    <div className="country-holder d-flex align-items-center">
                                        <div className="country-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.country}
                                                onChange={onChange}
                                                name="country"
                                                id="country"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="street">
                                        Street:
                                    </label>
                                    <div className="full-name-holder d-flex align-items-center">
                                        <div className="full-name-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.street}
                                                onChange={onChange}
                                                name="street"
                                                id="street"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="city">
                                        City:
                                    </label>
                                    <div className="city-holder d-flex align-items-center">
                                        <div className="city-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.city}
                                                onChange={onChange}
                                                name="city"
                                                id="city"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="state">
                                        State/Province:
                                    </label>
                                    <div className="state-holder d-flex align-items-center">
                                        <div className="state-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.state}
                                                onChange={onChange}
                                                name="state"
                                                id="state"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="zipcode">
                                        Postal Code:
                                    </label>
                                    <div className="zipcode-holder d-flex align-items-center">
                                        <div className="zipcode-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.zipcode}
                                                onChange={onChange}
                                                name="zipcode"
                                                id="zipcode"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={
                                !params.street ||
                                !params.city ||
                                !params.state ||
                                !params.zipcode ||
                                !params.country || (
                                    params.street === oldValues.street &&
                                    params.city === oldValues.city &&
                                    params.state === oldValues.state &&
                                    params.zipcode === oldValues.zipcode &&
                                    params.country === oldValues.country
                                )
                            }
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default BankAccountEdit;