import Axios from "../ServiceCaller";

let createOrder = (orderData) => {
    return Axios.post('/pub-orders/create-order', orderData);
};

let scheduleOrderReminder = (emailData) => {
    return Axios.post('/pub-orders/schedule-order-reminder', emailData);
};

let PayOrderWithTranzak = (data) => {
    return Axios.post('/payments/tranzak/create-tranzak-order', data);
};

let verifyTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-transaction/${transactionId}`);
    return data.Response;
};
export const orderService = {
    createOrder,
    scheduleOrderReminder,
    PayOrderWithTranzak,
    verifyTransaction
};