import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountService } from "../_services";

const AdminGuard = ({ children }) => {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await accountService.isAdmin();
                if (data !== 'admin') {
                    navigate("/auth/login");
                } else {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Error fetching admin status:", error);
            }
        };

        fetchData();
    }, [navigate]);

    return isAdmin ? children : null;
};

export default AdminGuard;
