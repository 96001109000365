import {Footer, FreeAccessAlert, Header, OrderRow, Revenue} from "./Private";
import {BiCog, BiPlus, BiPlusCircle} from "react-icons/bi";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {analyticsService, userOrderService} from "../../_services";

const Income = () => {
    const [total, setTotal] = useState(0);
    const [ordersData, setOrdersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const total_data = await analyticsService.getIncomeRevenueTotal();
                const orders_data = await userOrderService.getUserOrders();
                setTotal(total_data);
                setOrdersData(orders_data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const cost_1 = 0;
    return (
        <>
            <Header title="Income"/>
            <FreeAccessAlert/>
            <div className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white income-page p-3 rounded-3">
                    <div className="summary-holder">
                        <div className="row align-items-start">
                            <div className="col-xl-7 col-xxl-8 mb-3 px-0 px-xl-2">
                                <div className="chart-holder">
                                    <Revenue total_revenue={total}/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-xxl-4 px-xl-2">
                                <div className="resume-holder light-shadow rounded-3 p-3">
                                    <div
                                        className="d-flex align-items-center justify-content-between text-real-small mb-4">
                                        <div className="px-0 px-sm-1 px-xl-0 px-xxl-2">
                                            <div className="small-title light-text-color px-1 mb-1">
                                                Unpaid Account Balance
                                            </div>
                                            <div className="cost main-dark-color fw-bold-600 px-1">${cost_1.toFixed(2)}</div>
                                        </div>
                                        <div className="px-0 px-sm-1 px-xl-0 px-xxl-2">
                                            <div className="mb-1">
                                                <span className="small-title light-text-color px-1">Pending Payment</span>
                                                <span
                                                    className="cost main-dark-color fw-bold-600">${cost_1.toFixed(2)}</span>
                                            </div>
                                            <div>
                                                <span
                                                    className="small-title light-text-color px-1">Processing</span>
                                                <span
                                                    className="cost main-dark-color fw-bold-600">${cost_1.toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns-holder px-3">
                                        <button
                                            className="w-100 cash-out-btn border-0 text-white fw-bold-500 small py-2 px-5 rounded-3 mb-2">
                                            <div className="cash-out d-flex justify-content-center">
                                                <div className="d-flex align-items-center px-1">
                                                    <BiPlus size="20"/>
                                                </div>
                                                <div className="px-1">Cash Out</div>
                                            </div>
                                        </button>
                                        <Link to="/user/settings"
                                              className="w-100 settings-btn fw-bold-500 small py-2 px-5 rounded-3 d-inline-block">
                                            <div className="settings d-flex justify-content-center">
                                                <div className="d-flex align-items-center px-1">
                                                    <BiCog size="20"/>
                                                </div>
                                                <div className="px-1">Settings</div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="latest-orders rounded-2 light-shadow mt-3 p-3">
                        <div className="latest-title main-dark-color fw-bold mb-3">
                            Latest Orders
                        </div>
                        <div className="filters-holder d-none d-lg-block mb-4">
                            <div className="d-flex flex-wrap">
                                <div className="btn-holder mb-2 px-3">
                                    <button className="filter-btn py-1 px-2 rounded-pill small">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlusCircle size="20" className="main-dark-color"/>
                                            </div>
                                            <div className="btn-text px-1 gray-text-color fw-light">Date & Time</div>
                                        </div>
                                    </button>
                                </div>
                                <div className="btn-holder mb-2 px-3">
                                    <button className="filter-btn py-1 px-2 rounded-pill small">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlusCircle size="20" className="main-dark-color"/>
                                            </div>
                                            <div className="btn-text px-1 gray-text-color fw-light">Email</div>
                                        </div>
                                    </button>
                                </div>
                                <div className="btn-holder mb-2 px-3">
                                    <button className="filter-btn py-1 px-2 rounded-pill small">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlusCircle size="20" className="main-dark-color"/>
                                            </div>
                                            <div className="btn-text px-1 gray-text-color fw-light">Product</div>
                                        </div>
                                    </button>
                                </div>
                                <div className="btn-holder mb-2 px-3">
                                    <button className="filter-btn py-1 px-2 rounded-pill small">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlusCircle size="20" className="main-dark-color"/>
                                            </div>
                                            <div className="btn-text px-1 gray-text-color fw-light">Amount</div>
                                        </div>
                                    </button>
                                </div>
                                {/*<div className="btn-holder mb-2 px-3">*/}
                                {/*    <button className="filter-btn py-1 px-2 rounded-pill small">*/}
                                {/*        <div className="d-flex align-items-center">*/}
                                {/*            <div className="d-flex align-items-center px-1">*/}
                                {/*                <BiPlusCircle size="20" className="main-dark-color"/>*/}
                                {/*            </div>*/}
                                {/*            <div className="btn-text px-1 gray-text-color fw-light">Discount Code</div>*/}
                                {/*        </div>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div className="btn-holder mb-2 px-3">
                                    <button className="filter-btn py-1 px-2 rounded-pill small">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlusCircle size="20" className="main-dark-color"/>
                                            </div>
                                            <div className="btn-text px-1 gray-text-color fw-light">Payment Method</div>
                                        </div>
                                    </button>
                                </div>
                                {/*<div className="btn-holder mb-2 px-3">*/}
                                {/*    <button className="filter-btn py-1 px-2 rounded-pill small">*/}
                                {/*        <div className="d-flex align-items-center">*/}
                                {/*            <div className="d-flex align-items-center px-1">*/}
                                {/*                <BiPlusCircle size="20" className="main-dark-color"/>*/}
                                {/*            </div>*/}
                                {/*            <div className="btn-text px-1 gray-text-color fw-light">Status</div>*/}
                                {/*        </div>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <Table>
                            <thead>
                            <tr>
                                <td className="text-black-50 small fw-bold">Date</td>
                                <td className="text-black-50 small fw-bold d-none d-lg-table-cell">Email</td>
                                <td className="text-black-50 small fw-bold d-none d-lg-table-cell">Phone</td>
                                <td className="text-black-50 small fw-bold">Product</td>
                                <td className="text-black-50 small fw-bold text-center">Amount</td>
                            </tr>
                            </thead>
                            <tbody>
                            {ordersData.length > 0 ? (
                                ordersData.map((order) => (
                                    <OrderRow
                                        key={order.id}
                                        order_id={order.id}
                                        created_date={order.created_date}
                                        customer_email={order.customer_email}
                                        customer_phone_number={order.customer_phone_number}
                                        product={order.product}
                                        amount={order.amount}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Income;