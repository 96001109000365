import React, {useId, useState} from "react";
import {useQuery} from "react-query";
import {accountService} from "../../../_services";
import {ThreeDots} from "react-loader-spinner";
import {Link} from "react-router-dom";
import {BiLockOpen, BiX, BiXCircle} from "react-icons/bi";

const FreeAccessAlert = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserAccess());

    const [showAlert, setShowAlert] = useState(true);

    const handleHideAlert = () => {
        accountService.hideFreeAlert()
            .then(res => {
                if (res.data.status === 200) {
                    setShowAlert(false)
                }
            })
            .catch(error => console.log(error))
    }

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    if (data.access_type === "free_access" && data.show_alert_to_free && showAlert) {
        return (
            <div className="free-access-alert mb-2">
                <div
                    className="border p-2 d-flex align-items-center justify-content-between upgrade-holder bg-warning-subtle light-shadow rounded-2 position-relative mx-auto mt-3"
                    style={{width: "90%"}}>
                    <div className="small main-dark-color">
                        You’re on a FREE Limited plan
                    </div>
                    <div className="small">
                        <Link to="/user/pricing"
                              className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-1 px-md-2">
                            <div className="d-flex align-items-center justify-content-center px-1 px-md-2">
                                <BiLockOpen size="15"/>
                            </div>
                            <div className="fw-bold-500">Upgrade Now</div>
                        </Link>
                    </div>
                    <div
                        className="close-btn position-absolute d-flex align-items-center justify-content-center c-pointer"
                        onClick={handleHideAlert}>
                        <BiXCircle size="20"/>
                    </div>
                </div>
            </div>
        );
    }
};

export default FreeAccessAlert;