import DefaultProfilePicture from "../../../assets/images/global/default-profile-pic.webp";
import {BiEditAlt, BiLogoInstagram, BiLogoTiktok} from "react-icons/bi";

const StoreSectionInfo = ({
                              params,
                              iconImageUrl,
                              sendHiddenToParent,
                              hidden,
                          }) => {
    const handleEditUserData = () => {
        sendHiddenToParent(!hidden);
    };

    let baseURL = 'https://api.sellinb.io/';

    return (
        <div className="d-flex">
            <div className="img-profile-holder me-2 me-lg-3">
                <div className="selected-image-holder">
                    {iconImageUrl ? (
                            <img
                                src={iconImageUrl}
                                alt="Selected Profile Picture"
                                className="selected-image rounded-1"
                            />
                        )
                        : params.profile_picture ? (
                                <img
                                    src={`${baseURL}thedss/images/profiles/${params.profile_picture}`}
                                    alt="Selected Profile Picture"
                                    className="selected-image rounded-1"
                                />
                            )
                            : (
                                <img
                                    src={DefaultProfilePicture}
                                    alt="Selected Profile Picture"
                                    className="selected-image rounded-1 border border-1 border-light-subtle"
                                />
                            )}
                </div>
            </div>
            <div className="infos-profile-holder d-flex flex-column me-2">
                <div className="main-dark-color fw-bold-600">
                    {params.full_name}
                </div>
                <div className="username text-black-50 text-real-small">
                    @{params.username}
                </div>
                <div className="gray-text-color mt-auto d-flex align-items-center">
                    <div
                        className="p-1 border border-light-subtle d-flex align-items-center justify-content-center me-1">
                        <BiLogoInstagram size="20" className="light-text-color"/>
                    </div>
                    <div
                        className="p-1 border border-light-subtle d-flex align-items-center justify-content-center ms-1">
                        <BiLogoTiktok size="20" className="light-text-color"/>
                    </div>
                </div>
            </div>
            <div className="edit-btn-holder">
                <BiEditAlt size="20" className="edit-button c-pointer" onClick={handleEditUserData}/>
            </div>
        </div>
    );
};

export default StoreSectionInfo;