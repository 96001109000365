import Axios from "../ServiceCaller";

// Authentication
let signup = (userDetails) => {
    return Axios.post('/auth/signup', userDetails);
}
let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
};
let saveToken = (token) => {
    localStorage.setItem('token_inbio', token);
};
let getToken = () => {
    return localStorage.getItem('token_inbio');
};
let logout = () => {
    return Axios.post('/auth/logout', );
};

// Access
let isLogged = () => {
    let token = localStorage.getItem('token_inbio');
    return !!token;
};
let isAdmin = async () => {
    const {data} = await Axios.get('/account/is-admin');
    return data.User;
};
let getProductsLength = async () => {
    const {data} = await Axios.get('/account/product-length');
    return data.Length;
};
let getUserAccess = async () => {
    const {data} = await Axios.get('/account/user-access');
    return data.Access;
};
let getUserSubscriptionData = async () => {
    const {data} = await Axios.get('/account/subscription-data');
    return data.Subscription;
};
let getUserSubscriptionHistory = async () => {
    const {data} = await Axios.get('/account/subscription-history');
    return data.SubscriptionHistory;
};
let upgradeUser = async (planData) => {
    return Axios.post('/account/upgrade-user', planData);
};
let hideFreeAlert = () => {
    return Axios.post('/account/hide-free-alert', );
};
// User Data
let getUserData = async () => {
    const {data} = await Axios.get('/account/user-data');
    return data.User;
};
let getUserSocialAccounts = async () => {
    const {data} = await Axios.get('/account/social-accounts');
    return data;
};
let getUserPersonalInfo = async () => {
    const {data} = await Axios.get('/account/personal-info');
    return data;
};
let getUserNotificationsInfo = async () => {
    const {data} = await Axios.get('/account/notifications-info');
    return data;
};
let getUserAddressInfo = async () => {
    const {data} = await Axios.get('/account/address-info');
    return data;
};
let getStepsStatus = async () => {
    const {data} = await Axios.get('/account/is-steps-completed');
    return data.Steps;
};
let updateProfilePicture = async (profilePic) => {
    const { data } = await Axios.put("/account/update-profile-pic", profilePic);
    return data;
};
let updateProfileSocials = async (socialAccounts) => {
    const { data } = await Axios.put("/account/update-social-accounts", socialAccounts);
    return data;
};
let updateProfileData = async (userData) => {
    const { data } = await Axios.put("/account/update-profile-data", userData);
    return data;
};
let updatePersonalInfo = async (personalInfo) => {
    const { data } = await Axios.put("/account/update-personal-info", personalInfo);
    return data;
};
let updatePassword = async (password) => {
    const { data } = await Axios.put("/account/update-password", password);
    return data;
};
let updateAddressInfo = async (addressInfo) => {
    const { data } = await Axios.put("/account/update-address-info", addressInfo);
    return data;
};
let updateNotificationsInfo = async (addressInfo) => {
    const { data } = await Axios.put("/account/update-notifications-info", addressInfo);
    return data;
};

export const accountService = {
    // Authentication
    signup,
    login,
    saveToken,
    getToken,
    logout,
    // Access
    isLogged,
    isAdmin,
    getProductsLength,
    getUserAccess,
    getUserSubscriptionData,
    upgradeUser,
    hideFreeAlert,
    // User Data
    getUserData,
    getUserSocialAccounts,
    getStepsStatus,
    getUserPersonalInfo,
    getUserAddressInfo,
    getUserNotificationsInfo,
    updateProfilePicture,
    updateProfileSocials,
    updateProfileData,
    updatePersonalInfo,
    updatePassword,
    updateAddressInfo,
    updateNotificationsInfo,
    getUserSubscriptionHistory,
};