const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const UserRow = (props) => {

    if (props.user_id > 0) {
        return (
            <tr>
                <td>
                    {props.username}
                </td>
                <td>
                    {props.full_name}
                </td>
                <td>
                    {props.email}
                </td>
                <td>
                    {props.phone_number}
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
            <td colSpan="5" className="text-center">There is no user yet</td>
            </tr>
        );
    }
}

export default UserRow;