import React, {useState} from "react";
import {accountService} from "../../../_services";

const PasswordEdit = () => {
    const [params, setParamsValues] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
    });

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdatePassword = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("current_password", params.current_password);
            formData.append("new_password", params.new_password);
            await accountService.updatePassword(formData)
                .then(res => {
                    if (res.status === 200) {
                        accountService.saveToken(res.jwt);
                        setParamsValues({
                            current_password: "",
                            new_password: "",
                            confirm_password: ""
                        });
                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            console.error("Error While Updating Profile Password Info:", error);
        }
    };

    return (
        <div className="p-2 p-lg-3 light-shadow rounded-3 profile-step-two mt-3">
            <form onSubmit={onSubmitUpdatePassword}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="p-2 p-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="current_password">
                                        Current Password:
                                    </label>
                                    <div className="current-password-holder d-flex align-items-center">
                                        <div className="current-password-input flex-grow-1 position-relative">
                                            <input
                                                type="password"
                                                className="form-control text py-2 custom-input"
                                                value={params.current_password}
                                                onChange={onChange}
                                                name="current_password"
                                                id="current_password"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="new_password">
                                        New Password:
                                    </label>
                                    <div className="new-password-holder d-flex align-items-center">
                                        <div className="new-password-input flex-grow-1 position-relative">
                                            <input
                                                type="password"
                                                className="form-control text py-2 custom-input"
                                                value={params.new_password}
                                                onChange={onChange}
                                                name="new_password"
                                                id="new_password"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="confirm_password">
                                        Confirm Password:
                                    </label>
                                    <div className="confirm-new-passowrd-holder d-flex align-items-center">
                                        <div className="confirm-new-passowrd-input flex-grow-1 position-relative">
                                            <input
                                                type="password"
                                                className="form-control text py-2 custom-input"
                                                value={params.confirm_password}
                                                onChange={onChange}
                                                name="confirm_password"
                                                id="confirm_password"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={
                                !params.current_password ||
                                !params.new_password ||
                                !params.confirm_password ||
                                params.new_password !== params.confirm_password
                            }
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default PasswordEdit;