import Axios from "../ServiceCaller";

let getIncomeRevenueTotal = async (period) => {
    const { data } = await Axios.get("/analytics/income-total");
    return data.RevenueTotal;
};
let getSummaryTotals = async (period) => {
    const { data } = await Axios.get(`/analytics/summary-totals/${period}`);
    return data.Totals;
};

let getStoreVisit = async (period) => {
    const {data} = await Axios.get(`/analytics/store-visits/${period}`);
    return data.StoreVisits;
};

let getProductPageViews = async (period) => {
    const {data} = await Axios.get(`/analytics/product-page-views/${period}`);
    return data.ProductPageViews;
};

let getTotalRevenue = async (period) => {
    const {data} = await Axios.get(`/analytics/total-revenue/${period}`);
    return data.TotalRevenue;
};

let getRevenueData = async () => {
    const {data} = await Axios.get(`/analytics/revenue-data`);
    return data.RevenueData;
};

let getTotalLeads = async (period) => {
    const {data} = await Axios.get(`/analytics/total-leads/${period}`);
    return data.TotalLeads;
};

export const analyticsService = {
    getIncomeRevenueTotal,
    getSummaryTotals,
    getStoreVisit,
    getProductPageViews,
    getTotalRevenue,
    getTotalLeads,
    getRevenueData,
};