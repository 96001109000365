import "./admin.css"
import {Outlet} from "react-router-dom";
import {BottomNavbar, Sidebar} from "./Admin";

const ALayout = () => {
    return (
        <div className="d-flex holder-of-main-page">
            <Sidebar/>
            <div className="admin-main-content-holder d-flex flex-column vh-100 w-100">
                <Outlet/>
            </div>
            <BottomNavbar/>
        </div>
    );
}

export default ALayout;