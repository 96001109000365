import {BiEdit, BiTrash} from "react-icons/bi";
import {useState} from "react";
import {ebookService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const EbookCategoriesRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteEbookCategories = async () => {
        setIsDeleting(true);

        try {
            await ebookService.deleteEbookCategory(props.ebook_category_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting ebook_categories category:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditEbookCategories = () => {
        props.handleEditEbookCategories(props);
        props.sendHiddenToParent(!props.hidden);
    };
    if (props.ebook_category_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.name}</span>
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                    <span className="text-center">
                        <button className="border-0 bg-transparent" onClick={handleEditEbookCategories} disabled={!props.hidden}>
                            <BiEdit size="22" className="edit-icon"/>
                        </button>
                        {isDeleting ? (
                            <span className="text-danger">Loading...</span>
                        ) : (
                            <BiTrash onClick={handleDeleteEbookCategories} size="22" className="remove-icon mx-auto"/>
                        )}
                    </span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="5" className="text-center">There is no ebook_categories category yet</td>
            </tr>
        );
    }
}

export default EbookCategoriesRow;