import React, {useId, useState} from "react";
import {Footer, ForYou, FreeAccessAlert, Header, ProfileSteps, SummaryAnalytics} from "./Private";
import {accountService} from "../../_services";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";
import {IoChevronBack} from "react-icons/io5";
import Iframe from "react-iframe";


const Dashboard = () => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getStepsStatus());
    const stepsData = data || {"data": []}
    const full_name = localStorage.getItem('full_name') || "!";
    const [showOne, setShowOne] = useState(false);
    const [academyVideoData, setAcademyVideoData] = useState({
        title: "",
        name: "",
        description: "",
        video_url: "",
    });

    if (isLoading) {
        return (
            <>
                <Header title="Dashboard"/>
                <div className="d-flex align-items-center justify-content-center">
                    <ThreeDots
                        height={100}
                        width={100}
                        radius={5}
                        color="#683BD1"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <Header title="Dashboard"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                {showOne ? (
                    <div className="single-video-academy">
                        <button type="button" onClick={() => {
                            setShowOne(false);
                            setAcademyVideoData({
                                title: "",
                                name: "",
                                description: "",
                                video_url: "",
                                video_cover: "",
                            });
                        }} className="border-0 bg-transparent black-text-color d-flex mb-3">
                            <div className="d-flex align-items-center justify-content-center"
                                 style={{marginTop: "1.5px"}}>
                                <IoChevronBack size="20"/>
                            </div>
                            <div className="fw-bold-500">
                                Back
                            </div>
                        </button>
                        <div className="single-academy-content bg-white rounded-3 p-3">
                            <div className="main-dark-color fw-bold text-center fs-4 mb-2">
                                {academyVideoData.title}
                            </div>
                            <div className="video-play-holder text-center">
                                <Iframe className="rounded-3 video-play" url={academyVideoData.video_url.toString()}
                                        width="640" height="360"/>
                            </div>
                            <div className="fw-bold-600 black-text-color mb-2">
                                <span className="fw-bold-500 gray-text-color">Category: </span>
                                {academyVideoData.name}
                            </div>
                            <div className="fw-bold-600 black-text-color mb-1 ">About:</div>
                            <div className="gray-text-color lh-lg">
                                {academyVideoData.description}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {stepsData > 0 && (
                            <div className="bg-white p-3 rounded-3 mb-3">
                                <ProfileSteps step_number={stepsData}/>
                            </div>
                        )}
                        <div className="bg-white p-3 pb-0 rounded-3 mb-3 dashboard-page">
                            <div className="main-dark-color fw-bold fs-4 mb-2 ps-2">Hey {full_name} - Welcome back!
                            </div>
                            <SummaryAnalytics/>
                        </div>
                        <div className="bg-white p-3 rounded-3 dashboard-page">
                            <ForYou
                                showOne={showOne}
                                setShowOne={setShowOne}
                                academyVideoData={academyVideoData}
                                setAcademyVideoData={setAcademyVideoData}
                            />
                        </div>
                    </>
                )}
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Dashboard;