import {Footer, FreeAccessAlert, Header, TotalRevenue} from "./Private";
import React, {useState} from "react";
import StoreVisits from "../../components/private/analytics/StoreVisits";
import Leads from "../../components/private/analytics/Leads";
import ProductPageViews from "../../components/private/analytics/ProductPageViews";
import ClickBreakdown from "../../components/private/analytics/ClickBreakdown";
import TrafficSource from "../../components/private/analytics/TrafficSource ";
import ConversionRatio from "../../components/private/analytics/ConversionRatio";
import Referrals from "../../components/private/analytics/Referrals";

const Analytics = () => {
    const [period, setPeriod] = useState('sevenDays');
    const changePeriod = (period) => {
        setPeriod(period);
    };
    const today = new Date(); // Get today's date
    const new_7_categories = [];
    for (let i = 0; i < 7; i++) { // Generate new_categories for the last 14 days
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days from today
        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
        const day = date.getDate(); // Get day of the month
        const new_category = `${month} / ${day}`;
        new_7_categories.unshift(new_category); // Add category to the beginning of the array
    }
    const new_14_categories = [];
    for (let i = 0; i < 14; i++) { // Generate new_categories for the last 14 days
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days from today
        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
        const day = date.getDate(); // Get day of the month
        const new_category = `${month} / ${day}`;
        new_14_categories.unshift(new_category); // Add category to the beginning of the array
    }
    return (
        <>
            <Header title="Analytics"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content analytics-page d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="period-holder d-flex align-items-center mt-3 mb-4">
                    <div className="period-btn-holder px-2">
                        <button
                            className={`period-btn rounded-4 text-real-small py-1 px-4 border-0 ${period === 'sevenDays' ? 'active' : ''}`}
                            onClick={() => changePeriod('sevenDays')}>Last 7 Days
                        </button>
                    </div>
                    <div className="period-btn-holder px-2">
                        <button
                            className={`period-btn rounded-4 text-real-small py-1 px-4 border-0 ${period === 'fourteenDays' ? 'active' : ''}`}
                            onClick={() => changePeriod('fourteenDays')}>Last 14 Days
                        </button>
                    </div>
                </div>
                <TotalRevenue period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
                <StoreVisits period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
                <Leads period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
                <ProductPageViews period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
                {/*<TrafficSource period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>*/}
                {/*<ClickBreakdown period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>*/}
                {/*<ConversionRatio period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>*/}
                {/*<Referrals period={period} new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>*/}
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Analytics;