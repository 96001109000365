import {Footer, Header} from "./Admin";
import {Link} from "react-router-dom";
import eBookIcon from "../../assets/images/admin-products/e-book-icon.png"
const Products = () => {
    return (
        <>
            <Header title="Admin - Products"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-products-page bg-white p-3 rounded-3">
                    <div className="products-holder row">
                        <div className="col-xl-6">
                            <Link to="/admin/products/pdf-books"
                                  className="product-item light-hover-effect shadow-sm p-2 rounded-2 w-100 d-inline-block">
                                <div className="d-flex align-items-center">
                                    <div className="product-img p-2 rounded-2 me-2 me-xl-3">
                                        <img src={eBookIcon} width="70" alt="E-Book PDFs" className="img-fluid"/>
                                    </div>
                                    <div className="product-info">
                                        <div className="product-title fw-bold black-text-color">PDF Books</div>
                                        <div className="product-desc gray-text-color small">Uploading Pdf books...</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Products;