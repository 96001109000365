const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const OrderRow = (props) => {

    if (props.order_id > 0) {
        return (
            <tr>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td className="d-none d-lg-table-cell">
                    {props.customer_email}
                </td>
                <td className="d-none d-lg-table-cell">
                    {props.customer_phone_number}
                </td>
                <td className="small">
                    {props.product}
                </td>
                <td className="fw-bold-500 text-center">
                    ${props.amount.toFixed(2)}
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td colSpan="4" className="d-none d-lg-table-cell">There is no order yet</td>
                <td colSpan="3" className="d-lg-none">There is no order yet</td>
            </tr>
        );
    }
}

export default OrderRow;