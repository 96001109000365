import {useEffect, useState} from "react";
import {accountService} from "../../../_services";
import {Link} from "react-router-dom";

const Footer = () => {
    const [admin, setAdmin] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await accountService.isAdmin();
                if (data === 'admin') {
                    setAdmin(true)
                }
            } catch (error) {
                console.error("Error fetching account data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="mt-auto pt-3 text-center">
            {admin && (
                <Link to="/admin/dashboard">
                    <div className="btn btn-primary fw-bold-500 btn-sm px-3 py-1 small text-white mx-auto"
                         style={{width: "fit-content"}}>
                        Admin Dashboard
                    </div>
                </Link>
            )}
            <div className="copyright-text gray-text-color small">
                All Right Reserved Copyright &copy; 2024 <span className="fw-bold">Sellinbio</span>
            </div>
        </div>
    );
}

export default Footer;