import DefaultProfilePicture from "../../../assets/images/global/default-profile-pic.webp";
import {BiLogoInstagram, BiLogoTiktok} from "react-icons/bi";
import {Link} from "react-router-dom";
import StoreUserProducts from "./StoreUserProducts";

const VirtualPhone = ({params, iconImageUrl, userProducts}) => {

   let baseURL = 'https://api.sellinb.io/';
    userProducts = userProducts || []
    return (
        <div className="border virtual-tel border-3 border-bottom-0 rounded-top-5 h-100 p-3 mx-auto overflow-y-scroll">
            <div className="selected-image-holder text-center">
                {iconImageUrl ? (
                    <img
                        src={iconImageUrl}
                        alt="Selected Profile Picture"
                        className="selected-image rounded-circle"
                    />
                ) : params && params.profile_picture ? (
                        <img
                            src={`${baseURL}thedss/images/profiles/${params.profile_picture}`}
                            alt="Selected Profile Picture"
                            className="selected-image rounded-circle"
                        />
                    )
                    : (
                        <img
                            src={DefaultProfilePicture}
                            alt="Selected Profile Picture"
                            className="selected-image rounded-circle border border-1 border-light-subtle"
                        />
                    )}
            </div>
            <div className="main-dark-color text-center fw-bold my-2">
                {params && params.full_name}
            </div>
            <div className="d-flex align-items-center justify-content-center">
                {params && params.instagram_username && (
                    <Link target="_blank" className="social-link px-1"
                          to={`https://instagram.com/${params.instagram_username}`}>
                        <BiLogoInstagram size="25" className="light-text-color"/>
                    </Link>
                )}
                {params && params.tiktok_username && (
                    <Link target="_blank" className="social-link px-1"
                          to={`https://tiktok.com/@${params.tiktok_username}`}>
                        <BiLogoTiktok size="25" className="light-text-color"/>
                    </Link>
                )}
            </div>
            <StoreUserProducts userProducts={userProducts} preview={true}/>
        </div>
    );
};

export default VirtualPhone;