import mainLogo from "../../../assets/images/global/Inbio_logo.png";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {accountService} from "../../../_services";
import {GoSignOut} from "react-icons/go";

const Header = () => {
    const [logged, setLogged] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                setLogged(true)
            );
        }
    }, []);
    const logout = () => {
        accountService.logout()
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('token_inbio');
                    localStorage.removeItem('full_name');
                    navigate("/auth/login")
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div
            className="home-page-header bg-white d-flex align-items-center justify-content-between py-2 ps-3 pe-2 fixed-top container">
            <div className="logo-holder">
                <img className="logo img-fluid" src={mainLogo} alt="Sellinbio Logo"/>
            </div>
            <div className="header-links-holder d-none d-lg-flex">
                <div><a className="header-link active px-3" href="#landing_section">Home</a></div>
                <div><a className="header-link px-3" href="#features_section">Features</a></div>
                <div><a className="header-link px-3" href="#pricing_section">Pricing</a></div>
            </div>
            {logged ? (
                <div className="header-buttons-holder d-flex align-items-center">
                    <div className="btn-holder px-2">
                        <Link to="/auth/login" className="login-btn rounded-4 px-3 py-2 small">
                            Dashboard
                        </Link>
                    </div>
                    <div className="menu-item logout-button">
                        <button type="button" onClick={logout}
                                className="nav-link-item rounded-3 px-2 py-1 small d-flex align-items-center border-0 text-danger bg-transparent">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <GoSignOut size="25"/>
                            </div>
                            <div className="nav-text">Logout</div>
                        </button>
                    </div>

                </div>
            ) : (
                <div className="header-buttons-holder d-flex">
                    <div className="btn-holder px-2">
                        <Link to="/auth/login" className="login-btn rounded-4 px-3 py-2 small">
                            Log In
                        </Link>
                    </div>
                    <div className="btn-holder px-2">
                        <Link to="/auth/signup" className="sign-up-btn rounded-4 px-3 py-2 small">
                            Sign Up
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;