import Modal from "react-bootstrap/Modal";
import {Tab, Tabs} from "react-bootstrap";
import {PaypalPaymentButton, TranzakPaymentButton} from "../../../pages/public/Public";
import React, {useState} from "react";
import paypalLogo from "../../../assets/images/global/paypal_logo.png";
import stripeLogo from "../../../assets/images/global/stripe_logo.png";
import tranzakLogo from "../../../assets/images/global/tranzak_logo.png";
import paypalIcon from "../../../assets/images/global/paypal_icon.png"
import creditCardIcon from "../../../assets/images/global/credit_card_icon.png"
import mobileMoneyIcon from "../../../assets/images/global/mobile_money_icon.jpeg"
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import CheckoutPayHolder from "./CheckoutPayHolder";

const CheckoutModal = (props) => {
    const {
        modalShow,
        handleClose,
        productData,
        setOrderCompleted,
        username,
        completedOrder,
        customerDetails,
        pid,
        phoneNumber
    } = props;
    const [activeTab, setActiveTab] = useState("paypal");
    const [params, setParamsValues] = useState({
        payment_method: ""
    });

    function CustomTabPaypal() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={paypalLogo} alt="Paypal" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabStripe() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={stripeLogo} alt="Stripe" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabTranzak() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={tranzakLogo} alt="Stripe" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    const onTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    const initialOptions = {
        // clientId: `${props.client_id}`,
        clientId: 'AVVmFYppMwPz489P-tKxqV9YsmMaEDK5VrfrGU8BqAj6KrZCiCcQ5mU3_er5do5tD8giI6h-KsmAGXO2',
        currency: "USD",
        intent: "capture",
    };

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    return (
        <Modal
            show={modalShow}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <div className="fw-bold fs-5 text-center w-100">Payment Methods</div>
            </Modal.Header>
            <Modal.Body className="main-custom-tabs-holder">
                <div className="text-center fw-bold-500 gray-text-color mb-3">
                    Please select your desired payment method
                </div>
                <div className="checkout-modal-content">
                    <div className="type-holder h-100">
                        <div className="mb-3">
                            <input type="radio" value="mobile_money"
                                   onChange={onChange}
                                   id="payment_method_mobile_money"
                                   name="payment_method"
                                   checked={params.payment_method === "mobile_money"}/>
                            <label
                                className="c-pointer rounded-3 choice-content shadow-sm"
                                htmlFor="payment_method_mobile_money">
                                <div className="d-flex align-items-center p-4">
                                    <div className="icon-holder d-flex align-items-center justify-content-center me-3">
                                        <img src={mobileMoneyIcon} alt="Mobile Money" width="50" height="50" className="object-fit-contain"/>
                                    </div>
                                    <div className="rounded-3 value mb-1 fw-bold-600">Mobile Money</div>
                                </div>
                            </label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" value="credit_card"
                                   onChange={onChange}
                                   id="payment_method_credit_card"
                                   name="payment_method"
                                   checked={params.payment_method === "credit_card"}/>
                            <label
                                className="c-pointer rounded-3 choice-content shadow-sm"
                                htmlFor="payment_method_credit_card">
                                <div className="d-flex align-items-center p-4">
                                    <div className="icon-holder d-flex align-items-center justify-content-center me-3">
                                        <img src={creditCardIcon} alt="Credit Card" width="50" height="50" className="object-fit-contain"/>
                                    </div>
                                    <div className="rounded-3 value mb-1 fw-bold-600">Credit Card</div>
                                </div>
                            </label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" value="paypal"
                                   onChange={onChange}
                                   id="payment_method_paypal"
                                   name="payment_method"
                                   checked={params.payment_method === "paypal"}/>
                            <label
                                className="c-pointer rounded-3 choice-content shadow-sm"
                                htmlFor="payment_method_paypal">
                                <div className="d-flex align-items-center p-4">
                                    <div className="icon-holder d-flex align-items-center justify-content-center me-3">
                                        <img src={paypalIcon} alt="Credit Card" width="50" height="50" className="object-fit-contain"/>
                                    </div>
                                    <div className="rounded-3 value mb-1 fw-bold-600">Paypal</div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <CheckoutPayHolder
                    disabled={!params.payment_method || params.payment_method === 'mobile_money'}
                    seller_id={productData.user_id}
                    customer_name={customerDetails.name}
                    customer_email={customerDetails.email}
                    product_id={pid}
                    phone_number={phoneNumber}
                    price={productData.checkout_price}
                    payment_method={params.payment_method}
                    product_name={productData.thumbnail_title || productData.thumbnail_button}
                    product_type={productData.product_type}
                />
                {/*{productData.checkout_price > 0 ? (*/}
                {/*    <>*/}
                {/*        <div className="call-to-action-btn px-4 mb-3 mt-4 main-custom-tabs-holder">*/}
                {/*            <Tabs*/}
                {/*                variant="pills"*/}
                {/*                activeKey={activeTab}*/}
                {/*                onSelect={(tabKey) => onTabChange(tabKey)}*/}
                {/*                className="mb-3"*/}
                {/*            >*/}
                {/*                /!*<Tab eventKey="paypal" title={<CustomTabPaypal/>}>*!/*/}
                {/*                /!*    <PayPalScriptProvider options={initialOptions}>*!/*/}
                {/*                /!*        <PaypalPaymentButton*!/*/}
                {/*                /!*            seller_username={username}*!/*/}
                {/*                /!*            setOrderCompleted={setOrderCompleted}*!/*/}
                {/*                /!*            completedOrder={completedOrder}*!/*/}
                {/*                /!*            product_name={productData.thumbnail_title || productData.thumbnail_button}*!/*/}
                {/*                /!*            seller_id={productData.user_id}*!/*/}
                {/*                /!*            customer_name={customerDetails.name}*!/*/}
                {/*                /!*            customer_email={customerDetails.email}*!/*/}
                {/*                /!*            product_id={pid}*!/*/}
                {/*                /!*            price={productData.checkout_price}*!/*/}
                {/*                /!*            product_type={productData.product_type}*!/*/}
                {/*                /!*            phone_number={phoneNumber}*!/*/}
                {/*                /!*            payment_method={activeTab}*!/*/}
                {/*                /!*        />*!/*/}
                {/*                /!*    </PayPalScriptProvider>*!/*/}
                {/*                /!*</Tab>*!/*/}
                {/*                <Tab eventKey="tranzak" title={<CustomTabTranzak/>}>*/}
                {/*                    <TranzakPaymentButton*/}
                {/*                        seller_username={username}*/}
                {/*                        setOrderCompleted={setOrderCompleted}*/}
                {/*                        completedOrder={completedOrder}*/}
                {/*                        product_name={productData.thumbnail_title || productData.thumbnail_button}*/}
                {/*                        seller_id={productData.user_id}*/}
                {/*                        customer_name={customerDetails.name}*/}
                {/*                        customer_email={customerDetails.email}*/}
                {/*                        product_id={pid}*/}
                {/*                        price={productData.checkout_price}*/}
                {/*                        product_type={productData.product_type}*/}
                {/*                        phone_number={phoneNumber}*/}
                {/*                        payment_method={activeTab}*/}
                {/*                    />*/}
                {/*                </Tab>*/}
                {/*                <Tab disabled eventKey="stripe" title={<CustomTabStripe/>}>*/}
                {/*                    Tranzak*/}
                {/*                </Tab>*/}
                {/*            </Tabs>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*) : (*/}
                {/*    <button type="button"*/}
                {/*            className="main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500">*/}
                {/*        {productData.checkout_call_to_action_button}*/}
                {/*    </button>*/}
                {/*)}*/}
            </Modal.Body>
        </Modal>
    );
};

export default CheckoutModal;