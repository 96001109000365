import Axios from "../ServiceCaller";

let getEbooks = async () => {
    const { data } = await Axios.get("/ebooks");
    return data.Ebooks;
};
let createEbook = async (ebookData) => {
    const { data } = await Axios.post("/ebooks", ebookData);
    return data;
};
let getEbookById = async (id) => {
    const { data } = await Axios.get(`/ebooks/${id}`);
    return data.Ebook;
};
let updateEbook = async (ebookData) => {
    const { data } = await Axios.put("/ebooks", ebookData);
    return data;
};
let deleteEbook = async (ebook_id) => {
    try {
        const response = await Axios.delete(`/ebooks`, {
            data: { id: ebook_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Ebook:", error);
        throw error;
    }
};
let getEbookDetails = async (product_id) => {
    const { data } = await Axios.get(`/ebooks/details/${product_id}`);
    return data.EbookDetails;
};
let updateEbookDetails = async (productData) => {
    const {data} = await Axios.put("/ebooks/details", productData);
    return data;
}
let getEbookCategories = async () => {
    const { data } = await Axios.get("/ebooks/categories");
    return data.EbookCategories;
};
let createEbookCategory = async (ebookCategoryData) => {
    const { data } = await Axios.post("/ebooks/categories", ebookCategoryData);
    return data;
};
let getEbookCategoryById = async (id) => {
    const { data } = await Axios.get(`/ebooks/categories/${id}`);
    return data.EbookCategory;
};
let updateEbookCategory = async (ebookCategoryData) => {
    const { data } = await Axios.put("/ebooks/categories", ebookCategoryData);
    return data;
};
let deleteEbookCategory = async (ebookCategory_id) => {
    try {
        const response = await Axios.delete(`/ebooks/categories`, {
            data: { id: ebookCategory_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Ebook Category:", error);
        throw error;
    }
};

export const ebookService = {
    getEbooks,
    createEbook,
    getEbookById,
    updateEbook,
    deleteEbook,
    getEbookDetails,
    updateEbookDetails,
    getEbookCategories,
    createEbookCategory,
    getEbookCategoryById,
    updateEbookCategory,
    deleteEbookCategory
};