import {Route, Routes} from "react-router-dom";
import Error from "../../_utils/Error";
import Login from "./Login";
import Signup from "./Signup";
import ResetPassword from "./ResetPassword";

const AuthRouter = () => {
    return (
        <Routes>
            <Route index element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="*" element={<Error/>}/>
        </Routes>
    );
}

export default AuthRouter;