import React, {useEffect, useState} from "react";
import {productService} from "../../../_services";
import {EbookForm, EbookRow} from "../../../pages/private/Private";
import Modal from "react-bootstrap/Modal";
import defaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";
import {Link} from "react-router-dom";
import {BiLockOpen} from "react-icons/bi";

const EbookFilteredData = (props) => {
    let category_id = props.category_id;
    const [editMode, setEditMode] = useState(false);
    const [ebookToEdit, setEbookToEdit] = useState(null);
    const [ebookToModal, setEbookToModal] = useState(null);
    const [ebooksData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => {
        setModalShow(true)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await productService.getEbooks(category_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching E-book data:", error);
            }
        };

        fetchData();
    }, [refresh, category_id]);

    const handleEditEbook = (ebook) => {
        setEditMode(true);
        setEbookToEdit(ebook);
    };

    const handleModalEbook = (ebook) => {
        setEbookToModal(ebook);
    };

    const handleEditEbookAccessible = () => {
        props.sendHiddenToParent(!props.hidden);
        props.setTypeFormEbook(props.typeFormEbook)
        handleClose()
    };

    useEffect(() => {
        if (!props.searchQuery) {
            setFilteredData(ebooksData); // If search query is empty, show all orders
            return;
        }

        const filtered = ebooksData.filter((order) =>
            Object.values(order).some((value) =>
                String(value).toLowerCase().includes(props.searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [props.searchQuery, ebooksData]);

    let baseURL = 'https://api.sellinb.io/';

    return (
        <div>
            <div className={props.hidden ? "d-none" : "d-block"}>
                <EbookForm
                    setTypeFormEbook={props.setTypeFormEbook}
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    ebook={ebookToEdit}
                    sendHiddenToParent={props.sendHiddenToParent}
                    userProductsLength={props.userProductsLength}
                    hidden={props.hidden}
                />
            </div>
            <div className={`${props.hidden ? "d-block" : "d-none"}`}>
                {filteredData.length > 0 && (
                    <div
                        className="mt-3 mb-3 fw-bold-600 featured-title main-dark-color">{props.categoryName} Ebooks</div>
                )}
                <div className="slider-ebooks-holder row">
                    {filteredData.length > 0 && (
                        filteredData.map((ebook) => (
                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-3" key={ebook.product_id}>
                                <EbookRow
                                    handleShow={handleShow}
                                    typeFormEbook={props.typeFormEbook}
                                    setTypeFormEbook={props.setTypeFormEbook}
                                    product_id={ebook.product_id}
                                    title={ebook.title}
                                    category={ebook.category}
                                    description={ebook.description}
                                    book_cover={ebook.book_cover}
                                    min_price={ebook.min_price}
                                    max_price={ebook.max_price}
                                    access_type={ebook.access_type}
                                    accessible={ebook.accessible}
                                    user_access={ebook.user_access}
                                    aff_percentage_free={ebook.aff_percentage_free}
                                    aff_percentage_pro={ebook.aff_percentage_pro}
                                    aff_percentage_premium={ebook.aff_percentage_premium}
                                    user_id={ebook.user_id}
                                    thumbnail_style_option={ebook.thumbnail_style_option}
                                    thumbnail_icon_image={ebook.thumbnail_icon_image}
                                    thumbnail_title={ebook.thumbnail_title}
                                    thumbnail_subtitle={ebook.thumbnail_subtitle}
                                    thumbnail_button={ebook.thumbnail_button}
                                    checkout_cover_image={ebook.checkout_cover_image}
                                    checkout_short_description={ebook.checkout_short_description}
                                    checkout_description={ebook.checkout_description}
                                    checkout_bottom_title={ebook.checkout_bottom_title}
                                    checkout_call_to_action_button={ebook.checkout_call_to_action_button}
                                    promoted={ebook.promoted}
                                    created_date={ebook.created_date}
                                    sendRefreshToParent={setRefresh}
                                    refresh={refresh}
                                    handleEditEbook={handleEditEbook}
                                    handleModalEbook={handleModalEbook}
                                    sendHiddenToParent={props.sendHiddenToParent}
                                    hidden={props.hidden}
                                />
                            </div>
                        ))
                    )}
                </div>
            </div>
            <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                scrollable={true}
                centered
            >
                <Modal.Header closeButton>
                    {ebookToModal && (
                        <div className="fw-bold main-dark-color fs-5 ps-1">
                            {ebookToModal.title || ''}
                            <span className="fw-normal fs-6 gray-text-color ps-1 px-1">
                                ({ebookToModal.category || ''})
                            </span>
                        </div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {ebookToModal && (
                        <div className="upgrade-holder">
                            <div className="cover-holder mb-2 position-relative">
                                {ebookToModal.book_cover ? (
                                    <img className="ebook-bg rounded-3" alt={ebookToModal.title}
                                         style={{
                                             width: "100%",
                                             height: "240px",
                                             objectFit: "contain",
                                             maxHeight: "275px",
                                             maxWidth: "100%",
                                             border: "0.01px solid #ebebeb"
                                         }}
                                         src={`${baseURL}thedss/images/ebooks/${ebookToModal.book_cover}`}
                                    />
                                ) : (
                                    <img className="ebook-bg rounded-3" alt={ebookToModal.title}
                                         style={{
                                             width: "100%",
                                             height: "240px",
                                             objectFit: "contain",
                                             maxHeight: "275px",
                                             maxWidth: "100%",
                                             border: "0.01px solid #ebebeb"
                                         }}
                                         src={defaultEbookBg}
                                    />
                                )}
                                <div className="position-absolute top-0 start-0 pt-3">
                                    {ebookToModal.access_type === 'Pro' ? (
                                        <div>
                                            <div className="small fw-bold-500 text-white py-1 ps-1 pe-2 mb-2"
                                                 style={{
                                                     backgroundColor: "#007bff",
                                                     width: "fit-content",
                                                     borderRadius: "0 5px 5px 0"
                                                 }}
                                            >
                                                Pro
                                            </div>
                                            <div className="small fw-bold-500 text-white py-1 ps-1 pe-2"
                                                 style={{
                                                     backgroundColor: "#ffc107",
                                                     width: "fit-content",
                                                     borderRadius: "0 5px 5px 0"
                                                 }}
                                            >
                                                Premium
                                            </div>
                                        </div>
                                    ) : ebookToModal.access_type === 'Premium' ? (
                                        <div className="small fw-bold-500 text-white py-1 ps-1 pe-2"
                                             style={{
                                                 backgroundColor: "#ffc107",
                                                 width: "fit-content",
                                                 borderRadius: "0 5px 5px 0"
                                             }}
                                        >
                                        Premium
                                        </div>
                                    ) : (
                                        <span></span>
                                    )}

                                </div>
                            </div>
                            <div className="black-text-color small-margin-bottom ps-1">
                                ${ebookToModal.min_price}<span
                                className="gray-text-color text-nowrap"> ~ </span>${ebookToModal.max_price}
                            </div>
                            <div className="text-black-50 text-center">Affiliate commissions</div>
                            <div className="mb-1 d-flex justify-content-between small">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="fw-normal">Free</div>
                                    <div className="fw-bold-500">
                                        {ebookToModal.access_type === 'Free' ? ebookToModal.aff_percentage_free + '%' : '***'}
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="fw-normal">Pro</div>
                                    <div className="fw-bold-500">
                                        {ebookToModal.access_type !== 'Premium' ? ebookToModal.aff_percentage_pro + '%' : '***'}
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="fw-normal">Premium</div>
                                    <div className="fw-bold-500">
                                        {ebookToModal.aff_percentage_premium + '%'}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="small fw-bold-500 main-dark-color pt-3 border-top">About {ebookToModal.title || ''}</div>
                            <div className="checkout-description-holder small">
                                <div dangerouslySetInnerHTML={{__html: ebookToModal.checkout_description}}></div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {ebookToModal && !ebookToModal.accessible ? (
                        <div>
                            <div className="text-center fw-bold-600 main-dark-color mb-2">
                                Want to promote this ebook?
                            </div>
                            <Link to="/user/pricing"
                                  className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                <div className="d-flex align-items-center justify-content-center px-1">
                                    <BiLockOpen size="18"/>
                                </div>
                                <div className="px-1 small fw-bold-500">Upgrade Now</div>
                            </Link>
                        </div>
                    ) : (
                        <div className="text-center">
                            <button className="main-button-color border-0 text-white py-2 px-5 rounded-3 w-100"
                                    onClick={handleEditEbookAccessible}>
                                Promote
                            </button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EbookFilteredData;