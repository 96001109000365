import React, {useEffect, useState} from "react";
import {adminAcademyService} from "../../../_services";

const AcademyCategoriesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);

    const [params, setParamsValues] = useState({
        name: "",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                name: props.academy_categories.name,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                name: "",
            });
        }
    }, [props.editMode, props.academy_categories]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("name", params.name);

            if (isCreatingMode) {
                await adminAcademyService.createAcademyCategory(formData);
            } else {
                formData.append("id", props.academy_categories.academy_category_id);
                await adminAcademyService.updateAcademyCategory(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                name: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating academy_categories:", error);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            name: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-academy_categories-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New academy category</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="name">
                            Name:
                        </label>
                        <div className="name-holder d-flex align-items-center">
                            <div className="name-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Name of the Category"
                                    value={params.name}
                                    onChange={onChange}
                                    name="name"
                                    id="name"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AcademyCategoriesForm;