import Axios from "../ServiceCaller";

let getAcademyVideos = async () => {
    const { data } = await Axios.get("/admin-academy");
    return data.AcademyVideos;
};
let createAcademyVideo = async (academyVideoData) => {
    const { data } = await Axios.post("/admin-academy", academyVideoData);
    return data;
};
let getAcademyVideoById = async (id) => {
    const { data } = await Axios.get(`/admin-academy/${id}`);
    return data.AcademyVideo;
};
let updateAcademyVideo = async (academyVideoData) => {
    const { data } = await Axios.put("/admin-academy", academyVideoData);
    return data;
};
let deleteAcademyVideo = async (academyVideo_id) => {
    try {
        const response = await Axios.delete(`/admin-academy`, {
            data: { id: academyVideo_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Academy Video:", error);
        throw error;
    }
};
let getAcademyCategories = async () => {
    const { data } = await Axios.get("/admin-academy/categories");
    return data.AcademyCategories;
};
let createAcademyCategory = async (academyCategoryData) => {
    const { data } = await Axios.post("/admin-academy/categories", academyCategoryData);
    return data;
};
let getAcademyCategoryById = async (id) => {
    const { data } = await Axios.get(`/admin-academy/categories/${id}`);
    return data.AcademyCategory;
};
let updateAcademyCategory = async (academyCategoryData) => {
    const { data } = await Axios.put("/admin-academy/categories", academyCategoryData);
    return data;
};
let deleteAcademyCategory = async (academyCategory_id) => {
    try {
        const response = await Axios.delete(`/admin-academy/categories`, {
            data: { id: academyCategory_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Academy Category:", error);
        throw error;
    }
};
export const adminAcademyService = {
    getAcademyVideos,
    createAcademyVideo,
    getAcademyVideoById,
    updateAcademyVideo,
    deleteAcademyVideo,
    getAcademyCategories,
    createAcademyCategory,
    getAcademyCategoryById,
    updateAcademyCategory,
    deleteAcademyCategory
};