import React, {useEffect, useId, useState} from "react";
import {accountService} from "../../../_services";
import {useQuery} from "react-query";
import {BiLogoInstagram, BiLogoTiktok, BiRightArrowAlt} from "react-icons/bi";
import {Header} from "../../../pages/private/Private";
import {ThreeDots} from "react-loader-spinner";

const ProfileStepTwo = (props) => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserSocialAccounts());
    const socialsData = data || {"data": []}

    const [stepNumber, setStepNumber] = useState(props.step_number);
    const [showForm, setShowForm] = useState(false);
    const [params, setParamsValues] = useState({
        instagram_username: "",
        tiktok_username: "",
    });

    useEffect(() => {
        setStepNumber(props.step_number);
    }, [props.step_number]);

    useEffect(() => {
        if (socialsData.SocialAccounts) {
            const socialAccountsData = socialsData.SocialAccounts;
            setParamsValues({
                instagram_username: socialAccountsData.instagram_username,
                tiktok_username: socialAccountsData.tiktok_username,
            });
        }
    }, [socialsData]);
    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        );
    }
    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdateSocials = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("instagram_username", params.instagram_username);
            formData.append("tiktok_username", params.tiktok_username);
            await accountService.updateProfileSocials(formData);
            props.sendStepNumberToParent(3);
        } catch (error) {
            console.error("Error While Updating Profile Social Accounts:", error);
        }
    };

    const makeFormVisible = () => {
        setShowForm(true)
    };

    return (
        <div className="p-3 light-shadow rounded-3 profile-step-two mt-3">
            <form onSubmit={onSubmitUpdateSocials}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-lg-7">
                        <div className="main-dark-color fw-bold-600 fs-4 mb-2">Add Your Socials</div>
                        <div className="gray-text-color small lh-lg pe-5">Add your social accounts to your Sellinbio so
                            your
                            audience can connect with you across all of your
                            different platforms.
                        </div>
                        {(!params.instagram_username && !params.tiktok_username) && (
                            <>
                                {!showForm && (
                                    <div className="mb-1 mw-100">
                                        <div onClick={makeFormVisible}
                                             className="d-flex justify-content-center align-items-center c-pointer main-button main-button-color c-pointer text-white rounded-3 mt-4 mx-auto mx-lg-0 fw-bold-500">Add
                                            Social Links
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {showForm && (
                            <div className="mb-1">
                                <button disabled={!params.instagram_username && !params.tiktok_username} type="submit"
                                        className="next-step-button border-0 bg-transparent mt-4">
                                    <div className="d-flex align-items-center">
                                        <div>Next</div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <BiRightArrowAlt size="25"/>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-5 mb-3 mb-lg-0">
                        {showForm && (
                            <div className="border border-1 border-light-subtle rounded-2 h-100 p-3 mx-auto">
                                <div className="form-group mb-2">
                                    <label className="small-label text-black-50 small" htmlFor="instagram_username">
                                        Instagram:
                                    </label>
                                    <div className="instagram-username-holder d-flex align-items-center">
                                        <div className="instagram-username-input flex-grow-1 position-relative">
                                            <div className="position-absolute top-50 translate-middle-y ps-2">
                                                <BiLogoInstagram size="30"
                                                                 className="gray-text-color social-logo-icon pe-1 border-end"/>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                placeholder="Your username"
                                                value={params.instagram_username}
                                                onChange={onChange}
                                                name="instagram_username"
                                                id="instagram_username"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-1">
                                    <label className="small-label text-black-50 small" htmlFor="tiktok_username">
                                        Tiktok:
                                    </label>
                                    <div className="tiktok-username-holder d-flex align-items-center">
                                        <div className="tiktok-username-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                placeholder="Your username"
                                                value={params.tiktok_username}
                                                onChange={onChange}
                                                name="tiktok_username"
                                                id="tiktok_username"
                                            />
                                            <div className="position-absolute top-50 translate-middle-y ps-2">
                                                <BiLogoTiktok
                                                    size="30"
                                                    className="gray-text-color social-logo-icon pe-1 border-end"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}
export default ProfileStepTwo;