import "./public.css";
import React, {useEffect, useId, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {userService} from "../../_services";
import {ThreeDots} from "react-loader-spinner";
import {FiUserX} from "react-icons/fi";
import {BiLogoInstagramAlt, BiLogoTiktok} from "react-icons/bi";
import DefaultCoverImg from "../../assets/images/global/default-cover-img.jpeg"
import {ChangeCurrency, FloatingNewStore, ProfileTemplate, UserProducts} from "./Public";
import ProfileTemplateOne from "../../components/public/profile/ProfileTemplateOne";
import ProfileTemplateTwo from "../../components/public/profile/ProfileTemplateTwo";

const UserProfile = () => {
    let {username} = useParams();
    let randomID = useId();
    useEffect(() => {
        document.title = `@${username}`;
    }, [username]);

    const {isLoading, data} = useQuery(randomID, () => userService.getTemplateSettings(username));
    const TemplateSettings = data || {"data": []}

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    if (TemplateSettings) {
        return (
            <>
                {TemplateSettings.template_id === 1 ? (
                    <ProfileTemplateOne
                        username={username}
                    />
                ) : TemplateSettings.template_id === 2 ? (
                    <ProfileTemplateTwo
                        username={username}
                    />
                ) : (
                    <ProfileTemplateOne
                        username={username}
                        profileTemplateId={TemplateSettings.template_id}
                    />
                )}
            </>
        );
    } else {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div>
                    <div className="text-center mb-2">
                        <FiUserX size="100" className="gray-text-color fw-light"/>
                    </div>
                    <div className="text-black-50 fs-1">
                        Couldn't find this account
                    </div>
                </div>
                <FloatingNewStore/>
            </div>
        );
    }
}

export default UserProfile;