import {Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {LuGalleryThumbnails} from "react-icons/lu";
import {BiLink, BiTrash} from "react-icons/bi";
import {RxButton} from "react-icons/rx";
import {BsCardText} from "react-icons/bs";
import externalLinkIcon from "../../../assets/images/products/external_link_icon.png"
import {productService} from "../../../_services";
import {useNavigate} from "react-router-dom";

const ExternalLinkForm = ({
                              params,
                              setParamsValues,
                              file,
                              setFile,
                              iconImageUrl,
                              iconImage,
                              setIconImage,
                              coverImage,
                              setCoverImage,
                              isCreatingMode,
                              userProductsLength,
                              setIsCreatingMode
                          }) => {
    const [activeTab, setActiveTab] = useState("thumbnail");
    const [hideDeleteImg, setHideDeleteImg] = useState(false);

    let navigate = useNavigate();

    function CustomTabThumbnail() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><LuGalleryThumbnails className="tab-icon"/></div>
                <div className="tab-title px-1">Thumbnail</div>
            </div>
        );
    }

    function CustomTabUrl() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiLink className="tab-icon"/></div>
                <div className="tab-title px-1">URL</div>
            </div>
        );
    }

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    const onFileChange = (e) => {
        setIconImage('')
        setHideDeleteImg(true);
        setFile(e.target.files[0]);
    };

    const resetImage = () => {
        setIconImage('')
        setFile(null);
        setParamsValues({...params, thumbnail_icon_image: ""});
        setHideDeleteImg(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("thumbnail_style_option", params.thumbnail_style_option);
            formData.append("thumbnail_icon_image", file);
            formData.append("thumbnail_title", params.thumbnail_title);
            formData.append("thumbnail_subtitle", params.thumbnail_subtitle);
            formData.append("thumbnail_button", params.thumbnail_button);
            formData.append("thumbnail_url", params.thumbnail_url);

            if (isCreatingMode) {
                const result = await productService.createExternalLinkProduct(formData);
                setParamsValues({
                    thumbnail_style_option: "button",
                    thumbnail_icon_image: "",
                    thumbnail_title: "Check it out!",
                    thumbnail_subtitle: "Visit my Affiliate Link",
                    thumbnail_button: "Click Me!",
                    thumbnail_url: ""
                });
                resetImage();
                if (result.status === 201) {
                    navigate('/user/store', {replace: true})
                }
            } else {
                formData.append("id", params.product_id);
                const result = await productService.updateExternalLinkProduct(formData);
                if (result.status === 201) {
                    navigate('/user/store', {replace: true})
                }
            }
        } catch (error) {
            console.error("Error Creating/Updating The External Link Product:", error);
        } finally {
            // setIsCreating(false);
        }
    };

    const nextToURL = (e) => {
        e.preventDefault();
        setActiveTab('url');
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    const MAX_TITLE_CHARACTERS = 50;
    const MAX_SUBTITLE_CHARACTERS = 100;
    const MAX_BUTTON_CHARACTERS = 30;
    const MAX_URL_CHARACTERS = 1024;

    const goToPricing = () => {
        navigate("/user/pricing", {replace: true});
    };

    return (
        <div className="main-custom-tabs-holder main-product-custom-inputs">
            <form onSubmit={onSubmit} encType="multipart/form-data">
                <Tabs
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={(tabKey) => handleTabChange(tabKey)}
                    className="mb-3"
                >
                    <Tab eventKey="thumbnail" title={<CustomTabThumbnail/>}>
                        <div className="thumbnail-section">
                            <div className="style-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                    <div className="step-number-holder d-flex align-items-center me-4">
                                        <div
                                            className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                            1
                                        </div>
                                        <div className="step-title main-dark-color fw-bold small">
                                            Pick a style
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xl-8">
                                    <div className="step-options d-flex">
                                        <div className="">
                                            <input type="radio" value="button"
                                                   onChange={onChange}
                                                   id="style_option_button"
                                                   name="thumbnail_style_option"
                                                   checked={params.thumbnail_style_option === "button"}/>
                                            <label
                                                className="c-pointer rounded-3 choice-content"
                                                htmlFor="style_option_button">
                                                <div className="text-center p-3 rounded-2 text-real-small me-2 value">
                                                    <div><RxButton size="20"/></div>
                                                    <div className="">Button</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input type="radio" value="callout"
                                                   onChange={onChange}
                                                   id="style_option_callout"
                                                   name="thumbnail_style_option"
                                                   checked={params.thumbnail_style_option === "callout"}/>
                                            <label
                                                className="c-pointer rounded-3 choice-content"
                                                htmlFor="style_option_callout">
                                                <div className="text-center p-3 rounded-2 text-real-small ms-2 value">
                                                    <div><BsCardText size="20"/></div>
                                                    <div className="">Callout</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                    <div className="step-number-holder d-flex align-items-center me-4">
                                        <div
                                            className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                            2
                                        </div>
                                        <div className="step-title main-dark-color fw-bold small">
                                            Select image
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xl-6">
                                    <div
                                        className="img-input-holder border border-light-subtle rounded-2 p-3 d-flex align-items-center justify-content-between">
                                        <div className="img-holder position-relative p-2 rounded-2">
                                            {iconImage ? (
                                                <img src={`${iconImage}`} alt="External Links"/>
                                            ) : iconImageUrl ? (
                                                <img src={iconImageUrl || externalLinkIcon}
                                                     alt="External Links"/>
                                            ) : (
                                                <img src={iconImageUrl || externalLinkIcon}
                                                     alt="External Links"/>
                                            )}
                                            {hideDeleteImg && (
                                                <div className="position-absolute d-flex delete-holder">
                                                    <BiTrash size="15" className="c-pointer" onClick={resetImage}/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-holder text-center d-flex flex-column">
                                            <div className="title text-real-small light-text-color">Thumbnail</div>
                                            <div className="size text-black-50">400x400</div>
                                            <label htmlFor="icon_image" className="icon-image mt-auto">
                                                <div
                                                    className="text-real-small btn-text fw-bold-500 rounded-2 c-pointer">
                                                    Choose image
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onFileChange}
                                                name="icon_image"
                                                id="icon_image"
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        3
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Add text
                                    </div>
                                </div>
                                <div className="inputs-holder col-xl-10 ps-xl-4">
                                    {params.thumbnail_style_option === "callout" && (
                                        <div className="optional-inputs">
                                            <div className="form-group mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label htmlFor="thumbnail_title"
                                                           className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                        Title
                                                    </label>
                                                    <div className="text-black-50 small">
                                                        {params.thumbnail_title.length}/{MAX_TITLE_CHARACTERS}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <input type="text" className="form-control"
                                                           placeholder="Title heading here"
                                                           name="thumbnail_title"
                                                           id="thumbnail_title"
                                                           value={params.thumbnail_title}
                                                           onChange={onChange}
                                                           maxLength={MAX_TITLE_CHARACTERS}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label htmlFor="thumbnail_subtitle"
                                                           className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                        Subtitle
                                                    </label>
                                                    <div className="text-black-50 small">
                                                        {params.thumbnail_subtitle.length}/{MAX_SUBTITLE_CHARACTERS}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <input type="text" className="form-control"
                                                           placeholder="Enter a description here"
                                                           name="thumbnail_subtitle"
                                                           id="thumbnail_subtitle"
                                                           value={params.thumbnail_subtitle}
                                                           onChange={onChange}
                                                           maxLength={MAX_SUBTITLE_CHARACTERS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="thumbnail_button"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                Button*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.thumbnail_button.length}/{MAX_BUTTON_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="Button text here"
                                                   name="thumbnail_button"
                                                   id="thumbnail_button"
                                                   value={params.thumbnail_button}
                                                   onChange={onChange}
                                                   maxLength={MAX_BUTTON_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="url" title={<CustomTabUrl/>}>
                        <div className="url-section">
                            <div className="url-section mt-3 mt-sm-4 mt-lg-5">
                                <div className="step-number-holder d-flex align-items-center mb-3">
                                    <div
                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                        1
                                    </div>
                                    <div className="step-title main-dark-color fw-bold small">
                                        Enter URL
                                    </div>
                                </div>
                                <div className="inputs-holder col-xl-10 ps-xl-4">
                                    <div className="form-group mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label htmlFor="thumbnail_url"
                                                   className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                URL*
                                            </label>
                                            <div className="text-black-50 small">
                                                {params.thumbnail_url.length}/{MAX_URL_CHARACTERS}
                                            </div>
                                        </div>
                                        <div className="">
                                            <input type="text" className="form-control"
                                                   placeholder="https://..."
                                                   name="thumbnail_url"
                                                   id="thumbnail_url"
                                                   value={params.thumbnail_url}
                                                   onChange={onChange}
                                                   maxLength={MAX_URL_CHARACTERS}
                                                   required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
                <div className="publish-button-holder mt-4 text-end col-xl-10 ps-xl-4">
                    {activeTab === 'thumbnail' ? (
                        <button type="button" className="main-button-color border-0 rounded-3 py-2 p-5 text-white"
                                onClick={nextToURL}>
                            Next
                        </button>
                    ) : (
                        <>
                            {userProductsLength < 3 ? (
                                <button type="submit"
                                        className="main-button-color border-0 rounded-3 py-2 p-5 text-white">
                                    Publish
                                </button>
                            ) : (
                                <button type="button" onClick={goToPricing}
                                        className="main-button-color py-2 small px-4 border-0 rounded-2 text-white">
                                    Upgrade To Publish
                                </button>
                            )}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ExternalLinkForm;