import Axios from "../ServiceCaller";

let getProductDataById = async (product_id) => {
    const {data} = await Axios.get(`/pub-products/product/${product_id}`);
    return data.Product;
};

let getEbookDataById = async (product_id) => {
    const {data} = await Axios.get(`/pub-products/ebook/${product_id}`);
    return data.Product;
};

export const pubProductService = {
    getProductDataById,
    getEbookDataById,
};