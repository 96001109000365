import React, {useEffect, useState} from "react";
import {accountService, productService} from "../../../_services";
import StoreSectionInfo from "./StoreSectionInfo";
import VirtualPhone from "./VirtualPhone";
import StoreSectionInfoEdit from "./StoreSectionInfoEdit";
import {Link} from "react-router-dom";
import {BiLockOpen, BiPlus} from "react-icons/bi";
import default_profile_pic from "../../../assets/images/global/default-profile-pic.webp";
import StoreUserProducts from "./StoreUserProducts";

const StoreSection = () => {
    const [params, setParamsValues] = useState({
        full_name: "",
        username: "",
        profile_picture: "",
        tiktok_username: "",
        instagram_username: "",
    });

    const [file, setFile] = useState(null);
    const [iconImageUrl, setIconImageUrl] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [userProducts, setUserProducts] = useState([])
    const [userProductsLength, setUserProductsLength] = useState(0);

    const handleFileChange = (newFile) => {
        if (newFile) {
            setFile(newFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                setIconImageUrl(e.target.result);
            };
            reader.readAsDataURL(newFile);
        } else {
            setIconImageUrl(default_profile_pic);
        }
    };

    const handleParamsChange = (newParams) => {
        setParamsValues(newParams);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await accountService.getUserData();
                if (data) {
                    const {username, full_name, profile_picture, tiktok_username, instagram_username} = data;
                    setParamsValues({
                        username: username,
                        full_name: full_name,
                        profile_picture: profile_picture,
                        tiktok_username: tiktok_username,
                        instagram_username: instagram_username,
                    });
                }
                const user_products = await productService.getUserProducts();
                const user_products_length = await accountService.getProductsLength();
                if (user_products.length > 0) {
                    setUserProducts(user_products)
                }
                if (user_products_length) {
                    setUserProductsLength(user_products_length)
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="store-section bg-white p-3 rounded-3">
            <div className="row m-0 flex-column-reverse flex-lg-row">
                <div className="col-lg-8">
                    <div className="light-shadow p-3 rounded-3">
                        <div className={hidden ? "d-none" : "d-block"}>
                            <StoreSectionInfoEdit
                                params={params}
                                setParamsValues={handleParamsChange}
                                setFile={handleFileChange}
                                file={file}
                                iconImageUrl={iconImageUrl}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        </div>
                        <div className={!hidden ? "d-none" : "d-block"}>
                            <StoreSectionInfo
                                params={params}
                                iconImageUrl={iconImageUrl}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                            <div className="mt-4 text-center">
                                {userProductsLength < 3 ? (
                                    <Link to="/user/store/store-products"
                                          className="main-button-color text-white d-inline-block py-2 px-5 rounded-3">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center px-1">
                                                <BiPlus size="20"/>
                                            </div>
                                            <div className="px-1">Add Product</div>
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="upgrade-holder">
                                        <div>
                                            <div className="text-center fw-bold-600 main-dark-color mb-2">
                                                Want to add more products to your store?
                                            </div>
                                            <Link to="/user/pricing" className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                                <div className="d-flex align-items-center justify-content-center px-1">
                                                    <BiLockOpen size="18"/>
                                                </div>
                                                <div className="px-1 small fw-bold-500">Upgrade Now</div>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <StoreUserProducts
                        userProducts={userProducts}
                        setUserProducts={setUserProducts}
                        preview={false}
                    />
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 virtual-tel-holder">
                    <VirtualPhone
                        params={params}
                        iconImageUrl={iconImageUrl}
                        userProducts={userProducts}
                    />
                </div>
            </div>
        </div>
    );
};

export default StoreSection;