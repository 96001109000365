import {AcademyForm, AcademyRow, Footer, Header} from "./Admin";
import {useEffect, useState} from "react";
import {adminAcademyService} from "../../_services";
import {Link} from "react-router-dom";

const Academy = () => {
    const [academyData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [academyToEdit, setAcademyToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminAcademyService.getAcademyVideos();
                setData(data);
            } catch (error) {
                console.error("Error fetching Academy data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditAcademy = (academy) => {
        setEditMode(true);
        setAcademyToEdit(academy);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return(
        <>
            <Header title="Admin - Academy"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-academy-page bg-white p-3 rounded-3">
                    <div className="d-flex flex-column flex-lg-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-lg-3 mb-2 mb-lg-0">Academy Videos List</div>
                        <div className="add-btn-holder mb-2 mb-lg-0">
                            <button className="btn btn-outline-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>Add New Academy Video
                            </button>
                        </div>
                        <div className="ms-2">
                            <Link to="/admin/academy-categories">
                                <button type="button" className="btn btn-outline-info py-1 btn-sm">Manage Categories</button>
                            </Link>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <AcademyForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            academy={academyToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Title</td>
                                <td className="text-black-50">Category</td>
                                <td className="text-black-50">Description</td>
                                <td className="text-black-50">Access</td>
                                <td className="text-black-50">Video URL</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {academyData.length > 0 ? (
                                academyData.map((academy) => (
                                    <AcademyRow
                                        key={academy.id}
                                        academy_id={academy.id}
                                        title={academy.title}
                                        video_cover={academy.video_cover}
                                        category={academy.name}
                                        category_id={academy.category_id}
                                        description={academy.description}
                                        video_url={academy.video_url}
                                        access_type={academy.access_type}
                                        created_date={academy.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditAcademy={handleEditAcademy}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Academy;