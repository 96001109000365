import React, {useEffect, useState} from "react";
import {accountService} from "../../../_services";

const EmailNotificationsEdit = () => {
    const [refresh, setRefresh] = useState(false);
    const [emailParams, setEmailParamsValues] = useState({
        email_notification: true,
    });
    const [oldValues, setOldValues] = useState({
        email: "",
    });
    const [params, setParamsValues] = useState({
        email: "",
    });

    const handleToggleButton = () => {
        setEmailParamsValues((prevParams) => ({
            ...prevParams,
            email_notification: !prevParams.email_notification,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const notificationsInfoData = await accountService.getUserNotificationsInfo();
                if (notificationsInfoData.NotificationsInfo) {
                    setParamsValues({
                        email: notificationsInfoData.NotificationsInfo.email,
                    });
                    setOldValues({
                        email: notificationsInfoData.NotificationsInfo.email,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [refresh]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdateNotificationsInfo = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("email", params.email);
            await accountService.updateNotificationsInfo(formData);
            setRefresh(!refresh)
        } catch (error) {
            console.error("Error While Updating Profile Personal Info:", error);
        }
    };

    return (
        <div className="p-2 p-lg-3 light-shadow rounded-3 profile-step-two mt-3">
            <div className="notifications-holder d-flex align-items-center">
                {emailParams.email_notification ? (
                    <div className="toggle me-2 active c-pointer" onClick={handleToggleButton}>
                        <div className="toggle-right"></div>
                    </div>
                ) : (
                    <div className="toggle me-2 c-pointer" onClick={handleToggleButton}>
                        <div className="toggle-left"></div>
                    </div>
                )}
                <div className="email-text small">
                    Email notifications
                </div>
            </div>
            <form onSubmit={onSubmitUpdateNotificationsInfo}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="p-2 p-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2" htmlFor="email">
                                        Notification to Email:
                                    </label>
                                    <div className="email-holder d-flex align-items-center">
                                        <div className="email-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.email}
                                                onChange={onChange}
                                                name="email"
                                                id="email"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={
                                !params.email || (
                                    params.email === oldValues.email)
                            }
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EmailNotificationsEdit;