import inbioLogo from "../../../assets/images/global/Inbio_logo.png"
import {Link} from "react-router-dom";

const FloatingNewStore = () => {
    return (
        <div className="floating-new-store position-fixed fixed-bottom p-2">
            <Link to="https://sellinb.io" className="d-flex align-items-center content-holder justify-content-between py-2 px-3 rounded-4 mx-auto main-bg-light-color">
                <div className="logo-holder d-flex justify-content-center align-items-center">
                    <img src={inbioLogo} alt="Inbio Logos" height="25"/>
                </div>
                <div className="small fw-bold-500 black-text-color">
                    Create your own store
                </div>
            </Link>
        </div>
    );
};

export default FloatingNewStore;