import {BiCreditCard, BiDollarCircle, BiEnvelope, BiUser} from "react-icons/bi";
import {
    AddressInfoEdit,
    Footer,
    Header,
    PasswordEdit,
    PersonalInfoEdit,
    PaymentMethods,
    EmailNotificationsEdit, FreeAccessAlert
} from "./Private";
import {Tab, Tabs} from "react-bootstrap";
import {GoSignOut} from "react-icons/go";
import {accountService} from "../../_services";
import {Link, useNavigate} from "react-router-dom";
import React, {useId, useState} from "react";
import {useQuery} from "react-query";
import BillingSection from "../../components/private/settings/BillingSection";
import {ThreeDots} from "react-loader-spinner";

const Settings = () => {
    let navigate = useNavigate();
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserAccess());

    function CustomTabProfile() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>
                <div className="tab-title px-1">Profile</div>
            </div>
        );
    }

    function CustomTabBilling() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiDollarCircle className="tab-icon"/></div>
                <div className="tab-title px-1">Billing</div>
            </div>
        );
    }

    function CustomTabPayments() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiCreditCard className="tab-icon"/></div>
                <div className="tab-title px-1">Payments</div>
            </div>
        );
    }

    function CustomTabEmailNotifications() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiEnvelope className="tab-icon"/></div>
                <div className="tab-title px-1">Email Notifications</div>
            </div>
        );
    }

    const logout = () => {
        accountService.logout()
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('token_inbio');
                    localStorage.removeItem('full_name');
                    navigate("/auth/login")
                }
            })
            .catch(error => console.log(error))
    }

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="Settings"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-settings-page py-3 px-lg-3 rounded-3">
                    <div className="main-custom-tabs-holder">
                        <Tabs
                            variant="pills"
                            defaultActiveKey="profile"
                            className="mb-3"
                        >
                            <Tab eventKey="profile" title={<CustomTabProfile/>}>
                                <div className="profile-section bg-white py-3 px-4 rounded-3 light-shadow">
                                    <div className="title fs-5 main-dark-color fw-bold-500 mb-2">
                                        My Profile
                                    </div>
                                    <PersonalInfoEdit/>
                                    <div className="title fs-5 main-dark-color fw-bold-500 mb-2 mt-4">
                                        Password
                                    </div>
                                    <PasswordEdit/>
                                    <div className="title fs-5 main-dark-color fw-bold-500 mb-2 mt-4">
                                        Address
                                    </div>
                                    <AddressInfoEdit/>
                                    <div className="logout-holder mt-3 mb-1 d-lg-none">
                                        <div className="button-holder ms-auto">
                                            <button type="button" onClick={logout}
                                                    className="d-flex align-items-center border-0 bg-transparent">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <GoSignOut size="25"/>
                                                </div>
                                                <div className="button-text">Logout</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="billing" title={<CustomTabBilling/>}>
                                <BillingSection user_access={data.access_type}/>
                            </Tab>
                            <Tab eventKey="payments" title={<CustomTabPayments/>}>
                                <div className="payments-section bg-white py-3 px-4 rounded-3 light-shadow">
                                    <div className="title main-dark-color fw-bold-500 mb-2">
                                        Payment Methods
                                    </div>
                                    <div className="subtitle light-text-color small">
                                        Enter your payment Information to get paid.<br/>Payments are made on the 14th
                                        and 28th of every month for approved unpaid balances. And you must have met the
                                        minimum payment threshold of $50
                                    </div>
                                    <PaymentMethods/>
                                </div>
                            </Tab>
                            <Tab eventKey="email-notifications" title={<CustomTabEmailNotifications/>}>
                                <div
                                    className="email-notifications-section bg-white py-3 px-4 rounded-3 light-shadow">
                                    <div className="title main-dark-color fw-bold-500 mb-3">
                                        Email notifications
                                    </div>
                                    <EmailNotificationsEdit/>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
};

export default Settings;