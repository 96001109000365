import "./auth.css"
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {accountService} from "../../_services";
import {useState} from "react";
import {Link} from "react-router-dom";

import iconLogo from "../../assets/images/global/icon_inbio.png"
import {BiMailSend} from "react-icons/bi";

const ResetPassword = () => {
    useEffect(() => {
        document.title = "Sellinbio - Reset Password";
    }, []);

    let navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: '',
        // password: ''
    });

    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/home', {replace: true})
            );
        }
    }, [navigate]);

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        accountService.login(credentials)
            .then(res => {
                accountService.saveToken(res.data.jwt);
                navigate('/user/dashboard', {replace: true});
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="authentication-page h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-8 col-xl-6">
                        <div className="authentication-content bg-white px-1 px-md-2 py-3 py-md-4 rounded-3 shadow-lg">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form p-3 p-lg-4 p-xxl-5">
                                        <div
                                            className="text-center mb-1 d-flex justify-content-center align-items-center">
                                            <Link to="/home"><img width="75" src={iconLogo} className="img-fluid" alt="Sellinbio"/></Link>
                                        </div>
                                        <div className="text-center black-text-color fw-bold fs-3">Forget Your Password?</div>
                                        <div className="text-center mb-4 gray-text-color">We do it all the time too - we’ll send your email a verification code!</div>
                                        <form onSubmit={onSubmit}>
                                            <div className="form-group mb-4 position-relative">
                                                <div
                                                    className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                    <BiMailSend size="25" color="#b7b7b7"/>
                                                </div>
                                                <input type="text" className="form-control"
                                                       placeholder="Your email address" name="email" id="email"
                                                       value={credentials.email} onChange={onChange}/>
                                            </div>
                                            {/*<div className="form-group mb-2">*/}
                                            {/*    <label className="mb-1 fw-bold ps-3 gray-text-color"*/}
                                            {/*           htmlFor="pass">Password</label>*/}
                                            {/*    <input type="password" className="form-control"*/}
                                            {/*           placeholder="Your Password"*/}
                                            {/*           name="password" id="pass" value={credentials.password}*/}
                                            {/*           onChange={onChange}/>*/}
                                            {/*</div>*/}
                                            <div className="text-center">
                                                <button type="submit"
                                                        className="btn btn-block submit-button w-100 fw-bold">Submit
                                                </button>
                                            </div>
                                        </form>
                                        <div className="new-account mt-3">
                                            <p className="gray-text-color">Go back to
                                                <Link to="/auth/login" className="auth-link fw-bold"> Sign in</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;