import {AskMeAnyThingForm, Footer, FreeAccessAlert, Header} from "./Private";
import askMeAnyThinIcon from "../../assets/images/products/ask_me_anything_icon.png"
import React, {useEffect, useId, useState} from "react";
import checkoutCover from "../../assets/images/products/checkout_ask_me_cover.webp";
import {Link, useParams} from "react-router-dom";
import {accountService, productService} from "../../_services";
import {BiArrowBack} from "react-icons/bi";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";

const AskMeAnyThing = () => {
    let {pid} = useParams();
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getProductsLength());
    const [activeTab, setActiveTab] = useState("thumbnail");
    const [params, setParamsValues] = useState({
        thumbnail_style_option: "button",
        thumbnail_icon_image: "",
        thumbnail_title: "Custom Video or Product",
        thumbnail_subtitle: "I’ll send you a custom video/product addressing your unique request!",
        thumbnail_button: "Submit Your Request",
        checkout_cover_image: "",
        checkout_short_description: "Personalized Video Response",
        checkout_bottom_title: "Get Your Video!",
        checkout_call_to_action_button: "PURCHASE",
        checkout_price: 0,
    });

    const [checkoutDescription, setCheckoutDescription] = useState("<p>I will personally review your request and help you build a plan for success.</p><p>By purchasing this product, I will create a custom video/ product just for you where I address your unique request and give you my best recommendations!</p><p><strong>This is for you if you’re looking to:</strong></p><ul><li>Have your unique request reviewed</li><li>Looking for direction that is specific to you</li><li>Need guidance but not ready for a 1:1 session</li></ul>");
    const [isCreatingMode, setIsCreatingMode] = useState(true);

    const [iconImageUrl, setIconImageUrl] = useState(askMeAnyThinIcon);
    const [file, setFile] = useState(null);
    const [iconImage, setIconImage] = useState('');
    const [coverImage, setCoverImage] = useState('');

    const [coverImageUrl, setCoverImageUrl] = useState(checkoutCover);
    const [cover, setCover] = useState(null);

    const handleFileChange = (newFile) => {
        if (newFile) {
            setFile(newFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                setIconImageUrl(e.target.result);
            };
            reader.readAsDataURL(newFile);
        } else {
            setIconImageUrl(askMeAnyThinIcon);
        }
    };

    const handleCoverChange = (newCover) => {
        if (newCover) {
            setCover(newCover);
            const reader = new FileReader();
            reader.onload = (e) => {
                setCoverImageUrl(e.target.result);
            };
            reader.readAsDataURL(newCover);
        } else {
            setCoverImageUrl(checkoutCover);
        }
    };

    const handleParamsChange = (newParams) => {
        setParamsValues(newParams);
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        if (pid && pid > 0) {
            const product_id = pid;
            const fetchData = async () => {
                try {
                    const data = await productService.getAskMeAnyThingProductById(product_id);
                    if (data) {
                        setIsCreatingMode(false)
                        setParamsValues({
                            thumbnail_style_option: data.thumbnail_style_option,
                            thumbnail_icon_image: data.thumbnail_icon_image,
                            thumbnail_title: data.thumbnail_title,
                            thumbnail_subtitle: data.thumbnail_subtitle,
                            thumbnail_button: data.thumbnail_button,
                            checkout_cover_image: data.checkout_cover_image,
                            checkout_short_description: data.checkout_short_description,
                            checkout_bottom_title: data.checkout_bottom_title,
                            checkout_call_to_action_button: data.checkout_call_to_action_button,
                            checkout_price: data.checkout_price,
                            product_id: pid,
                        });
                        setCheckoutDescription(data.checkout_description);
                        let baseURL= 'https://api.sellinb.io';
                        if (data.thumbnail_icon_image) {
                            let thumbnail_icon_image = `${baseURL}/thedss/images/as_me_an_pr/${data.thumbnail_icon_image}`
                            setIconImage(thumbnail_icon_image)
                        }
                        if (data.checkout_cover_image) {
                            let checkout_cover_image = `${baseURL}/thedss/images/as_me_an_pr/${data.checkout_cover_image}`
                            setCoverImage(checkout_cover_image)
                        }

                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchData();
        }
    }, [pid]);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="Ask Me AnyThing"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="external-links-products-page bg-white p-3 rounded-3">
                    <div className="back-link-holder mb-3">
                        <Link to="/user/store/store-products" className="d-flex align-items-center c-pointer">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiArrowBack size="20"/>
                            </div>
                            <div className="fw-bold-500">Back to Store Products</div>
                        </Link>
                    </div>
                    <div className="black-text-color fw-bold-600 mb-3">
                        Customize Ask Me AnyThing Service
                    </div>
                    <div className="row m-0 flex-column-reverse flex-xl-row">
                        <div className="col-xl-8">
                            <AskMeAnyThingForm
                                params={params}
                                setParamsValues={handleParamsChange}
                                checkout_description={checkoutDescription}
                                setCheckoutDescription={setCheckoutDescription}
                                file={file}
                                setFile={handleFileChange}
                                iconImageUrl={iconImageUrl}
                                cover={cover}
                                setCover={handleCoverChange}
                                coverImageUrl={coverImageUrl}
                                iconImage={iconImage}
                                setIconImage={setIconImage}
                                coverImage={coverImage}
                                setCoverImage={setCoverImage}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                onTabChange={handleTabChange}
                                isCreatingMode={isCreatingMode}
                                setIsCreatingMode={setIsCreatingMode}
                                userProductsLength={data}
                            />
                        </div>
                        {activeTab === 'thumbnail' && (
                            <div className="col-xl-4 mb-3 mb-xl-0 main-virtual-tel-holder">
                                <div
                                    className="border virtual-tel border-3 border-bottom-0 rounded-top-5 h-100 p-3 mx-auto d-flex flex-column align-items-center justify-content-center">
                                    <div className="main-virtual-product-preview light-shadow p-2 w-100 rounded-2">
                                        {params.thumbnail_style_option === "button" ? (
                                            <div className="d-flex align-items-center">
                                                <div className="img-holder p-2 rounded-2 me-2">
                                                    {iconImage ? (
                                                        <img src={`${iconImage}`} alt="Ask Me Anything"/>
                                                    ) : iconImageUrl ? (
                                                        <img src={iconImageUrl || askMeAnyThinIcon}
                                                             alt="Ask Me Anything"/>
                                                    ) : (
                                                        <img src={iconImageUrl || askMeAnyThinIcon}
                                                             alt="Ask Me Anything"/>
                                                    )}
                                                </div>
                                                <div className="button-text small fw-bold-600">
                                                    {params.thumbnail_button}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="callout-preview">
                                                <div className="d-flex align-items-center">
                                                    <div className="img-holder p-2 rounded-2 me-2">
                                                        {iconImage ? (
                                                            <img src={`${iconImage}`} alt="Ask Me Anything"/>
                                                        ) : iconImageUrl ? (
                                                            <img src={iconImageUrl || askMeAnyThinIcon}
                                                                 alt="Ask Me Anything"/>
                                                        ) : (
                                                            <img src={iconImageUrl || askMeAnyThinIcon}
                                                                 alt="Ask Me Anything"/>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="title-text small fw-bold-600">
                                                            {params.thumbnail_title}
                                                        </div>
                                                        <div className="thumbnail_subtitle-text text-black-50 small">
                                                            {params.thumbnail_subtitle}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="main-button-color text-white rounded-3 text-center py-2 mt-2 small fw-bold-500">
                                                    {params.thumbnail_button}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'checkout' && (
                            <div className="col-xl-4 mb-3 mb-xl-0 main-virtual-tel-holder-tall">
                                <div
                                    className="border border-3 border-bottom-0 virtual-tel rounded-top-5 h-100 mx-auto overflow-y-scroll">
                                    <div className="main-virtual-product-preview w-100 rounded-top-5">
                                        <div className="cover-holder position-relative">
                                            {coverImage ? (
                                                <img src={`${coverImage}`} alt="Ask Me Anything"
                                                     className="rounded-top-5 cover-img"/>
                                            ) : coverImageUrl ? (
                                                <img src={coverImageUrl || checkoutCover} alt="Ask Me Anything"
                                                     className="rounded-top-5 cover-img"/>
                                            ) : (
                                                <img src={coverImageUrl || checkoutCover} alt="Ask Me Anything"
                                                     className="rounded-top-5 cover-img"/>
                                            )}
                                            <div
                                                className="checkout-short-description position-absolute bottom-0 start-0 fw-bold-500 text-white w-100 p-2">
                                                {params.checkout_short_description}
                                            </div>
                                        </div>
                                        <div className="checkout-description-holder p-3 small">
                                            <div dangerouslySetInnerHTML={{__html: checkoutDescription}}></div>
                                        </div>
                                        <div className="text-center main-dark-color fw-bold-600 fs-5 px-3 mb-3">
                                            {params.checkout_bottom_title}
                                        </div>
                                        <div className="inputs-holder px-4 mb-3">
                                            <input type="text" placeholder="Enter your name" className="form-control"
                                                   readOnly disabled/>
                                        </div>
                                        <div className="inputs-holder px-4 mb-3">
                                            <input type="text" placeholder="Enter your email" className="form-control"
                                                   readOnly disabled/>
                                        </div>
                                        <div className="inputs-holder px-4 mb-3">
                                            <input type="text" placeholder="Enter your phone" className="form-control"
                                                   readOnly disabled/>
                                        </div>
                                        <div
                                            className="price-holder d-flex align-items-center justify-content-between px-4">
                                            <div className="text-black-50 fs-5">
                                                Total:
                                            </div>
                                            <div className="black-text-color fw-bold-600 fs-5">
                                                {params.checkout_price > 0 ? (
                                                    <span>${params.checkout_price}</span>
                                                ) : (
                                                    <span>FREE</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="call-to-action-btn px-4 mb-3 mt-4">
                                            <div
                                                className="main-button-color text-white rounded-3 text-center py-2 mt-2 small fw-bold-500">
                                                {params.checkout_call_to_action_button}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
};

export default AskMeAnyThing;