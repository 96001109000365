import {BiEdit, BiTrash} from "react-icons/bi";
import {useState} from "react";
import {ebookService} from "../../../_services";
import defaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";
import {Link} from "react-router-dom";

const EbookRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteEbook = async () => {
        setIsDeleting(true);
        try {
            await ebookService.deleteEbook(props.ebook_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting ebook:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditEbook = () => {
        props.handleEditEbook(props);
        props.sendHiddenToParent(!props.hidden);
    };

    let baseURL = 'https://api.sellinb.io/';

    return (
        <div className="col-xl-6 mb-3">
            <div className="light-hover-effect shadow-sm rounded-3 p-2 h-100">
                <div className="box-holder row h-100">
                    <div className="ebook-image col-5 col-md-4 col-lg-3 pe-2">
                        {props.book_cover ? (
                            <img className="ebook-bg rounded-3" alt={props.title}
                                 style={{
                                     width: "100%",
                                     height: "100%",
                                     objectFit: "cover",
                                     maxHeight: "275px",
                                     maxWidth: "100%"
                                 }}
                                 src={`${baseURL}thedss/images/ebooks/${props.book_cover}`}
                            />
                        ) : (
                            <img className="ebook-bg rounded-3" alt={props.title}
                                 style={{
                                     width: "100%",
                                     height: "100%",
                                     objectFit: "cover",
                                     maxHeight: "275px",
                                     maxWidth: "100%"
                                 }}
                                 src={defaultEbookBg}
                            />
                        )}
                    </div>
                    <div className="ebook-info col-7 col-md-8 col-lg-9 d-flex flex-column h-100 ps-2 small">
                        <div className="mb-1">
                            <span className="text-black-50">Title: </span>
                            <span className="ebook-title fw-bold-600 black-text-color">{props.title}</span>
                        </div>
                        <div className="mb-1">
                            <span className="text-black-50">Category: </span>
                            <span className="ebook-title fw-bold-500 black-text-color">{props.category}</span>
                        </div>
                        <div className="black-text-color mb-1">
                            <span className="text-black-50">Range price: </span>
                            ${props.min_price}<span className="gray-text-color"> ~ </span>${props.max_price}
                        </div>
                        <div className="mb-1">
                            <span className="text-black-50">Access: </span>
                            <span className="ebook-title fw-bold-500 black-text-color">
                                {props.access_type === 'Free' && 'Free, Pro, Premium'}
                                {props.access_type === 'Pro' && 'Pro, Premium'}
                                {props.access_type === 'Premium' && 'Premium'}
                            </span>
                        </div>
                        <span className="text-black-50 text-center">Affiliate</span>
                        <div className="mb-1 d-flex justify-content-between">
                            <div className="d-flex flex-column align-items-center">
                                <div className="fw-normal">Free</div>
                                <div className="fw-bold-500">
                                    {props.access_type === 'Free' ? props.aff_percentage_free + '%' : '***'}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="fw-normal">Pro</div>
                                <div className="fw-bold-500">
                                    {props.access_type !== 'Premium' ? props.aff_percentage_pro + '%' : '***'}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="fw-normal">Premium</div>
                                <div className="fw-bold-500">
                                    {props.aff_percentage_premium + '%'}
                                </div>
                            </div>
                        </div>
                        <div className="mt-auto">
                            <div className="actions-btn my-3">
                                <div className="d-flex align-items-center px-2">
                                    <div>
                                        <button className="border-0 bg-transparent" onClick={handleEditEbook}
                                                disabled={!props.hidden}>
                                            <BiEdit size="25" className="edit-icon text-success"/>
                                        </button>
                                    </div>
                                    <div>
                                        {isDeleting ? (
                                            <span className="text-danger">Loading...</span>
                                        ) : (
                                            <BiTrash size="25" onClick={handleDeleteEbook}
                                                     className="text-danger c-pointer"/>
                                        )}
                                    </div>
                                    <div className="ms-auto">
                                        <Link to={`/admin/products/checkout/${props.ebook_id}`}
                                              className="link-as-button px-2 py-2 px-lg-4 py-lg-2 rounded-3 small">
                                            Checkout
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EbookRow;