import React, {useEffect, useId, useState} from "react";
import {accountService, storeService} from "../../../_services";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";

const ChooseTemplate = (props) => {
    const [refresh, setRefresh] = useState(false);
    const [params, setParamsValues] = useState({
        template_id: "",
    });
    const [oldValues, setOldValues] = useState({
        template_id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const template_id = await storeService.getStoreTemplate();
                if (template_id) {
                    setParamsValues({
                        template_id: template_id,
                    });
                    setOldValues({
                        template_id: template_id,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [refresh]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("template_id", params.template_id);
            await storeService.updateStoreTemplate(formData);
            setRefresh(!refresh)
        } catch (error) {
            console.error("Error Updating Store Template:", error);
        }
    };

    if (params.template_id) {
        return (
            <div className="main-template-change-section py-1 px-2">
                <form onSubmit={onSubmit}>
                    <div className="form-group col-lg-4 col-xl-3">
                        <label htmlFor="template_id" className="main-dark-color small fw-bold-500 mb-2">
                            Change my store template
                        </label>
                        <select
                            className="form-select form-select-sm"
                            value={params.template_id}
                            onChange={onChange}
                            name="template_id"
                            id="template_id"
                        >
                            <option value="1">Template 1</option>
                            <option value="2">Template 2</option>
                        </select>
                        <button
                            disabled={params.template_id === oldValues.template_id}
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    return null;
};

export default ChooseTemplate;