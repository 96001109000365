import {Link, NavLink, useNavigate} from "react-router-dom";
import mainLogo from "../../../assets/images/global/Inbio_logo.png"
import {
    GoGear,
    GoProject,
    GoSignOut,
    GoStack,
} from "react-icons/go";
import {BiCart, BiUser} from "react-icons/bi";
import {HiOutlineAcademicCap} from "react-icons/hi2";
import {accountService} from "../../../_services";

const Sidebar = () => {
    let navigate = useNavigate();
    const menuItem = [
        {
            path: "/admin/dashboard",
            name: "Dashboard",
            icon: <GoStack size="25"/>
        },
        {
            path: "/admin/products",
            name: "Products",
            icon: <GoProject size="25"/>
        },
        {
            path: "/admin/users",
            name: "Users",
            icon: <BiUser size="25"/>
        },
        {
            path: "/admin/orders",
            name: "Orders",
            icon: <BiCart size="25"/>
        },
        {
            path: "/admin/academy",
            name: "Academy",
            icon: <HiOutlineAcademicCap size="25"/>
        },
    ]

    const logout = () => {
        accountService.logout()
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('token_inbio');
                    localStorage.removeItem('full_name');
                    navigate("/auth/login")
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="the-main-sidebar position-fixed top-0 start-0 d-none d-lg-block">
            <div className="h-100 d-flex flex-column px-3 pb-3">
                <div className="sidebar-logo-holder mb-2">
                    <Link to="/admin/dashboard"
                          className="logo-holder d-flex align-items-center justify-content-center h-100">
                        <img src={mainLogo} alt="Sellinbio" className="logo-img"/>
                    </Link>
                </div>
                <div className="sidebar-links">
                    {
                        menuItem.map((item, index) => (
                            <div className="nav-link mt-2 list-unstyled align-items-center" key={index}>
                                <div className="menu-item">
                                    <NavLink to={item.path} key={index}
                                             className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            {item.icon}
                                        </div>
                                        <div className="nav-text">{item.name}</div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="mt-auto">
                    <div className="nav-link mt-2 list-unstyled align-items-center">
                        <div className="menu-item">
                            <NavLink to="/admin/settings"
                                     className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <GoGear size="25"/>
                                </div>
                                <div className="nav-text">Settings</div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="nav-link list-unstyled align-items-center">
                        <div className="menu-item logout-button">
                            <button type="button" onClick={logout}
                                    className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center border-0 bg-transparent">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <GoSignOut size="25"/>
                                </div>
                                <div className="nav-text">Logout</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;