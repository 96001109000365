import {useId, useState, useEffect} from "react";
import {pubGlobalService} from "../../../_services";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";

const ChangeCurrency = (props) => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => pubGlobalService.getCurrencies());
    const [params, setParamsValues] = useState({currency: {rate: "", code: ""}});

    useEffect(() => {
        const storedRate = localStorage.getItem('inbio_chosen_currency');
        const storedCode = localStorage.getItem('inbio_chosen_currency_name');

        if (storedRate && storedCode) {
            setParamsValues({currency: {rate: storedRate, code: storedCode}});
        } else if (data && data.length > 0) {
            const usdCurrency = data.find(item => item.code === "USD") || data[0];
            setParamsValues({currency: {rate: usdCurrency.rate, code: usdCurrency.code}});
        }
    }, [data]);

    const onChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedOption = e.target.options[selectedIndex];
        const selectedCurrency = {
            rate: e.target.value,
            code: selectedOption.getAttribute('data-code')
        };

        setParamsValues({
            ...params,
            currency: selectedCurrency
        });

        localStorage.setItem('inbio_chosen_currency', selectedCurrency.rate);
        localStorage.setItem('inbio_chosen_currency_name', selectedCurrency.code);
        props.sendRefreshToParent(!props.refresh);
    };

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        );
    }

    if (data && data.length > 0) {
        return (
            <div className="main-currency-change-holder py-1 px-2">
                <select
                    style={{width: "fit-content"}}
                    className="form-select form-select-sm border-start-0 border-top-0 border-bottom-0 rounded-0 me-auto"
                    value={params.currency.rate}
                    onChange={onChange}
                    name="currency"
                    id="currency"
                >
                    {data.map((item, index) => (
                        <option
                            value={item.rate}
                            data-code={item.code}
                            key={index}>
                            {item.code}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    return null;
};

export default ChangeCurrency;
