import {Route, Routes} from "react-router-dom";

import {
    ALayout,
    Dashboard,
    Error, PdfBooks,
    Products,
    Academy,
    AcademyCategories,
    EbookCategories,
    Users,
    AdminProductDetails,
    Settings,
} from "./Admin";

const AuthRouter = () => {
    return (
        <Routes>
            <Route element={<ALayout/>}>
                <Route index element={<Dashboard/>}/>
                <Route path="dashboard" element={<Dashboard/>}/>
                <Route index path="/" element={<Dashboard/>}/>
                <Route index path="/products" element={<Products/>}/>
                <Route index path="/academy" element={<Academy/>}/>
                <Route index path="/settings" element={<Settings/>}/>
                <Route index path="/academy-categories" element={<AcademyCategories/>}/>
                <Route index path="/users" element={<Users/>}/>
                <Route index path="/products/pdf-books" element={<PdfBooks/>}/>
                <Route index path="/ebook-categories" element={<EbookCategories/>}/>
                <Route index path="/products/checkout/:pid" element={<AdminProductDetails/>}/>
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default AuthRouter;