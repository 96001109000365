import React, {useEffect, useState} from "react";
import {ebookService} from "../../../_services";
import DefaultEbookBg from "../../../assets/images/global/default_ebook_bg.png"

const EbookForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);

    const [params, setParamsValues] = useState({
        title: "",
        category_id: "",
        min_price: "",
        max_price: "",
        access_type: "Free",
        aff_percentage_free: "",
        aff_percentage_pro: "",
        aff_percentage_premium: "",
        book_cover: "",
    });

    const [bookCover, setBookCover] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ebookService.getEbookCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.ebook.title,
                category_id: props.ebook.category_id,
                min_price: props.ebook.min_price,
                max_price: props.ebook.max_price,
                access_type: props.ebook.access_type,
                aff_percentage_free: props.ebook.aff_percentage_free,
                aff_percentage_pro: props.ebook.aff_percentage_pro,
                aff_percentage_premium: props.ebook.aff_percentage_premium,
                book_cover: props.ebook.book_cover,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title: "",
                category_id: "",
                min_price: "",
                max_price: "",
                access_type: "Free",
                aff_percentage_free: "",
                aff_percentage_pro: "",
                aff_percentage_premium: "",
                book_cover: "",
            });
        }
    }, [props.editMode, props.ebook]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onBookCoverChange = (e) => {
        setBookCover(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("title", params.title);
            formData.append("category_id", params.category_id);
            formData.append("min_price", params.min_price);
            formData.append("max_price", params.max_price);
            formData.append("access_type", params.access_type);
            formData.append("aff_percentage_free", params.aff_percentage_free);
            formData.append("aff_percentage_pro", params.aff_percentage_pro);
            formData.append("aff_percentage_premium", params.aff_percentage_premium);
            formData.append("book_cover", bookCover);

            if (isCreatingMode) {
                await ebookService.createEbook(formData);
            } else {
                formData.append("id", props.ebook.ebook_id);
                await ebookService.updateEbook(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                title: "",
                category_id: "",
                min_price: "",
                max_price: "",
                access_type: "Free",
                aff_percentage_free: "",
                aff_percentage_pro: "",
                aff_percentage_premium: "",
                book_cover: "",
            });
            setBookCover(null);
        } catch (error) {
            console.error("Error Creating/Updating ebook:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
        setParamsValues({
            title: "",
            category_id: "",
            min_price: "",
            max_price: "",
            access_type: "Free",
            aff_percentage_free: "",
            aff_percentage_pro: "",
            aff_percentage_premium: "",
            book_cover: "",
        });
        setBookCover(null);
    }

    let baseURL = 'https://api.sellinb.io/';

    return (
        <div className="main-form-holder add-ebook-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="main-text-color fw-bold-600 text-center">Add a New E-book</div>
            <div className="form-holder small">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="title">
                            Title:
                        </label>
                        <div className="title-holder d-flex align-items-center">
                            <div className="title-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="The Title of the E-book"
                                    value={params.title}
                                    onChange={onChange}
                                    name="title"
                                    id="title"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="category_id">
                            Ebook Category:
                        </label>
                        <div className="category-id-holder d-flex align-items-center">
                            <div className="category-id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.category_id}
                                    onChange={onChange}
                                    name="category_id"
                                    id="category_id"
                                    required
                                >
                                    <option value="0">Please Select a Category</option>
                                    {categoriesData.length > 0 && (
                                        categoriesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-xl-row align-items-center">
                        <div className="form-group mb-4 w-50">
                            <label className="mb-1" htmlFor="book_cover">
                                <div className="link-as-button px-3 py-1 rounded-2 mb-1">Upload Cover:</div>
                                <div className="text-black-50">*(Vertical Images)</div>
                            </label>
                            <div className="book-cover-holder d-flex align-items-center">
                                <div className="book-cover-input flex-grow-1">
                                    <input
                                        type="file"
                                        className="form-control py-2 d-none"
                                        onChange={onBookCoverChange}
                                        name="book_cover"
                                        id="book_cover"
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-4 text-center w-50">
                            <label className="mb-1 text-black-50" htmlFor="selectedImage">
                                Selected Cover:
                            </label>
                            <div className="selected-image-holder">
                                <div className="selected-image mx-auto">
                                    {bookCover ? (
                                        <img
                                            height="200"
                                            width="130"
                                            style={{objectFit: "cover"}}
                                            src={URL.createObjectURL(bookCover)}
                                            alt="Selected Cover For The E-book"
                                            className="selected-image"
                                        />
                                    ) : params.book_cover ? (
                                        <img
                                            height="200"
                                            width="130"
                                            style={{objectFit: "cover"}}
                                            src={`${baseURL}thedss/images/ebooks/${params.book_cover}`}
                                            alt="Selected Cover For The E-book"
                                            className="selected-image"
                                        />
                                    ) : (
                                        <img
                                            height="200"
                                            width="130"
                                            style={{objectFit: "cover"}}
                                            src={DefaultEbookBg}
                                            alt="Selected Cover For The E-book"
                                            className="selected-image"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="form-group mb-4 w-50 pe-2 pe-xl-3">
                            <label className="mb-1" htmlFor="min_price">
                                Minimum Price:
                            </label>
                            <div className="duration-holder d-flex align-items-center">
                                <div className="duration-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Minimum Price for The E-book"
                                        value={params.min_price}
                                        onChange={onChange}
                                        name="min_price"
                                        id="min_price"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-4 w-50 ps-2 ps-xl-3">
                            <label className="mb-1" htmlFor="max_price">
                                Maximum Price:
                            </label>
                            <div className="duration-holder d-flex align-items-center">
                                <div className="duration-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control text py-2"
                                        placeholder="Maximum Price for The E-book"
                                        value={params.max_price}
                                        onChange={onChange}
                                        name="max_price"
                                        id="max_price"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="access_type">
                            Access Type:
                        </label>
                        <div className="access-type-holder d-flex align-items-center">
                            <div className="access-type-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.access_type}
                                    onChange={onChange}
                                    name="access_type"
                                    id="access_type"
                                    required
                                >
                                    <option value="Free">Free</option>
                                    <option value="Pro">Pro</option>
                                    <option value="Premium">Premium</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-center fw-bold-500">Affiliate Percentage</div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group mb-4">
                                    <label className="mb-1" htmlFor="aff_percentage_free">
                                        Free (%):
                                    </label>
                                    <div className="affiliate-holder d-flex align-items-center">
                                        <div className="affiliate-input flex-grow-1">
                                            <input
                                                type="text"
                                                className="form-control text py-2"
                                                placeholder="Affiliate Percentage For Free Users"
                                                value={params.aff_percentage_free || ""}
                                                onChange={onChange}
                                                name="aff_percentage_free"
                                                id="aff_percentage_free"
                                                disabled={params.access_type === 'Pro' || params.access_type === 'Premium'}
                                                required={params.access_type === 'Free'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group mb-4">
                                    <label className="mb-1" htmlFor="aff_percentage_pro">
                                        Pro (%):
                                    </label>
                                    <div className="affiliate-holder d-flex align-items-center">
                                        <div className="affiliate-input flex-grow-1">
                                            <input
                                                type="text"
                                                className="form-control text py-2"
                                                placeholder="Affiliate Percentage For Pro Users"
                                                value={params.aff_percentage_pro || ""}
                                                onChange={onChange}
                                                name="aff_percentage_pro"
                                                id="aff_percentage_pro"
                                                disabled={params.access_type === 'Premium'}
                                                required={params.access_type !== 'Premium'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group mb-4">
                                    <label className="mb-1" htmlFor="aff_percentage_premium">
                                        Premium (%):
                                    </label>
                                    <div className="affiliate-holder d-flex align-items-center">
                                        <div className="affiliate-input flex-grow-1">
                                            <input
                                                type="text"
                                                className="form-control text py-2"
                                                placeholder="Affiliate Percentage For Premium Users"
                                                value={params.aff_percentage_premium || ""}
                                                onChange={onChange}
                                                name="aff_percentage_premium"
                                                id="aff_percentage_premium"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-success">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default EbookForm;