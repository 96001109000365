import {UserRow, Footer, Header} from "./Admin";
import {useEffect, useState} from "react";
import {adminUsersService} from "../../_services";

const Users = () => {
    const [usersData, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminUsersService.getUsers();
                setData(data);
            } catch (error) {
                console.error("Error fetching Academy data:", error);
            }
        };

        fetchData();
    }, []);


    return(
        <>
            <Header title="Admin - Users"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-users-page bg-white p-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">Users List</div>
                    </div>

                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Username</td>
                                <td className="text-black-50">Full Name</td>
                                <td className="text-black-50">Email</td>
                                <td className="text-black-50">Phone Number</td>
                                <td className="text-black-50">Registered</td>
                            </tr>
                            </thead>
                            <tbody>
                            {usersData.length > 0 ? (
                                usersData.map((user) => (
                                    <UserRow
                                        key={user.id}
                                        user_id={user.id}
                                        username={user.username}
                                        full_name={user.full_name}
                                        email={user.email}
                                        phone_number={user.phone_number}
                                        created_date={user.created_date}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Users;