import {EbookFilteredData, EbookForm, EbookRow, Footer, FreeAccessAlert, Header} from "./Private";
import React, {useEffect, useId, useState} from "react";
import {accountService, productService} from "../../_services";
import {BiArrowBack, BiSearchAlt} from "react-icons/bi";
import FeaturedEbooks from "../../components/private/products/FeaturedEbooks";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import {ThreeDots} from "react-loader-spinner";

const PdfBooks = () => {
    const [category, setCategory] = useState(0);
    const [categoryName, setCategoryName] = useState("All");
    const [categoriesData, setCategoriesData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [hidden, setHidden] = useState(true);
    const [typeFormEbook, setTypeFormEbook] = useState("");
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getProductsLength());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await productService.getEbookCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    const changeCategory = (category, category_name) => {
        setCategory(category);
        setCategoryName(category_name)
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="PDF Books"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white p-3 rounded-3 mb-3 ebooks-main-page">
                    <div className="back-link-holder mb-3">
                        <Link to="/user/store/store-products" className="d-flex align-items-center c-pointer">
                            <div className="d-flex align-items-center justify-content-center me-1">
                                <BiArrowBack size="20"/>
                            </div>
                            <div className="fw-bold-500">Back to Store Products</div>
                        </Link>
                    </div>
                    <div className={`${hidden ? "d-block" : "d-none"}`}>
                        <div className="mb-3">
                            <div className="main-dark-color fw-bold">Select an ebook to promote</div>
                            <div className="text-real-small fw-bold-500 light-text-color">Earn commissions on each sale
                                made
                            </div>
                        </div>
                        <div className="search-input-holder position-relative mb-4">
                            <div className="position-absolute top-50 translate-middle-y ps-2">
                                <BiSearchAlt size="30"
                                             className="gray-text-color social-logo-icon pe-1 border-end"/>
                            </div>
                            <input
                                type="text"
                                className="form-control text py-2"
                                placeholder="Search ebooks"
                                value={searchQuery}
                                onChange={handleSearchInputChange}
                                id="search"
                                maxLength={50}
                            />
                        </div>
                        <div className="category-scroll-container mb-4">
                            <div className="category-holder text-nowrap overflow-x-scroll">
                                <div className="category-btn-holder d-inline-block px-2">
                                    <button
                                        className={`category-btn text-nowrap px-1 border-0 bg-transparent ${category === 0 ? 'active' : ''}`}
                                        onClick={() => changeCategory(0, "All")}>All
                                    </button>
                                </div>
                                {categoriesData.length > 0 && (
                                    categoriesData.map((item, index) => (
                                        <div className="category-btn-holder d-inline-block px-2" key={index}>
                                            <button
                                                className={`category-btn text-nowrap px-1 border-0 bg-transparent ${category === item.id ? 'active' : ''}`}
                                                onClick={() => changeCategory(item.id, item.name)}>{item.name}
                                            </button>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    {typeFormEbook !== 'featured' && (
                        (category === 0 && !searchQuery) && (
                            <div className="faetured-ebooks-holder">
                                <FeaturedEbooks
                                    sendHiddenToParent={setHidden}
                                    hidden={hidden}
                                    typeFormEbook="all"
                                    setTypeFormEbook={setTypeFormEbook}
                                    userProductsLength={data}
                                />
                            </div>
                        )
                    )}

                    {typeFormEbook !== 'all' && (
                        <EbookFilteredData
                            category_id={category}
                            categoryName={categoryName}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                            searchQuery={searchQuery}
                            typeFormEbook="featured"
                            setTypeFormEbook={setTypeFormEbook}
                            userProductsLength={data}
                        />
                    )}

                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default PdfBooks;