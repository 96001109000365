import {Tab, Tabs} from "react-bootstrap";
import PaypalEdit from "./PaypalEdit";
import WiseEdit from "./WiseEdit";
import PayoneerEdit from "./PayoneerEdit";
import MobileMoneyEdit from "./MobileMoneyEdit";
import BankAccountEdit from "./BankAccountEdit";

const PaymentMethods = () => {

    function CustomTabPaypal() {
        return (
            <div className="d-flex align-items-center">
                {/*<div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>*/}
                <div className="tab-title px-1">PayPal</div>
            </div>
        );
    }

    function CustomTabBankAccount() {
        return (
            <div className="d-flex align-items-center">
                {/*<div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>*/}
                <div className="tab-title px-1">Bank Account</div>
            </div>
        );
    }

    function CustomTabWise() {
        return (
            <div className="d-flex align-items-center">
                {/*<div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>*/}
                <div className="tab-title px-1">Wise</div>
            </div>
        );
    }

    function CustomTabPayoneer() {
        return (
            <div className="d-flex align-items-center">
                {/*<div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>*/}
                <div className="tab-title px-1">Payoneer</div>
            </div>
        );
    }

    function CustomTabMobileMoney() {
        return (
            <div className="d-flex align-items-center">
                {/*<div className="d-flex align-items-center px-1"><BiUser className="tab-icon"/></div>*/}
                <div className="tab-title px-1">Mobile Money</div>
            </div>
        );
    }

    return (
        <div className="main-custom-tabs-holder mt-4">
            <Tabs
                variant="pills"
                defaultActiveKey="paypal"
                className="mb-3"
            >
                <Tab eventKey="paypal" title={<CustomTabPaypal/>}>
                    <PaypalEdit/>
                </Tab>
                <Tab eventKey="bank-account" title={<CustomTabBankAccount/>}>
                    <BankAccountEdit/>
                </Tab>
                <Tab eventKey="wise" title={<CustomTabWise/>}>
                    <WiseEdit/>
                </Tab>
                <Tab eventKey="payoneer" title={<CustomTabPayoneer/>}>
                    <PayoneerEdit/>
                </Tab>
                <Tab eventKey="mobile-money" title={<CustomTabMobileMoney/>}>
                    <MobileMoneyEdit/>
                </Tab>
            </Tabs>
        </div>
    );
};

export default PaymentMethods;