import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="mt-auto pt-3 text-center">
            <Link to="/user/dashboard">
                <div className="btn btn-primary fw-bold-500 btn-sm px-3 py-1 small text-white mx-auto"
                     style={{width: "fit-content"}}>
                    User Dashboard
                </div>
            </Link>
            <div className="copyright-text gray-text-color small">
                All Right Reserved Copyright &copy; 2024 <span className="fw-bold">Sellinbio</span>
            </div>
        </div>
    );
}

export default Footer;