import {useEffect, useId, useState} from "react";
import {useQuery} from "react-query";
import {userService} from "../../../_services";
import {BallTriangle} from "react-loader-spinner";
import {Link} from "react-router-dom";
import externalLinkIcon from "../../../assets/images/products/external_link_icon.png";
import askMeAnyThingIcon from "../../../assets/images/products/ask_me_anything_icon.png";
import bookAppointmentIcon from "../../../assets/images/products/book_appointment_icon.png";
import digitalDownloadIcon from "../../../assets/images/products/digital_download_icon.png";

const UserProducts = (props) => {
    let randomID = useId();
    let username = props.username;
    useEffect(() => {
    }, [props.username]);
    const {isLoading, data} = useQuery(randomID, () => userService.getUserProducts(username));
    const userProducts = data || {"data": []}

    const [currency, setCurrency] = useState({
        rate: localStorage.getItem('inbio_chosen_currency') || 1,
        code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
    });
    const [convertedPrices, setConvertedPrices] = useState([]);

    useEffect(() => {
        const initialCurrency = {
            rate: localStorage.getItem('inbio_chosen_currency') || 1,
            code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
        };
        setCurrency(initialCurrency);
    }, [props.refresh]);

    useEffect(() => {
        const updateCurrency = () => {
            const updatedCurrency = {
                rate: localStorage.getItem('inbio_chosen_currency') || 1,
                code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
            };
            setCurrency(updatedCurrency);
        };

        window.addEventListener('storage', updateCurrency);
        return () => {
            window.removeEventListener('storage', updateCurrency);
        };
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            const newPrices = data.map(product => {
                let formattedPrice = '';

                // Ensure product.checkout_price is valid
                if (product.checkout_price == null) {
                    return 'FREE';
                }

                // Ensure currency.rate is valid
                const rate = currency.rate ? parseFloat(currency.rate) : 1;

                // Calculate and format the price
                if (rate !== 1) {
                    const convertedPrice = product.checkout_price / rate;
                    formattedPrice = convertedPrice.toLocaleString(undefined, {
                        style: 'currency',
                        currency: currency.code,
                        maximumFractionDigits: 0 // Round to the nearest whole number
                    });
                } else {
                    formattedPrice = product.checkout_price.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD', // Assuming default currency is USD
                        maximumFractionDigits: 0 // Round to the nearest whole number
                    });
                }

                return formattedPrice !== 'FREE' ? formattedPrice : 'FREE';
            });

            setConvertedPrices(newPrices);
        }
    }, [currency, data, props.refresh]);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    let baseURL = 'https://api.sellinb.io';

    return (
        <div className="user-products-section row m-0">
            {userProducts.length > 0 ? (
                userProducts.map((product, index) => (
                    <div className="col-lg-6 col-xl-4 m-0" key={product.id}>
                        {product.thumbnail_style_option === 'callout' ? (
                            <div className="callout-layout my-2 px-2">
                                <div className="layout-content shadow rounded-3 p-3">
                                    <div className="d-flex">
                                        <div
                                            className="prod-icon-holder me-3 d-flex justify-content-center align-items-center rounded-2">
                                            {product.thumbnail_icon_image ? (
                                                <>
                                                    {product.product_type === 'external_link' && (
                                                        <img className="rounded-2"
                                                             src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                             alt="External Link Icon"/>
                                                    )}
                                                    {product.product_type === 'ask_me_anything' && (
                                                        <img className="rounded-2"
                                                             src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                             alt="Ask Me Any Thing Icon"/>
                                                    )}
                                                    {product.product_type === 'book_appointment' && (
                                                        <img className="rounded-2"
                                                             src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                             alt="Book Appointment Icon"/>
                                                    )}
                                                    {product.product_type === 'digital_download' && (
                                                        <img className="rounded-2"
                                                             src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                             alt="Digital Download Icon"/>
                                                    )}
                                                    {product.product_type === 'admin_ebook' && (
                                                        <img className="rounded-2"
                                                             src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                             alt="Digital Download Icon"/>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {product.product_type === 'external_link' && (
                                                        <img className="rounded-2"
                                                             src={externalLinkIcon}
                                                             alt="External Link Icon"/>
                                                    )}
                                                    {product.product_type === 'ask_me_anything' && (
                                                        <img className="rounded-2"
                                                             src={askMeAnyThingIcon}
                                                             alt="Ask Me Any Thing Icon"/>
                                                    )}
                                                    {product.product_type === 'book_appointment' && (
                                                        <img className="rounded-2"
                                                             src={bookAppointmentIcon}
                                                             alt="Book Appointment Icon"/>
                                                    )}
                                                    {product.product_type === 'digital_download' && (
                                                        <img className="rounded-2"
                                                             src={digitalDownloadIcon}
                                                             alt="Digital Download Icon"/>
                                                    )}
                                                    {product.product_type === 'admin_ebook' && (
                                                        <img className="rounded-2"
                                                             src={digitalDownloadIcon}
                                                             alt="Digital Download Icon"/>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="info-holder w-100">
                                            <div className="title fw-bold-600 mb-1 black-text-color">
                                                {product.thumbnail_title}
                                            </div>
                                            <div className="subtitle small gray-text-color">
                                                {product.thumbnail_subtitle}
                                            </div>
                                            {product.product_type !== 'external_link' && (
                                                <div className="price fw-bold mb-1 light-text-color fs-5 mt-2">
                                                    {convertedPrices[index]}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="link-holder mt-3">
                                        {product.product_type === 'external_link' ? (
                                            <Link to={`${product.thumbnail_url}`}
                                                  className="d-inline-block w-100 link-button text-white rounded-4 p-3 text-center fw-bold-600">
                                                {product.thumbnail_button}
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`/${username}/${product.product_type === 'admin_ebook' ? "ebook/" : ""}${product.id}`}
                                                className="d-inline-block w-100 link-button text-white rounded-4 p-3 text-center fw-bold-600">
                                                {product.thumbnail_button}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="button-layout my-2 px-2">
                                {product.product_type === 'external_link' ? (
                                    <Link to={`${product.thumbnail_url}`}
                                          className="layout-content shadow rounded-3 p-3 d-inline-block w-100">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="prod-icon-holder me-3 d-flex justify-content-center align-items-center rounded-2">
                                                {product.thumbnail_icon_image ? (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={externalLinkIcon}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={askMeAnyThingIcon}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={bookAppointmentIcon}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="info-holder w-100">
                                                <div className="title fw-bold-600 mb-1 black-text-color">
                                                    {product.thumbnail_title}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <Link
                                        // to={`/${username}/${product.id}${product.thumbnail_title && `/${product.thumbnail_title.replace(/\s+/g, '-')}`}`}
                                        to={`/${username}/${product.product_type === 'admin_ebook' ? "ebook/" : ""}${product.id}`}
                                        className="layout-content shadow rounded-3 p-3 d-inline-block w-100">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="prod-icon-holder me-3 d-flex justify-content-center align-items-center rounded-2">
                                                {product.thumbnail_icon_image ? (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={externalLinkIcon}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={askMeAnyThingIcon}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={bookAppointmentIcon}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="info-holder d-flex align-items-center justify-content-between w-100">
                                                <div className="title fw-bold-600 mb-1 black-text-color">
                                                    {product.thumbnail_title}
                                                </div>
                                                {product.product_type !== 'external_link' && (
                                                    <div className="price fw-bold mb-1 light-text-color fs-5">
                                                        {convertedPrices[index]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );
}
export default UserProducts;