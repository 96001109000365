import React, {useEffect, useState} from "react";
import {adminAcademyService} from "../../../_services";
import DefaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";

const AcademyForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [categoriesData, setCategoriesData] = useState([]);

    const [params, setParamsValues] = useState({
        title: "",
        category_id: "",
        description: "",
        video_url: "",
        access_type: "",
        video_cover: "",
    });
    const [videoCover, setVideoCover] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminAcademyService.getAcademyCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.academy.title,
                category_id: props.academy.category_id,
                description: props.academy.description,
                video_url: props.academy.video_url,
                access_type: props.academy.access_type,
                video_cover: props.academy.video_cover,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title: "",
                category_id: "",
                description: "",
                video_url: "",
                access_type: "",
                video_cover: "",
            });
        }
    }, [props.editMode, props.academy]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onVideoCoverChange = (e) => {
        setVideoCover(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", params.title);
            formData.append("category_id", params.category_id);
            formData.append("description", params.description);
            formData.append("video_url", params.video_url);
            formData.append("access_type", params.access_type);
            formData.append("video_cover", videoCover);

            if (isCreatingMode) {
                await adminAcademyService.createAcademyVideo(formData);
            } else {
                formData.append("id", props.academy.academy_id);
                await adminAcademyService.updateAcademyVideo(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                title: "",
                category_id: "",
                description: "",
                video_url: "",
                access_type: "",
                video_cover: "",
            });
            setVideoCover(null);
        } catch (error) {
            console.error("Error Creating/Updating academy:", error);
        }
    };

    const setFormHidden = () => {
        setVideoCover(null);
        props.sendHiddenToParent(!props.hidden);
    }

    let baseURL = 'https://api.sellinb.io';

    return (
        <div className="main-form-holder add-academy-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center">Add a New academy</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="title">
                            Title:
                        </label>
                        <div className="title-holder d-flex align-items-center">
                            <div className="title-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Title of the Academy Video"
                                    value={params.title}
                                    onChange={onChange}
                                    name="title"
                                    id="title"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="category_id">
                            Academy Category:
                        </label>
                        <div className="category-id-holder d-flex align-items-center">
                            <div className="category-id-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.category_id}
                                    onChange={onChange}
                                    name="category_id"
                                    id="category_id"
                                    required
                                >
                                    <option value="0">Please Select a Category</option>
                                    {categoriesData.length > 0 && (
                                        categoriesData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="access_type">
                            Access Type:
                        </label>
                        <div className="access-type-holder d-flex align-items-center">
                            <div className="access-type-input flex-grow-1">
                                <select
                                    className="form-control py-2"
                                    value={params.access_type}
                                    onChange={onChange}
                                    name="access_type"
                                    id="access_type"
                                    required
                                >
                                    <option value="Free">Free</option>
                                    <option value="Pro">Pro</option>
                                    <option value="Premium">Premium</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-xl-row align-items-center">
                        <div className="form-group mb-4 w-50">
                            <label className="mb-1" htmlFor="video_cover">
                                <div className="link-as-button px-3 py-1 rounded-2 mb-1">Upload Cover:</div>
                            </label>
                            <div className="text-black-50 small">*(Landscape Images)</div>
                            <div className="book-cover-holder d-flex align-items-center">
                                <div className="book-cover-input flex-grow-1">
                                    <input
                                        type="file"
                                        className="form-control py-2 d-none"
                                        onChange={onVideoCoverChange}
                                        name="video_cover"
                                        id="video_cover"
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-4 text-center w-50">
                            <label className="mb-1 text-black-50" htmlFor="selectedImage">
                                Selected Cover:
                            </label>
                            <div className="selected-image-holder">
                                <div className="selected-image mx-auto">
                                    {videoCover ? (
                                        <img
                                            height="130"
                                            width="200"
                                            style={{objectFit: "cover"}}
                                            src={URL.createObjectURL(videoCover)}
                                            alt="Video Cover"
                                            className="selected-image mw-100"
                                        />
                                    ) : params.video_cover ? (
                                        <img
                                            height="130"
                                            width="200"
                                            style={{objectFit: "cover"}}
                                            src={`${baseURL}/thedss/academy/${params.video_cover}`}
                                            alt="Video Cover"
                                            className="selected-image mw-100"
                                        />
                                    ) : (
                                        <img
                                            height="130"
                                            width="200"
                                            style={{objectFit: "cover"}}
                                            src={DefaultEbookBg}
                                            alt="Video Cover"
                                            className="selected-image mw-100"
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="description">
                            Description:
                        </label>
                        <div className="description-holder d-flex align-items-center">
                            <div className="description-input flex-grow-1">
                                 <textarea
                                     className="textarea form-control py-2"
                                     placeholder="Description of the Academy Video"
                                     value={params.description}
                                     onChange={onChange}
                                     name="description"
                                     id="description"
                                     rows={3}
                                     required
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="mb-1" htmlFor="video_url">
                            Video URL:
                        </label>
                        <div className="video_url-holder d-flex align-items-center">
                            <div className="video_url-input flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control text py-2"
                                    placeholder="Video URL For Academy Video"
                                    value={params.video_url}
                                    onChange={onChange}
                                    name="video_url"
                                    id="video_url"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AcademyForm;