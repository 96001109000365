import {useEffect, useState} from "react";
import LineChart from "./LineChart";
import {analyticsService} from "../../../_services";

const Revenue = (props) => {
    const [totalRevenueData, setTotalRevenueData] = useState([]);
    const period = 'sevenDays';
    const today = new Date(); // Get today's date
    const new_7_categories = [];
    let series;

    for (let i = 0; i < 7; i++) { // Generate new_categories for the last 14 days
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days from today
        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
        const day = date.getDate(); // Get day of the month
        const new_category = `${month} / ${day}`;
        new_7_categories.unshift(new_category); // Add category to the beginning of the array
    }

    const dataByPeriod = {
        sevenDays: {
            // categories: ['Feb / 23', 'Feb / 24', 'Feb / 25', 'Feb / 26', 'Feb / 27', 'Feb / 28', 'Feb / 29'],
            categories: new_7_categories,
            series: [{
                name: 'Total Revenue',
                data: [0, 0, 0, 0, 0, 0, 0]
            }]
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const totalRevenueData = await analyticsService.getRevenueData();
                setTotalRevenueData(totalRevenueData);
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const chart_categories = Object.keys(totalRevenueData);
    const chart_values = Object.values(totalRevenueData);

    series = [
        {
            name: 'Total Revenue',
            data: chart_values,
        }
    ];

    const total_revenue = 0;
    while (totalRevenueData.length === 0) {
        return (
            <div className="total-revenue-section">
                <div className="bg-white p-3 pb-0 p-lg-4 rounded-3 mb-3">
                    {!props.summary && (
                        <>
                            <div className="title-holder small light-text-color">
                                Total Lifetime Revenue
                            </div>
                            <div className="total main-dark-color fw-bold fs-1 my-2 ps-1">
                                ${total_revenue.toFixed(2)}
                            </div>
                        </>
                    )}
                    <LineChart
                        categories={dataByPeriod[period].categories}
                        series={dataByPeriod[period].series}
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="total-revenue-section">
            <div className="bg-white p-3 pb-0 p-lg-4 rounded-3 mb-3">
                {!props.summary && (
                    <>
                        <div className="title-holder small light-text-color">
                            Total Lifetime Revenue
                        </div>
                        <div className="total main-dark-color fw-bold fs-1 my-2 ps-1">
                            ${props.total_revenue.toFixed(2)}
                        </div>
                    </>
                )}
                <LineChart
                    categories={chart_categories}
                    series={series}
                />
            </div>
        </div>
    );
};

export default Revenue;