import {useEffect, useState} from "react";
import {MdRemoveRedEye} from "react-icons/md";
import {BiDollarCircle} from "react-icons/bi";
import {IoMagnet} from "react-icons/io5";
import StoreVisits from "../analytics/StoreVisits";
import {TotalRevenue} from "../../../pages/private/Private";
import Leads from "../analytics/Leads";
import {analyticsService} from "../../../_services";

const SummaryAnalytics = () => {
    const [period, setPeriod] = useState('sevenDays');
    const [dataType, setDataType] = useState('siteViews');
    const [totalsData, setTotalsData] = useState({
        visits: 0,
        revenue: 0,
        leads: 0
    });

    useEffect(() => {
        const periodNum = period === 'sevenDays' ? 7 : 14;
        const fetchData = async () => {
            try {
                const total_data = await analyticsService.getSummaryTotals(periodNum);
                setTotalsData(total_data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [period]);

    const changePeriod = (period) => {
        setPeriod(period);
    };
    const changeDataType = (dataType) => {
        setDataType(dataType);
    };
    const today = new Date(); // Get today's date
    const new_7_categories = [];
    for (let i = 0; i < 7; i++) { // Generate new_categories for the last 14 days
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days from today
        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
        const day = date.getDate(); // Get day of the month
        const new_category = `${month} / ${day}`;
        new_7_categories.unshift(new_category); // Add category to the beginning of the array
    }
    const new_14_categories = [];
    for (let i = 0; i < 14; i++) { // Generate new_categories for the last 14 days
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days from today
        const month = date.toLocaleString('default', { month: 'short' }); // Get short month name
        const day = date.getDate(); // Get day of the month
        const new_category = `${month} / ${day}`;
        new_14_categories.unshift(new_category); // Add category to the beginning of the array
    }
    return (
        <div>
            <div className="period-holder d-flex align-items-center ps-xl-4 mt-3 mb-4">
                <div className="period-btn-holder px-2">
                    <button
                        className={`period-btn rounded-4 text-real-small py-1 px-4 border-0 ${period === 'sevenDays' ? 'active' : ''}`}
                        onClick={() => changePeriod('sevenDays')}>Last 7 Days
                    </button>
                </div>
                <div className="period-btn-holder px-2">
                    <button
                        className={`period-btn rounded-4 text-real-small py-1 px-4 border-0 ${period === 'fourteenDays' ? 'active' : ''}`}
                        onClick={() => changePeriod('fourteenDays')}>Last 14 Days
                    </button>
                </div>
            </div>
            <div className="type-holder row px-xl-5 mt-3">
                <div className="type-btn-holder col-xl-4 px-2 mb-3">
                    <button className={`type-btn w-100 rounded-2 bg-transparent border text-real-small pt-3 px-2 px-md-3 px-xl-4 
                    ${dataType === 'siteViews' ? 'active' : ''}`}
                            onClick={() => changeDataType('siteViews')}>
                        <div className="title-holder d-flex align-items-center light-text-color">
                            <div className="d-flex justify-content-center align-items-center">
                                <MdRemoveRedEye size="20"/></div>
                            <div className="ps-1 fw-bold-500">Site Views</div>
                        </div>
                        <div className="total main-dark-color fw-bold-600 fs-2 my-2 ps-1 text-start">{totalsData.visits || 0}</div>
                    </button>
                </div>
                <div className="type-btn-holder col-6 col-xl-4 px-2 mb-3">
                    <button className={`type-btn w-100 rounded-2 bg-transparent border text-real-small pt-3 px-2 px-md-3 px-xl-4 
                    ${dataType === 'totalRevenue' ? 'active' : ''}`}
                            onClick={() => changeDataType('totalRevenue')}>
                        <div className="title-holder d-flex align-items-center light-text-color">
                            <div className="d-flex justify-content-center align-items-center">
                                <BiDollarCircle size="20"/></div>
                            <div className="ps-1 fw-bold-500">Total Revenue</div>
                        </div>
                        <div className="total main-dark-color fw-bold-600 fs-2 my-2 ps-1 text-start">${totalsData.revenue || 0}</div>
                    </button>
                </div>
                <div className="type-btn-holder col-6 col-xl-4 px-2 mb-3">
                    <button className={`type-btn w-100 rounded-2 bg-transparent border text-real-small pt-3 px-2 px-md-3 px-xl-4 
                    ${dataType === 'Leads' ? 'active' : ''}`}
                            onClick={() => changeDataType('Leads')}>
                        <div className="title-holder d-flex align-items-center light-text-color">
                            <div className="d-flex justify-content-center align-items-center">
                                <IoMagnet size="20"/></div>
                            <div className="ps-1 fw-bold-500">Leads</div>
                        </div>
                        <div className="total main-dark-color fw-bold-600 fs-2 my-2 ps-1 text-start">{totalsData.leads || 0}</div>
                    </button>
                </div>
            </div>
            {dataType === 'siteViews' ? (
                <StoreVisits period={period} summary={true}
                             new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
            ) : dataType === 'totalRevenue' ? (
                <TotalRevenue period={period} summary={true}
                              new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
            ) : (
                <Leads period={period} summary={true}
                       new_7_categories={new_7_categories} new_14_categories={new_14_categories}/>
            )}
        </div>
    );
}
export default SummaryAnalytics;