import {useParams} from "react-router-dom";
import React, {useEffect, useId, useState} from "react";
import checkoutCover from "../../assets/images/products/checkout_ask_me_cover.webp";
import {useQuery} from "react-query";
import {accountService, pubProductService, userService} from "../../_services";
import {ThreeDots} from "react-loader-spinner";
import {Tab, Tabs} from "react-bootstrap";
import {EditDesignSection, StoreSection} from "../private/Private";
import {BiStore} from "react-icons/bi";
import {MdDone, MdOutlineDesignServices} from "react-icons/md";
import paypalLogo from "../../assets/images/global/paypal_logo.png"
import stripeLogo from "../../assets/images/global/stripe_logo.png"
import {FloatingNewStore, PaypalPaymentButton, TranzakPaymentButton} from "./Public";
import {PhoneInput} from "react-international-phone";
import Modal from "react-bootstrap/Modal";
import tranzakLogo from "../../assets/images/global/tranzak_logo.png";
import CheckoutModal from "../../components/public/profile/CheckoutModal";

const CheckoutAff = () => {
    // let {username, pid, product} = useParams();
    let {username, pid} = useParams();
    // useEffect(() => {
    //     document.title = `@${username} ${product || ''}`;
    // }, [username, product]);
    useEffect(() => {
        const timer = setTimeout(() => {
            userService.increasePageViews({username: username})
                .then(response => {
                    // console.log('Page view tracked successfully');
                })
                .catch(error => {
                    // console.error('Error tracking page view:', error);
                });
            userService.increaseProductPageViews({username: username, product_id: pid})
                .then(response => {
                    // console.log('Page view tracked successfully');
                })
                .catch(error => {
                    // console.error('Error tracking page view:', error);
                });

        }, 1000); // Adjust the delay as needed

        return () => clearTimeout(timer); // Cleanup function to cancel the timeout if the component unmounts
    }, [username, pid]);
    useEffect(() => {
        document.title = `@${username}`;
    }, [username]);
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => pubProductService.getEbookDataById(pid));
    const productData = data || {"data": []}
    const [completedOrder, setCompletedOrder] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        name: '',
        email: ''
    });
    const handleClose = () => setModalShow(false);

    const handleShow = () => {
        setModalShow(true);
    }
    const [phoneNumber, setPhoneNumber] = useState('');
    const setOrderCompleted = () => {
        setCompletedOrder(true);
    };

    const onChange = (e) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.name]: e.target.value
        });
    }

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    let baseURL = 'https://api.sellinb.io';

    return (
        <div className="holder-of-main-page">
            <div className="checkout-main-page d-flex flex-column vh-100">
                {completedOrder ? (
                    <div className="text-center h-100 d-flex align-items-center justify-content-center">
                        <div>
                            <MdDone size="40" color="green"/>
                            <div>
                                Order Added Successfully
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="user-profile-content pb-5 bg-white">
                        <div className="cover-holder position-relative">
                            {productData.checkout_cover_image ? (
                                <img className="cover-img"
                                     src={`${baseURL}/thedss/ebooks-images/${productData.checkout_cover_image}`}
                                     alt="Ebook Cover"/>
                            ) : (
                                <img src={checkoutCover} alt="Cover Image"
                                     className="cover-img"/>
                            )}
                            <div
                                className="checkout-short-description position-absolute bottom-0 start-0 fw-bold-500 text-white w-100 p-2 text-center fs-5">
                                {productData.checkout_short_description}
                            </div>
                        </div>
                        <div className="col-lg-9 col-xl-8 col-xxl-6 mx-auto">
                            <div className="checkout-description-holder p-3">
                                <div dangerouslySetInnerHTML={{__html: productData.checkout_description}}/>
                            </div>
                            <div className="text-center main-dark-color fw-bold-600 fs-4 px-3 mb-3">
                                {productData.checkout_bottom_title}
                            </div>
                            <div className="form-holder mb-4">
                                <form>
                                    <div className="inputs-holder px-4 mb-3">
                                        <input type="text" className="form-control"
                                               placeholder="Enter your name" name="name" id="name"
                                               value={customerDetails.name} onChange={onChange}/>
                                    </div>
                                    <div className="inputs-holder px-4 mb-3">
                                        <input type="text" className="form-control"
                                               placeholder="Your email address" name="email" id="email"
                                               value={customerDetails.email} onChange={onChange}/>
                                    </div>
                                    <div className="inputs-holder px-4 mb-3">
                                        <PhoneInput
                                            className="custom-phone-input"
                                            defaultCountry="ua"
                                            value={phoneNumber}
                                            onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="price-holder d-flex align-items-center justify-content-between px-4">
                                <div className="text-black-50 fs-5">
                                    Total:
                                </div>
                                <div className="black-text-color fw-bold-600 fs-5">
                                    {productData.checkout_price > 0 ? (
                                        <>
                                            {localStorage.getItem('inbio_chosen_currency_name') && localStorage.getItem('inbio_chosen_currency') && localStorage.getItem('inbio_chosen_currency') !== '1' ? (
                                                <>
                                                    {`${productData.checkout_price / localStorage.getItem('inbio_chosen_currency')} ${localStorage.getItem('inbio_chosen_currency_name')}`}
                                                </>
                                            ) : (
                                                <>
                                                    <span>${productData.checkout_price}</span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <span>FREE</span>
                                    )}
                                </div>
                            </div>
                            {productData.checkout_price > 0 ? (
                                <div className="call-to-action-btn px-4 mb-3 mt-4 main-custom-tabs-holder">
                                    <button type="button" onClick={() => handleShow()}
                                            disabled={!customerDetails.name || !customerDetails.email || !phoneNumber}
                                            className={`main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500
                                            ${(!customerDetails.name || !customerDetails.email || !phoneNumber) ? 'disabled' : ''}`}>
                                        {productData.checkout_call_to_action_button}
                                    </button>
                                </div>
                            ) : (
                                <button type="button" onClick={() => handleShow()} disabled={true}
                                        className="main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500">
                                    {productData.checkout_call_to_action_button}
                                </button>
                            )}
                        </div>
                        <CheckoutModal
                            modalShow={modalShow}
                            handleClose={handleClose}
                            productData={productData}
                            setOrderCompleted={setOrderCompleted}
                            username={username}
                            completedOrder={completedOrder}
                            customerDetails={customerDetails}
                            pid={pid}
                            phoneNumber={phoneNumber}
                        />
                    </div>
                )}
                {/* Footer Section */}
                <div className="gray-text-color text-real-small text-center py-1 mt-auto">
                    All Right Reserved Copyright &copy; 2024 <span className="fw-bold">Sellinbio</span>
                </div>
            </div>
            <FloatingNewStore/>
        </div>
    );
};

export default CheckoutAff;