import {FloatingNewStore, UserProducts} from "../../../pages/public/Public";
import DefaultCoverImg from "../../../assets/images/global/default-cover-img.jpeg";
import {Link, useParams} from "react-router-dom";
import {BiLogoInstagramAlt, BiLogoTiktok} from "react-icons/bi";
import React, {useEffect, useId, useState} from "react";
import {useQuery} from "react-query";
import {userService} from "../../../_services";
import {ThreeDots} from "react-loader-spinner";
import ProfileHeader from "./ProfileHeader";
import UserProductsTemplateTwo from "./UserProductsTemplateTwo";

const ProfileTemplateTwo = (props) => {
    let username = props.username;
    let randomID = useId();

    const {isLoading, data} = useQuery(randomID, () => userService.getUserDataByUsername(username));
    const userData = data || {"data": []}
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            userService.increasePageViews({username: username})
                .then(response => {
                    // console.log('Page view tracked successfully');
                })
                .catch(error => {
                    // console.error('Error tracking page view:', error);
                });
        }, 1000); // Adjust the delay as needed

        return () => clearTimeout(timer); // Cleanup function to cancel the timeout if the component unmounts
    }, [username]);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    let baseURL = 'https://api.sellinb.io/';

    if (userData.username) {
        const user = userData;
        return (
            <div className="holder-of-main-page" style={{backgroundColor: "#f4f4f6"}}>
                <div className="user-profile-page d-flex flex-column">
                    <div className="user-profile-content container-lg px-0 pb-5 px-2">
                        <ProfileHeader
                            padding={true}
                            refresh={refresh}
                            sendRefreshToParent={setRefresh}
                        />
                        <div className="profile-header light-shadow py-4 bg-white" style={{marginTop: "40px"}}>
                            <div className="pic-cover-holder mb-3">
                                <div className="pic-holder text-center">
                                    <img src={`${baseURL}thedss/images/profiles/${user.profile_picture}`}
                                         alt={`${user.full_name} Profile`}
                                         className="profile-pic img-fluid rounded-circle shadow"/>
                                </div>
                            </div>
                            <div className="full-name text-center fw-bold main-dark-color mb-1 fs-6">
                                {user.full_name}
                            </div>
                            <div className="social-links">
                                <div className="d-flex align-items-center justify-content-center">
                                    {user.tiktok_username && (
                                        <div className="link-holder">
                                            <Link target="_blank" className="social-link px-1"
                                                  to={`https://tiktok.com/@${user.tiktok_username}`}>
                                                <BiLogoTiktok size="25" className="gray-text-color"/>
                                            </Link>
                                        </div>
                                    )}
                                    {user.instagram_username && (
                                        <div className="link-holder">
                                            <Link target="_blank" className="social-link px-1"
                                                  to={`https://instagram.com/${user.instagram_username}`}>
                                                <BiLogoInstagramAlt size="25" className="gray-text-color"/>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <UserProductsTemplateTwo username={username} refresh={refresh}/>
                    </div>
                    {/* Footer Section */}
                    <div className="gray-text-color text-real-small text-center py-1 mt-auto">
                        All Right Reserved Copyright &copy; 2024 <span className="fw-bold">Sellinbio</span>
                    </div>
                </div>
                <FloatingNewStore/>
            </div>
        );
    } else {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }
};

export default ProfileTemplateTwo;