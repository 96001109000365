import {BiEdit, BiTrash} from "react-icons/bi";
import {useState} from "react";
import {adminAcademyService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const AcademyRow = (props) => {

    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteAcademy = async () => {
        setIsDeleting(true);

        try {
            await adminAcademyService.deleteAcademyVideo(props.academy_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting academy video:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditAcademy = () => {
        props.handleEditAcademy(props);
        props.sendHiddenToParent(!props.hidden);
    };

    if (props.academy_id > 0) {
        return (
            <tr>
                <td>
                    <span>{props.title}</span>
                </td>
                <td>
                    {props.category}
                </td>
                <td>
                    {`${props.description.substring(0, 25)}...`}
                </td>
                <td>
                    {props.access_type === 'Free' && 'Free, Pro, Premium'}
                    {props.access_type === 'Pro' && 'Pro, Premium'}
                    {props.access_type === 'Premium' && 'Premium'}
                </td>
                <td>
                    {props.video_url}
                </td>
                <td>
                    <span className="text-black-50">{props.created_date ? getDate(props.created_date) : ''}</span>
                </td>
                <td>
                    <span className="text-center">
                        <button className="border-0 bg-transparent" onClick={handleEditAcademy}
                                disabled={!props.hidden}>
                            <BiEdit size="22" className="edit-icon"/>
                        </button>
                        {isDeleting ? (
                            <span className="text-danger">Loading...</span>
                        ) : (
                            <BiTrash onClick={handleDeleteAcademy} size="22" className="remove-icon mx-auto"/>
                        )}
                    </span>
                </td>
            </tr>
        );
    } else {
        return (
            <tr>
            <td colSpan="5" className="text-center">There is no academy video yet</td>
            </tr>
        );
    }
}

export default AcademyRow;