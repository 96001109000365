import {Footer, Header} from "./Admin";

const Dashboard = () => {
    return (
        <>
            <Header title="Admin - Dashboard"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white p-3 rounded-3 vh-100 w-100 d-flex justify-content-center align-items-center">
                    <h1>Admin Dashboard Page</h1>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Dashboard;