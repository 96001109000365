import {EbookForm, EbookRow, Footer, Header} from "./Admin";
import {useEffect, useState} from "react";
import {ebookService} from "../../_services";
import {Link} from "react-router-dom";

const PdfBooks = () => {
    const [editMode, setEditMode] = useState(false);
    const [ebookToEdit, setEbookToEdit] = useState(null);
    const [ebooksData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ebookService.getEbooks();
                setData(data);
            } catch (error) {
                console.error("Error fetching ebook data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditEbook = (ebook) => {
        setEditMode(true);
        setEbookToEdit(ebook);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Admin - PDF Books"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="ebook-products-page bg-white p-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">E-books List</div>
                        <div className="add-btn-holder mb-2">
                            <button className="btn btn-outline-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>
                                Add New Ebook
                            </button>
                        </div>
                        <div className="ms-2 mb-2">
                            <Link to="/admin/ebook-categories">
                                <button type="button" className="btn btn-outline-info py-1 btn-sm">
                                    Manage Categories
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <EbookForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            ebook={ebookToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className={!hidden ? "d-none" : "d-flex row m-0"}>
                        {ebooksData.length > 0 ? (
                                ebooksData.map((ebook) => (
                                    <EbookRow
                                        key={ebook.id}
                                        ebook_id={ebook.id}
                                        title={ebook.title}
                                        category={ebook.name}
                                        category_id={ebook.category_id}
                                        book_cover={ebook.book_cover}
                                        min_price={ebook.min_price}
                                        max_price={ebook.max_price}
                                        access_type={ebook.access_type}
                                        aff_percentage_free={ebook.aff_percentage_free}
                                        aff_percentage_pro={ebook.aff_percentage_pro}
                                        aff_percentage_premium={ebook.aff_percentage_premium}
                                        created_date={ebook.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditEbook={handleEditEbook}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            )
                            : (
                                <div className="text-center">There is no E-book yet</div>
                            )}
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default PdfBooks;