import "./public.css";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import mainLogo from "../../assets/images/global/Inbio_logo.png"
import landingImage from "../../assets/images/home/landing_image.png"
import paypalLogo from "../../assets/images/home/paypal_logo.png"
import googleLogo from "../../assets/images/home/google_logo.png"
import samsungLogo from "../../assets/images/home/samsung_logo.png"
import lenovoLogo from "../../assets/images/home/lenovo_logo.png"
import amazonLogo from "../../assets/images/home/amazon_logo.png"
import microsoftLogo from "../../assets/images/home/microsoft_logo.png"
import analyticsIcon from "../../assets/images/home/analytics_icon.png"
import reportsIcon from "../../assets/images/home/reports_icon.png"
import usersIcon from "../../assets/images/home/users_icon.png"
import abTestingIcon from "../../assets/images/home/ab_testing_icon.png"
import customizationIcon from "../../assets/images/home/customization_icon.png"
import noCodingImg from "../../assets/images/home/no_coding_required.png"
import oneTapImg from "../../assets/images/home/one_tap_checkout.png"

import {
    BiCheckCircle, BiCurrentLocation,
    BiLogoFacebookCircle,
    BiLogoInstagramAlt, BiLogoLinkedinSquare, BiPhoneCall,
    BiRightArrow,
    BiXCircle
} from "react-icons/bi";
import {Header} from "./Public";

const Home = () => {
    const words = ["Creator", "Influencer", "Entrepreneur", "Artist", "Developer", "Designer"];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.title = "Sellinbio";
    }, []);

    const [params, setParamsValues] = useState({
        plan_type: "Intro",
        plan_period: "Monthly",
        accept_prize: true,
    });

    const handleToggleButton = () => {
        setParamsValues((prevParams) => ({
            ...prevParams,
            accept_prize: !prevParams.accept_prize,
        }));
    };

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setAnimate(true);

        const intervalId = setInterval(() => {
            setAnimate(false);

            setTimeout(() => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                setAnimate(true);
            }, 500);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <div className="home-page bg-white">
            <div className="home-page-content container px-0 pt-3">
                <Header/>
                <section className="landing bg-white pt-3 pt-lg-5 mt-3" id="landing_section">
                    <div className="content-holder text-center position-relative">
                        <div className="big-title fw-bold px-1 mb-3">
                            Every<span className={animate ? 'animate px-2' : 'px-2'}>{words[currentWordIndex]},</span><br className="d-lg-none"/>needs
                            to <span>Sell in Bio</span>
                        </div>
                        <div
                            className="desc-holder col-sm-11 col-md-10 col-lg-8 col-xl-6 col-xxl-5 mx-auto small gray-text-color px-4">
                            Sellinbio helps you generate money from your online activities. Here, you can effortlessly
                            host your courses, digital products, appointment bookings, and lots more all in one link.
                        </div>
                    </div>
                    <div className="image-holder position-relative">
                        <img className="w-100" src={landingImage} alt="One Click Zero Friction With Sellinbio"/>
                    </div>
                </section>
                <section className="trusted-companies gray-bg-color position-relative pt-1">
                    <div className="companies-holder mx-auto px-2">
                        <div
                            className="row justify-content-center align-items-center mx-auto col-xxl-8 m-0 bg-white shadow-sm p-3 p-xl-4 rounded-1">
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={paypalLogo} alt="Paypal" className="company-logo img-fluid"/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={googleLogo} alt="Google" className="company-logo img-fluid"/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={samsungLogo} alt="Samsung" className="company-logo img-fluid"/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={lenovoLogo} alt="Lenovo" className="company-logo img-fluid"/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={amazonLogo} alt="Amazon" className="company-logo img-fluid"/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2 my-2">
                                <div
                                    className="company-logo-holder d-flex align-items-center justify-content-center px-2">
                                    <img src={microsoftLogo} alt="Microsoft" className="company-logo img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="best-services gray-bg-color pt-5 pb-3 pb-lg-4 pb-xl-5">
                    <div className="services-header text-center mt-3 col-md-10 col-xl-6 mx-auto px-4 px-xl-3">
                        <div className="title black-text-color fw-bold mb-3 px-xxl-5">
                            Transform Your Social Platform into a
                            Lucrative Revenue Stream
                        </div>
                        <div className="desc gray-text-color px-2 px-xl-0">
                            Monetize Your Influence, Engage Your Audience, and Maximize Your Earnings with Sellinbio
                        </div>
                    </div>
                    <div className="services-content mt-5 px-3 px-xl-5">
                        <div className="row m-0">
                            <div className="p-0 col-xl-4 mb-3 mb-xl-0 px-md-2">
                                <div className="tall-box bg-white h-100 rounded-4 shadow-sm">
                                    <div className="row m-0">
                                        <div className="p-0 col-md-6 col-xl-12">
                                            <div className="service-box px-4 px-3 pt-3 pb-md-3 pb-xl-0">
                                                <div className="icon-holder">
                                                    <img src={analyticsIcon} alt="Advanced Analytics" width="35"
                                                         className="icon-img"/>
                                                </div>
                                                <div className="title fw-bold black-text-color my-2">
                                                    Our Features
                                                </div>
                                                <div className="service-desc small mb-3 gray-text-color">
                                                    From hosting links in your bio to selling digital products and more
                                                    our comprehensive suite of features.
                                                    <p className="mt-2">
                                                        Is designed to help you effortlessly generate income.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-0 col-md-6 col-xl-12">
                                            <div className="included-box px-4 px-3 pb-3 pt-md-3 pt-xl-0">
                                                <div className="title fw-bold black-text-color my-2">
                                                    Included with...
                                                </div>
                                                <div className="included-content mb-3">
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Promote Done for You Products
                                                        </div>
                                                    </div>
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Sell Digital Products
                                                        </div>
                                                    </div>
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Host Any Link in Bio
                                                        </div>
                                                    </div>
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Collect Payments & Donations
                                                        </div>
                                                    </div>
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Schedule Meetings
                                                        </div>
                                                    </div>
                                                    <div className="included-item mb-2 d-flex align-items-center">
                                                        <div className="icon-holder d-flex align-items-center me-2">
                                                            <BiCheckCircle size="17" color="#0D70FB"/></div>
                                                        <div className="included-text gray-text-color small">
                                                            Collect Contacts & Build a List
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-0 col-xl-8">
                                <div className="four-boxes row m-0 h-100">
                                    <div className="p-0 col-md-6 mb-3 px-md-2">
                                        <div className="service-box h-100 px-4 bg-white shadow-sm p-3 rounded-4">
                                            <div className="icon-holder">
                                                <img src={reportsIcon} alt="Automated Reports" width="35"
                                                     className="icon-img"/>
                                            </div>
                                            <div className="title fw-bold black-text-color my-2">
                                                Promote Done for You Products
                                            </div>
                                            <div className="service-desc small mb-3 gray-text-color">
                                                Effortlessly promote and sell "done for you" products to your audience,
                                                earning upto 70% percentage of the revenue while we handle the product
                                                creation and fulfillment.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 col-md-6 mb-3 px-md-2">
                                        <div className="service-box h-100 px-4 bg-white shadow-sm p-3 rounded-4">
                                            <div className="icon-holder">
                                                <img src={usersIcon} alt="User Journey Flow" width="35"
                                                     className="icon-img"/>
                                            </div>
                                            <div className="title fw-bold black-text-color my-2">
                                                Sell Digital Products
                                            </div>
                                            <div className="service-desc small mb-3 gray-text-color">
                                                Promote and sell your digital products, such as ebooks, music or files
                                                directly to your audience, This allows you to focus on creating
                                                exceptional content while we handle secure product delivery and customer
                                                satisfaction.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 col-md-6 mb-3 px-md-2 mb-xl-0">
                                        <div className="service-box h-100 px-4 bg-white shadow-sm p-3 rounded-4">
                                            <div className="icon-holder">
                                                <img src={abTestingIcon} alt="Compare A/B Testing" width="35"
                                                     className="icon-img"/>
                                            </div>
                                            <div className="title fw-bold black-text-color my-2">
                                                Detailed Analytics
                                            </div>
                                            <div className="service-desc small mb-3 gray-text-color">
                                                Track your performance, monitor user engagement, and make data-driven
                                                decisions to optimize your marketing strategies and drive even greater
                                                success with our robust platform analytics and efficient reporting
                                                system.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 col-md-6 mb-3 px-md-2 mb-xl-0">
                                        <div className="service-box h-100 px-4 bg-white shadow-sm p-3 rounded-4">
                                            <div className="icon-holder">
                                                <img src={customizationIcon} alt="Easy Customization" width="35"
                                                     className="icon-img"/>
                                            </div>
                                            <div className="title fw-bold black-text-color my-2">
                                                Lots More
                                            </div>
                                            <div className="service-desc small mb-3 gray-text-color">
                                                Sellinbio unlocks endless possibilities for your online business with
                                                more features like appointment booking, payment collections, donation
                                                and many more options.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features_section" className="big-features gray-bg-color py-3 px-2 px-lg-3 px-xl-4">
                    <div className="features-holder bg-white py-4 py-md-0">
                        <div className="coding-holder row m-0 align-items-center mb-3 mb-md-4">
                            <div className="col-10 mx-auto col-md-5 p-0">
                                <div className="img-holder">
                                    <img src={noCodingImg} className="img-fluid" alt="No Coding Required"/>
                                </div>
                            </div>
                            <div className="col-md-7 px-5 mb-4">
                                <div className="txt-holder text-center text-md-start">
                                    <div className="title fw-bold mb-2">No Coding Required</div>
                                    <div className="desc gray-text-color">
                                        Sellinbio is incredibly user-friendly, making it a breeze for beginners to get
                                        started without any coding experience required. We've designed it to be so
                                        intuitive that anyone can use it, regardless of their technical background. It
                                        only takes a few minutes to setup your store and start earning online.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="one-tap-holder row m-0 align-items-center">
                            <div className="col-md-7 px-5">
                                <div className="txt-holder text-center text-md-end">
                                    <div className="title fw-bold mb-2">Easily Collect Payment</div>
                                    <div className="desc gray-text-color">
                                        Sellinbio simplifies the payment process with its one-tap checkout feature,
                                        allowing your customers to make purchases quickly and conveniently.
                                        Additionally, we support payment collection in over 15 different currencies,
                                        ensuring a seamless and global transaction experience for your business.
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 mx-auto col-md-5 p-0 order-first order-md-last mb-4">
                                <div className="img-holder">
                                    <img src={oneTapImg} className="img-fluid" alt="One Tap Checkout"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="pricing_section" className="pricing gray-bg-color py-3 px-2 px-lg-3 px-xl-4">
                    <div className="pricing-holder bg-white py-5">
                        <div className="pricing-header text-center">
                            <div className="title mb-2">Simple, transparent pricing</div>
                            <div className="desc gray-text-color">No contracts, No surprise fees.</div>
                        </div>
                        <div className="pricing-content mt-4 mt-lg-5 px-2 px-lg-3 px-xl-4 px-xxl-5">
                            <form>
                                <div className="row m-0 px-lg-3 px-xl-4 px-xxl-5">
                                    <div className="col-md-6 order-last order-md-first">
                                        <div
                                            className="period-holder gray-bg-color shadow-sm rounded-3 h-100 py-3 py-lg-4 px-4 px-lg-5">
                                            <div
                                                className="plan-period d-flex align-items-center bg-white rounded-pill">
                                                <div className="">
                                                    <input type="radio" value="Monthly"
                                                           onChange={onChange}
                                                           id="plan_period_monthly"
                                                           name="plan_period"
                                                           checked={params.plan_period === "Monthly"}/>
                                                    <label
                                                        className="c-pointer rounded-start-pill choice-content for-period"
                                                        htmlFor="plan_period_monthly">
                                                        <div className="value fw-light py-2 px-4">Monthly</div>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input type="radio" value="Yearly"
                                                           onChange={onChange}
                                                           id="plan_period_yearly"
                                                           name="plan_period"
                                                           checked={params.plan_period === "Yearly"}/>
                                                    <label
                                                        className="c-pointer rounded-end-pill choice-content for-period"
                                                        htmlFor="plan_period_yearly">
                                                        <div className="value fw-light py-2 px-4">Yearly</div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="features mt-4 mt-md-5 mt-xl-5">
                                                <div className="feature d-flex align-items-center mb-3 mb-md-5">
                                                    <div className="feature-title black-text-color">All limited links
                                                    </div>
                                                    <div className="icon-holder d-flex align-items-center ms-auto">
                                                        <BiCheckCircle size="20" color="#683BD1"
                                                                       className="feature-icon"/>
                                                    </div>
                                                </div>
                                                <div className="feature d-flex align-items-center mb-3 mb-md-5">
                                                    <div className="feature-title black-text-color">Own analytics
                                                        platform
                                                    </div>
                                                    <div className="icon-holder d-flex align-items-center ms-auto">
                                                        <BiCheckCircle size="20" color="#683BD1"
                                                                       className="feature-icon"/>
                                                    </div>
                                                </div>
                                                <div className="feature d-flex align-items-center mb-3 mb-md-5">
                                                    <div className="feature-title black-text-color">Chat support
                                                    </div>
                                                    <div className="icon-holder d-flex align-items-center ms-auto">
                                                        {params.plan_type === "Base" || params.plan_type === "Popular" || params.plan_type === "Enterprise" ? (
                                                            <BiCheckCircle size="20" color="#683BD1"
                                                                           className="feature-icon"/>
                                                        ) : (
                                                            <BiXCircle size="20" color="#74767e"
                                                                       className="feature-icon"/>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="feature d-flex align-items-center mb-3 mb-md-5">
                                                    <div className="feature-title black-text-color">Optimize hashtags
                                                    </div>
                                                    <div className="icon-holder d-flex align-items-center ms-auto">
                                                        {params.plan_type === "Popular" || params.plan_type === "Enterprise" ? (
                                                            <BiCheckCircle size="20" color="#683BD1"
                                                                           className="feature-icon"/>
                                                        ) : (
                                                            <BiXCircle size="20" color="#74767e"
                                                                       className="feature-icon"/>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="feature d-flex align-items-center">
                                                    <div className="feature-title black-text-color">Unlimited users
                                                    </div>
                                                    <div className="icon-holder d-flex align-items-center ms-auto">
                                                        {params.plan_type === "Enterprise" ? (
                                                            <BiCheckCircle size="20" color="#683BD1"
                                                                           className="feature-icon"/>
                                                        ) : (
                                                            <BiXCircle size="20" color="#74767e"
                                                                       className="feature-icon"/>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <div className="type-holder h-100">
                                            <div className="mb-3">
                                                <input type="radio" value="Intro"
                                                       onChange={onChange}
                                                       id="plan_type_intro"
                                                       name="plan_type"
                                                       checked={params.plan_type === "Intro"}/>
                                                <label
                                                    className="c-pointer rounded-3 choice-content border border-1 border-light-subtle shadow-sm"
                                                    htmlFor="plan_type_intro">
                                                    <div
                                                        className="d-flex align-items-center p-4">
                                                        <div
                                                            className="icon-holder d-flex align-items-center ps-2 pe-3">
                                                            <BiCheckCircle className="check-icon" size="20"/>
                                                        </div>
                                                        <div>
                                                            <div className="rounded-3 value mb-1">Intro</div>
                                                            <div className="save-holder rounded-pill fw-light">Save
                                                                20%
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto d-flex align-items-center">
                                                            <div className="price">$19</div>
                                                            <div className="period small fw-light">/Month</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="mb-3">
                                                <input type="radio" value="Base"
                                                       onChange={onChange}
                                                       id="plan_type_base"
                                                       name="plan_type"
                                                       checked={params.plan_type === "Base"}/>
                                                <label
                                                    className="c-pointer rounded-3 choice-content border border-1 border-light-subtle shadow-sm"
                                                    htmlFor="plan_type_base">
                                                    <div
                                                        className="d-flex align-items-center p-4">
                                                        <div
                                                            className="icon-holder d-flex align-items-center ps-2 pe-3">
                                                            <BiCheckCircle className="check-icon" size="20"/>
                                                        </div>
                                                        <div>
                                                            <div className="rounded-3 value mb-1">Base</div>
                                                            <div className="save-holder rounded-pill fw-light">Save
                                                                20%
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto d-flex align-items-center">
                                                            <div className="price">$39</div>
                                                            <div className="period small fw-light">/Month</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="mb-3">
                                                <input type="radio" value="Popular"
                                                       onChange={onChange}
                                                       id="plan_type_popular"
                                                       name="plan_type"
                                                       checked={params.plan_type === "Popular"}/>
                                                <label
                                                    className="c-pointer rounded-3 choice-content border border-1 border-light-subtle shadow-sm"
                                                    htmlFor="plan_type_popular">
                                                    <div
                                                        className="d-flex align-items-center p-4">
                                                        <div
                                                            className="icon-holder d-flex align-items-center ps-2 pe-3">
                                                            <BiCheckCircle className="check-icon" size="20"/>
                                                        </div>
                                                        <div>
                                                            <div className="rounded-3 value mb-1">Popular</div>
                                                            <div className="save-holder rounded-pill fw-light">Save
                                                                20%
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto d-flex align-items-center">
                                                            <div className="price">$99</div>
                                                            <div className="period small fw-light">/Month</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" value="Enterprise"
                                                       onChange={onChange}
                                                       id="plan_type_enterprise"
                                                       name="plan_type"
                                                       checked={params.plan_type === "Enterprise"}/>
                                                <label
                                                    className="c-pointer rounded-3 choice-content border border-1 border-light-subtle shadow-sm"
                                                    htmlFor="plan_type_enterprise">
                                                    <div
                                                        className="d-flex align-items-center p-4">
                                                        <div
                                                            className="icon-holder d-flex align-items-center ps-2 pe-3">
                                                            <BiCheckCircle className="check-icon" size="20"/>
                                                        </div>
                                                        <div>
                                                            <div className="rounded-3 value mb-1">Enterprise</div>
                                                            <div className="save-holder rounded-pill fw-light">Save
                                                                20%
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto d-flex align-items-center">
                                                            <div className="price">$119</div>
                                                            <div className="period small fw-light">/Month</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-bg-color my-4 my-lg-5 rounded-pill" style={{height: '3px'}}></div>
                                <div
                                    className="pricing-footer d-flex flex-column flex-md-row justify-content-between align-items-center px-lg-3 px-xl-4 px-xxl-5">
                                    <div className="prize-holder d-flex align-items-center px-3 px-lg-4 mb-4 mb-md-0">
                                        {params.accept_prize ? (
                                            <div className="toggle me-2 active c-pointer" onClick={handleToggleButton}>
                                                <div className="toggle-right"></div>
                                            </div>
                                        ) : (
                                            <div className="toggle me-2 c-pointer" onClick={handleToggleButton}>
                                                <div className="toggle-left"></div>
                                            </div>
                                        )}
                                        <div className="prize-text">
                                            <div className="prize-title small mb-1">Accept your prize</div>
                                            <div className="save-holder rounded-pill fw-light">Save 20%
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="pricing-btn-holder d-flex align-items-center pe-2 col-12 col-md-8 ms-auto">
                                        <button type="button"
                                                className="col-12 col-md-8 ms-auto pricing-btn text-white small py-3 px-5 rounded-4">Choose
                                            Plan
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <div className="home-page-footer gray-bg-color py-4 px-2 px-lg-3 px-xl-4">
                    <div className="row m-0">
                        <div className="col-md-6 col-xl-3 mb-3 mb-md-4">
                            <div className="footer-brand px-2 text-center text-md-start">
                                <div className="logo-holder mb-1">
                                    <img src={mainLogo} alt="Sellinbio" className="logo-img img-fluid"/>
                                </div>
                                <div className="desc gray-text-color small mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque
                                    cup
                                </div>
                                <div className="footer-btn">
                                    <Link to="#" className="footer-btn-link small py-1 px-3 rounded-2">About Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-3 mb-md-4">
                            <div className="footer-quote px-2 text-center text-md-start">
                                <div className="title mx-auto mx-md-0 black-text-color position-relative">Request a
                                    quote
                                </div>
                                <div className="desc gray-text-color small mb-3">
                                    Send your request and we will send you a quote in 24hrs. Thanks
                                </div>
                                <div className="footer-btn">
                                    <Link to="#" className="footer-btn-link small py-1 px-3 rounded-2">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-3 mb-md-4">
                            <div className="footer-links px-2 text-center text-md-start">
                                <div className="title mx-auto mx-md-0 black-text-color position-relative">Links</div>
                                <div className="row m-0">
                                    <div className="p-0 col-7 col-md-12 mb-md-2">
                                        <div className="quick-links row m-0">
                                            <div className="col-6 p-0 mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiRightArrow
                                                            size="15" color="#FFAB2F"/></div>
                                                    <Link to="#" className="quick-link small">About Us</Link>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiRightArrow
                                                            size="15" color="#FFAB2F"/></div>
                                                    <Link to="#" className="quick-link small">Enterprise</Link>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiRightArrow
                                                            size="15" color="#FFAB2F"/></div>
                                                    <Link to="#" className="quick-link small">Contact Us</Link>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiRightArrow
                                                            size="15" color="#FFAB2F"/></div>
                                                    <Link to="#" className="quick-link small">Services</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 col-5 col-md-12">
                                        <div className="black-text-color fw-bold small mb-1">Social Media</div>
                                        <div
                                            className="social-media d-flex align-items-center justify-content-center justify-content-md-start">
                                            <Link to="#" className="d-flex align-items-center px-1">
                                                <BiLogoFacebookCircle size="30" className="social-media-icon"/>
                                            </Link>
                                            <Link to="#" className="d-flex align-items-center px-1">
                                                <BiLogoInstagramAlt size="30" className="social-media-icon"/>
                                            </Link>
                                            <Link to="#" className="d-flex align-items-center px-1">
                                                <BiLogoLinkedinSquare size="30" className="social-media-icon"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-3 mb-md-4">
                            <div className="footer-info px-2 text-center text-md-start">
                                <div className="title mx-auto mx-md-0 black-text-color position-relative">Official
                                    Info
                                </div>
                                <div className="row m-0">
                                    <div className="p-0 col-7 col-md-12 mb-md-2">
                                        <div className="info-content">
                                            <div className="mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiCurrentLocation
                                                            size="15" color="#FFAB2F"/></div>
                                                    <div className="info small gray-text-color">Kusunti Road, Lapitpur
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="icon-holder d-flex align-items-center me-1">
                                                        <BiPhoneCall
                                                            size="15" color="#FFAB2F"/></div>
                                                    <div className="info small gray-text-color">123456789</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-0 col-5 col-md-12">
                                        <div className="black-text-color fw-bold small mb-1">Open Hours:</div>
                                        <div className="small gray-text-color">Sun - Fri: 10am - 5pm</div>
                                        <div className="small gray-text-color">Saturday: CLOSED</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-bg-color text-white small text-center py-1">
                    All Right Reserved Copyright &copy; 2024 <span className="fw-bold">Sellinbio</span>
                </div>
            </div>
        </div>
    );
}

export default Home;