import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ProfileStepOne from "./ProfileStepOne";
import ProfileStepTwo from "./ProfileStepTwo";
import DigitalProductsEarning from "../../../assets/images/dashboard/digital-products-earning.svg";

const ProfileSteps = (props) => {
    let navigate = useNavigate();
    const [stepNumber, setStepNumber] = useState(props.step_number);

    useEffect(() => {
        setStepNumber(props.step_number);
    }, [props.step_number]);

    const handleFinish = () => {
        setStepNumber(0)
        navigate('/user/store/store-products', {replace: true});
    };

    const getStepNumberFromChild = (stepNumber) => {
        setStepNumber(stepNumber);
    }

    return (
        <div className="main-profile-steps">
            <div className="main-dark-color fw-bold-600 mt-3 mb-4">Store Launch Checklist</div>
            <div className="steps-holder">
                <div className="header-steps-numbers gray-bg-color mx-auto d-flex align-items-center position-relative">
                    <div
                        className={`${stepNumber === 2 && 'w-50'} ${stepNumber === 3 && 'w-100'} colored-progress position-absolute h-100 main-bg-color`}></div>
                    <div className="steps-progress w-100 d-flex justify-content-between position-relative">
                        <div
                            className="active step-item step-first bg-white text-black-50 rounded-circle d-flex justify-content-center align-items-center">1
                        </div>
                        <div
                            className={`${stepNumber >= 2 && 'active'} step-item bg-white text-black-50 rounded-circle d-flex justify-content-center align-items-center`}>2
                        </div>
                        <div
                            className={`${stepNumber === 3 && 'active'} step-item step-last bg-white text-black-50 rounded-circle d-flex justify-content-center align-items-center`}>3
                        </div>
                    </div>
                </div>
                <div
                    className="header-steps-numbers mt-4 text-black-50 mb-4 mx-auto d-flex align-items-center position-relative pt-3">
                    <div className="steps-progress w-100 d-flex justify-content-between">
                        <div
                            className="active step-item step-first step-text text-center d-flex justify-content-center">Profile
                            Picture
                        </div>
                        <div
                            className={`${stepNumber >= 2 && 'active'} step-item text-center step-text d-flex justify-content-center`}>Social
                            Accounts
                        </div>
                        <div
                            className={`${stepNumber === 3 && 'active'} step-item step-last step-text text-center d-flex justify-content-center`}>Adding
                            Products
                        </div>
                    </div>
                </div>
            </div>

            {stepNumber === 1 && (
                <ProfileStepOne
                    step_number={stepNumber}
                    sendStepNumberToParent={getStepNumberFromChild}
                />
            )}
            {stepNumber === 2 && (
                <ProfileStepTwo
                    step_number={stepNumber}
                    sendStepNumberToParent={getStepNumberFromChild}
                />
            )}
            {stepNumber === 3 && (
                <div className="p-3 light-shadow rounded-3 profile-step-three mt-3">
                    <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7">
                            <div className="main-dark-color fw-bold-600 fs-4 mb-2">Create Your First Product</div>
                            <div className="gray-text-color small lh-lg pe-5">Sellinbio allows you to create, host and sell
                                any
                                product or service in your own branded Creator Store.
                            </div>
                            <div className="mb-1 mw-100">
                                <div onClick={handleFinish}
                                     className="d-flex justify-content-center align-items-center c-pointer main-button main-button-color c-pointer text-white rounded-3 mt-4 mx-auto mx-lg-0 fw-bold-500">Create
                                    Your Product
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mb-3 mb-lg-0">
                            <div className="h-100 p-3 mx-auto text-center">
                                <img src={DigitalProductsEarning} alt="Digital Products Earning"
                                     className="mx-auto digital-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default ProfileSteps;