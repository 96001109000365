import Axios from "../ServiceCaller";

let getCurrencies = async () => {
    const { data } = await Axios.get("/admin-settings/currencies");
    return data.Currencies;
};
let createCurrency = async (currencyData) => {
    const { data } = await Axios.post("/admin-settings/currencies", currencyData);
    return data;
};
let getCurrencyById = async (id) => {
    const { data } = await Axios.get(`/admin-settings/currencies/${id}`);
    return data.Currency;
};
let updateCurrency = async (currencyData) => {
    const { data } = await Axios.put("/admin-settings/currencies", currencyData);
    return data;
};
let deleteCurrency = async (currency_id) => {
    try {
        const response = await Axios.delete(`/admin-settings/currencies`, {
            data: { id: currency_id },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Currency:", error);
        throw error;
    }
};

export const settingsService = {
    getCurrencies,
    createCurrency,
    getCurrencyById,
    updateCurrency,
    deleteCurrency
};