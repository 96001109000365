import {Footer, Header, AcademyFilteredData, FreeAccessAlert} from "./Private";
import React, {useEffect, useState} from "react";
import {academyService} from "../../_services";
import {IoChevronBack} from "react-icons/io5";
import Iframe from "react-iframe";

const Academy = () => {
    const [categoriesData, setCategoriesData] = useState([]);
    const [showOne, setShowOne] = useState(false);
    const [academyVideoData, setAcademyVideoData] = useState({
        title: "",
        name: "",
        description: "",
        video_url: "",
        access_type: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await academyService.getAcademyCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    const [category, setCategory] = useState(0);
    const changeCategory = (category) => {
        setCategory(category);
    };

    let baseURL = 'https://api.sellinb.io';

    return (
        <>
            <Header title="Academy"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content academy-page d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                {showOne ? (
                    <div className="single-video-academy">
                        <button type="button" onClick={() => {
                            setShowOne(false);
                            setAcademyVideoData({
                                title: "",
                                name: "",
                                description: "",
                                video_url: "",
                                access_type: "",
                                video_cover: "",
                            });
                        }} className="border-0 bg-transparent black-text-color d-flex mb-3">
                            <div className="d-flex align-items-center justify-content-center" style={{marginTop: "1.5px"}}>
                                <IoChevronBack size="20"/>
                            </div>
                            <div className="fw-bold-500">
                                Back
                            </div>
                        </button>
                        <div className="single-academy-content bg-white rounded-3 p-3">
                            <div className="main-dark-color fw-bold text-center fs-4 mb-2">
                                {academyVideoData.title}
                            </div>
                            <div className="video-play-holder text-center">
                                <Iframe className="rounded-3 video-play" url={academyVideoData.video_url.toString()}
                                        width="640" height="360"/>
                            </div>
                            <div className="fw-bold-600 black-text-color mb-2">
                                <span className="fw-bold-500 gray-text-color">Category: </span>
                                {academyVideoData.name}
                            </div>
                            <div className="fw-bold-600 black-text-color mb-1 ">About:</div>
                            <div className="gray-text-color lh-lg">
                                {academyVideoData.description}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="category-scroll-container">
                            <div className="category-holder text-nowrap overflow-x-scroll mt-3 mb-4">
                                <div className="category-btn-holder d-inline-block px-2">
                                    <button
                                        className={`category-btn text-nowrap rounded-4 text-real-small py-1 px-4 border-0 ${category === 0 ? 'active' : ''}`}
                                        onClick={() => changeCategory(0)}>All
                                    </button>
                                </div>
                                {categoriesData.length > 0 && (
                                    categoriesData.map((item, index) => (
                                        <div className="category-btn-holder d-inline-block px-2" key={index}>
                                            <button
                                                className={`category-btn text-nowrap rounded-4 text-real-small py-1 px-4 border-0 ${category === item.id ? 'active' : ''}`}
                                                onClick={() => changeCategory(item.id)}>{item.name}
                                            </button>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        <div className="rounded-3">
                            <AcademyFilteredData
                                category_id={category}
                                showOne={showOne}
                                setShowOne={setShowOne}
                                academyVideoData={academyVideoData}
                                setAcademyVideoData={setAcademyVideoData}
                            />
                        </div>
                    </>
                )}
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Academy;