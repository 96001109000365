import {CurrenciesForm, CurrenciesRow, Footer, Header} from "./Admin";
import {useEffect, useState} from "react";
import {settingsService} from "../../_services";

const Settings = () => {
    const [currenciesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currenciesToEdit, setCurrenciesToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await settingsService.getCurrencies();
                setData(data);
            } catch (error) {
                console.error("Error fetching Currencies data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditCurrencies = (currencies) => {
        setEditMode(true);
        setCurrenciesToEdit(currencies);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return(
        <>
            <Header title="Admin - Currencies"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-settings-page bg-white p-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">Currencies List</div>
                        <div className="add-btn-holder">
                            <button className="btn btn-outline-primary py-1 btn-sm" type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>Add New Currency
                            </button>
                        </div>
                    </div>
                    <div className="main-dark-color text-center fw-bold-500">The main currency is <span className="fw-bold">USD</span></div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <CurrenciesForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            currencies={currenciesToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">Code</td>
                                <td className="text-black-50">Rate to USD</td>
                                <td className="text-black-50">Created</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {currenciesData.length > 0 ? (
                                currenciesData.map((currency) => (
                                    <CurrenciesRow
                                        key={currency.id}
                                        currency_id={currency.id}
                                        code={currency.code}
                                        rate={currency.rate}
                                        created_date={currency.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditCurrencies={handleEditCurrencies}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Settings;