import Axios from "../ServiceCaller";

let getUserDataByUsername = async (username) => {
    const {data} = await Axios.get(`/users/username/${username}`);
    return data.User;
};

let getUserEbookProducts = async (username) => {
    const {data} = await Axios.get(`/users/username/${username}/ebooks`);
    return data.UserEbooks;
};

let getUserProducts = async (username) => {
    const {data} = await Axios.get(`/users/username/${username}/products`);
    return data.UserProducts;
};

let increasePageViews = async (username) => {
    const {data} = await Axios.post("/users/increase-page-views", username);
    return data;
};

let increaseProductPageViews = async (visitData) => {
    const {data} = await Axios.post("/users/increase-product-page-views", visitData);
    return data;
};

let getTemplateSettings = async (username) => {
    const {data} = await Axios.get(`/users/template-settings/${username}`);
    return data.TemplateSettings;
};

export const userService = {
    getUserDataByUsername,
    getUserEbookProducts,
    getUserProducts,
    increasePageViews,
    increaseProductPageViews,
    getTemplateSettings,
};