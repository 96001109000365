import {FcClapperboard} from "react-icons/fc";
import Iframe from "react-iframe";
import DefaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";
import React from "react";

const AcademyRow = (props) => {
    let baseURL = 'https://api.sellinb.io';
    return (
        <div className="col-lg-6 col-xxl-4 mb-4">
            <div className="card-video-box light-shadow rounded-top-3 text-center d-flex flex-column h-100 c-pointer position-relative"
                 onClick={() => {
                     if (props.accessible) {
                         props.setShowOne(true);
                         props.setAcademyVideoData({
                             title: props.title,
                             name: props.name,
                             description: props.description,
                             video_url: props.video_url,
                         });
                     } else {
                         props.handleShow()
                     }
                 }}>
                {props.access_type === 'Pro' && (
                    <div className="badge pro">Pro</div>
                )}
                {props.access_type === 'Premium' && (
                    <div className="badge premium">Premium</div>
                )}
                {props.video_cover ? (
                    <img
                        height="180"
                        width="100%"
                        style={{objectFit: "cover"}}
                        src={`${baseURL}/thedss/academy/${props.video_cover}`}
                        alt="Video Cover"
                        className="cover-image rounded-top-3"
                    />
                ) : (
                    <img
                        height="180"
                        width="100%"
                        style={{objectFit: "cover"}}
                        src={DefaultEbookBg}
                        alt="Video Cover"
                        className="cover-image rounded-top-3"
                    />
                )}
                {/*<Iframe className="rounded-3" url={props.video_url.toString()}*/}
                {/*        width="100%" height="280px"/>*/}
                <div className="video-info p-2">
                    <div className="black-text-color fw-bold-600 mb-1">
                        {props.title}
                    </div>
                    <div className="gray-text-color small">
                        {`${props.description.substring(0, 45)}${props.description.length > 45 ? '...' : ''}`}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AcademyRow;