import Axios from "../ServiceCaller";

let getStoreTemplate = async () => {
    const { data } = await Axios.get("/user-store/template");
    return data.Template;
};

let updateStoreTemplate = async (templateData) => {
    const {data} = await Axios.post("/user-store/template", templateData);
    return data;
}

export const storeService = {
    getStoreTemplate,
    updateStoreTemplate,
};