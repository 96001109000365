import ChangeCurrency from "./ChangeCurrency";
import {useState} from "react";
import inbioLogo from "../../../assets/images/global/Inbio_logo.png";
import englishIcon from "../../../assets/images/languages/english_icon.png";
import frenchIcon from "../../../assets/images/languages/french_icon.png";
import Select from 'react-select';

const ProfileHeader = (props) => {
    const [params, setParamsValues] = useState({
        language_id: "1",
    });

    const onChange = (selectedOption) => {
        setParamsValues({
            ...params,
            language_id: selectedOption.value
        });
    };

    const options = [
        {value: '1', label: 'EN', image: englishIcon},
        {value: '2', label: 'FR', image: frenchIcon}
    ];

    const customSingleValue = ({data}) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={data.image} alt={data.label} style={{width: 20, height: 20, marginRight: 5}}/>
            {data.label}
        </div>
    );

    const customOption = (props) => {
        const {innerRef, innerProps, data} = props;
        return (
            <div ref={innerRef} {...innerProps} style={{display: 'flex', alignItems: 'center', padding: 10}}>
                <img src={data.image} alt={data.label} style={{width: 20, height: 20, marginRight: 10}}/>
                {data.label}
            </div>
        );
    };

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: 'auto',
        }),
        control: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            // border: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            borderBottom: '0',
            borderTop: '0',
            borderRight: '0',
            borderRadius: '0',
            paddingLeft: '10px',
            borderColor: "#dee2e6",
            marginLeft: 'auto',
            minHeight: '30px'
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center'
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px'
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#000', // Change this if you want a different color for the arrow
        }),
    };

    return (
        <div
            className={`main-profile-header fixed-top holder-of-main-page container ${props.padding ? 'px-2 pt-1' : 'bg-white'}`}>
            <div
                className={`row m-0 justify-content-between align-items-center ${props.padding ? 'profile-header-shadow bg-white' : ''}`}>
                <div className="col-4 px-1 px-sm-2">
                    <ChangeCurrency
                        refresh={props.refresh}
                        sendRefreshToParent={props.sendRefreshToParent}
                    />
                </div>
                <div className="col-4 px-1 px-sm-2">
                    <div className="main-currency-change-holder py-1 px-2 text-center">
                        <img src={inbioLogo} alt="Sellinbio" className="img-fluid mx-auto" style={{maxHeight: "40px"}}/>
                    </div>
                </div>
                <div className="col-4 px-1 px-sm-2">
                    <div className="main-currency-change-holder py-1 px-sm-2 small"
                         style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Select
                            value={options.find(option => option.value === params.language_id)}
                            onChange={onChange}
                            options={options}
                            isSearchable={false}
                            components={{SingleValue: customSingleValue, Option: customOption}}
                            styles={customStyles}
                            name="language_id"
                            id="language_id"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileHeader;
