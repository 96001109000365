import React, {useEffect, useState} from "react";
import {productService} from "../../../_services";
import checkoutCover from "../../../assets/images/products/checkout_digital_download_cover.webp";
import {LuGalleryThumbnails} from "react-icons/lu";
import {BiEnvelope, BiListCheck, BiLockOpen, BiUser} from "react-icons/bi";
import {Tab, Tabs} from "react-bootstrap";
import {RxButton} from "react-icons/rx";
import {BsCardText} from "react-icons/bs";
import digitalDownloadIcon from "../../../assets/images/products/digital_download_icon.png";
import ReactQuill from "react-quill";
import {IoChevronBack} from "react-icons/io5";
import {Link, useNavigate} from "react-router-dom";

const EbookForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [isSalePriceError, setIsSalePriceError] = useState(false);
    const [activeTab, setActiveTab] = useState("thumbnail");
    const [params, setParamsValues] = useState({
        thumbnail_style_option: "",
        thumbnail_icon_image: "",
        thumbnail_title: "",
        thumbnail_subtitle: "",
        thumbnail_button: "",
        checkout_cover_image: "",
        checkout_short_description: "",
        checkout_bottom_title: "",
        checkout_call_to_action_button: "",
        checkout_description: "",
        title: "",
        category: "",
        min_price: "",
        max_price: "",
        aff_percentage_free: "",
        book_cover: "",
        sale_price: "",
        accessible: false,
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.ebook.title,
                category: props.ebook.category,
                min_price: props.ebook.min_price,
                max_price: props.ebook.max_price,
                aff_percentage_free: props.ebook.aff_percentage_free,
                book_cover: props.ebook.book_cover,
                thumbnail_style_option: props.ebook.thumbnail_style_option,
                thumbnail_icon_image: props.ebook.thumbnail_icon_image,
                thumbnail_title: props.ebook.thumbnail_title,
                thumbnail_subtitle: props.ebook.thumbnail_subtitle,
                thumbnail_button: props.ebook.thumbnail_button,
                checkout_cover_image: props.ebook.checkout_cover_image,
                checkout_short_description: props.ebook.checkout_short_description,
                checkout_bottom_title: props.ebook.checkout_bottom_title,
                checkout_call_to_action_button: props.ebook.checkout_call_to_action_button,
                checkout_description: props.ebook.checkout_description,
                sale_price: "",
                accessible: props.ebook.accessible,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                thumbnail_style_option: "",
                thumbnail_icon_image: "",
                thumbnail_title: "",
                thumbnail_subtitle: "",
                thumbnail_button: "",
                checkout_cover_image: "",
                checkout_short_description: "",
                checkout_bottom_title: "",
                checkout_call_to_action_button: "",
                checkout_description: "",
                title: "",
                category: "",
                min_price: "",
                max_price: "",
                aff_percentage_free: "",
                book_cover: "",
                sale_price: "",
                accessible: false,
            });
        }
    }, [props.editMode, props.ebook]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        setIsSalePriceError(true)
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("thumbnail_style_option", params.thumbnail_style_option);
            formData.append("sale_price", params.sale_price);

            if (isNaN(params.sale_price) || params.sale_price < params.min_price || params.sale_price > params.max_price) {
                setIsSalePriceError(true)
                return;
            }
            setIsSalePriceError(false)
            if (!isCreatingMode) {
                formData.append("product_id", props.ebook.product_id);
                await productService.promoteEbook(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            props.setTypeFormEbook("");

            setParamsValues({
                thumbnail_style_option: "",
                thumbnail_icon_image: "",
                thumbnail_title: "",
                thumbnail_subtitle: "",
                thumbnail_button: "",
                checkout_cover_image: "",
                checkout_short_description: "",
                checkout_bottom_title: "",
                checkout_call_to_action_button: "",
                checkout_description: "",
                title: "",
                category: "",
                min_price: "",
                max_price: "",
                aff_percentage_free: "",
                book_cover: "",
                sale_price: "",
                accessible: false,
            });
        } catch (error) {
            console.error("Error Promoting ebook:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
        props.setTypeFormEbook("");
        setParamsValues({
            thumbnail_style_option: "",
            thumbnail_icon_image: "",
            thumbnail_title: "",
            thumbnail_subtitle: "",
            thumbnail_button: "",
            checkout_cover_image: "",
            checkout_short_description: "",
            checkout_bottom_title: "",
            checkout_call_to_action_button: "",
            checkout_description: "",
            title: "",
            category: "",
            min_price: "",
            max_price: "",
            aff_percentage_free: "",
            book_cover: "",
            sale_price: "",
            accessible: false,
        });
        setIsSalePriceError(false)
    }

    let baseURL = 'https://api.sellinb.io/'
    let navigate = useNavigate();

    function CustomTabThumbnail() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><LuGalleryThumbnails className="tab-icon"/></div>
                <div className="tab-title px-1">Thumbnail</div>
            </div>
        );
    }

    function CustomTabCheckout() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiListCheck className="tab-icon"/></div>
                <div className="tab-title px-1">Checkout Page</div>
            </div>
        );
    }

    const goToPricing = () => {
        navigate("/user/pricing", {replace: true});
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    const nextToCheckout = (e) => {
        e.preventDefault();
        setActiveTab('checkout');
    };

    return (
        <div className="row">
            <button type="button" onClick={() => {
                props.sendHiddenToParent(!props.hidden);
                props.setTypeFormEbook("");
            }} className="border-0 bg-transparent black-text-color d-flex mb-3">
                <div className="d-flex align-items-center justify-content-center" style={{marginTop: "1.5px"}}>
                    <IoChevronBack size="20"/>
                </div>
                <div className="fw-bold-500">
                    Back
                </div>
            </button>
            <div className="col-xl-8">
                <div className="promote-form-holder add-ebook-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
                    <div className="main-text-color fw-bold-600 text-center mb-3 small">* You can only change style and
                        sale price! *
                    </div>
                    <div className="form-holder main-custom-tabs-holder main-product-custom-inputs">
                        <form onSubmit={onSubmit} encType="multipart/form-data">
                            <Tabs
                                variant="pills"
                                activeKey={activeTab}
                                onSelect={(tabKey) => handleTabChange(tabKey)}
                                className="mb-3"
                            >
                                <Tab eventKey="thumbnail" title={<CustomTabThumbnail/>}>
                                    <div className="thumbnail-section">
                                        <div className="style-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                            <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                                <div className="step-number-holder d-flex align-items-center me-4">
                                                    <div
                                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                        1
                                                    </div>
                                                    <div className="step-title main-dark-color fw-bold small">
                                                        Pick a style
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 col-xl-8">
                                                <div className="step-options d-flex">
                                                    <div className="">
                                                        <input type="radio" value="button"
                                                               onChange={onChange}
                                                               id="thumbnail_style_option_button"
                                                               name="thumbnail_style_option"
                                                               checked={params.thumbnail_style_option === "button"}/>
                                                        <label
                                                            className="c-pointer rounded-3 choice-content"
                                                            htmlFor="thumbnail_style_option_button">
                                                            <div
                                                                className="text-center p-3 rounded-2 text-real-small me-2 value">
                                                                <div><RxButton size="20"/></div>
                                                                <div className="">Button</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input type="radio" value="callout"
                                                               onChange={onChange}
                                                               id="thumbnail_style_option_callout"
                                                               name="thumbnail_style_option"
                                                               checked={params.thumbnail_style_option === "callout"}/>
                                                        <label
                                                            className="c-pointer rounded-3 choice-content"
                                                            htmlFor="thumbnail_style_option_callout">
                                                            <div
                                                                className="text-center p-3 rounded-2 text-real-small ms-2 value">
                                                                <div><BsCardText size="20"/></div>
                                                                <div className="">Callout</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="image-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                            <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                                <div className="step-number-holder d-flex align-items-center me-4">
                                                    <div
                                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                        2
                                                    </div>
                                                    <div className="step-title main-dark-color fw-bold small">
                                                        Select image
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 col-xl-6">
                                                <div
                                                    className="img-input-holder border border-light-subtle rounded-2 p-3 d-flex align-items-center justify-content-between">
                                                    <div className="img-holder position-relative p-2 rounded-2">
                                                        {params.thumbnail_icon_image ? (
                                                            <img
                                                                src={`${baseURL}thedss/ebooks-images/${params.thumbnail_icon_image}`}
                                                                alt="Digital Download"/>
                                                        ) : (
                                                            <img src={digitalDownloadIcon}
                                                                 alt="Digital Download"/>
                                                        )}
                                                    </div>
                                                    <div className="input-holder text-center d-flex flex-column">
                                                        <div
                                                            className="title text-real-small light-text-color">Thumbnail
                                                        </div>
                                                        <div className="size text-black-50">400x400</div>
                                                        <label htmlFor="thumbnail_icon_image"
                                                               className="icon-image mt-auto">
                                                            <div
                                                                className="text-real-small btn-text fw-bold-500 rounded-2 c-pointer">
                                                                Choose image
                                                            </div>
                                                        </label>
                                                        {/*<input*/}
                                                        {/*    type="file"*/}
                                                        {/*    className="form-control py-2 d-none"*/}
                                                        {/*    value=""*/}
                                                        {/*    disabled*/}
                                                        {/*    name="thumbnail_icon_image"*/}
                                                        {/*    id="thumbnail_icon_image"*/}
                                                        {/*    accept="image/*"*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-section mt-3 mt-sm-4 mt-lg-5">
                                            <div className="step-number-holder d-flex align-items-center mb-3">
                                                <div
                                                    className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                    3
                                                </div>
                                                <div className="step-title main-dark-color fw-bold small">
                                                    Add text
                                                </div>
                                            </div>
                                            <div className="inputs-holder col-xl-10 ps-xl-4">
                                                {params.thumbnail_style_option !== "button" && (
                                                    <div className="optional-inputs">
                                                        <div className="form-group mb-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between">
                                                                <label htmlFor="thumbnail_title"
                                                                       className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                                    Title
                                                                </label>
                                                            </div>
                                                            <div className="">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Title heading here"
                                                                       name="thumbnail_title"
                                                                       id="thumbnail_title"
                                                                       value={params.thumbnail_title}
                                                                       readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between">
                                                                <label htmlFor="thumbnail_subtitle"
                                                                       className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                                    Subtitle
                                                                </label>
                                                            </div>
                                                            <div className="">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Enter a description here"
                                                                       name="thumbnail_subtitle"
                                                                       id="thumbnail_subtitle"
                                                                       readOnly
                                                                       value={params.thumbnail_subtitle}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-group mb-3">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label htmlFor="thumbnail_button"
                                                               className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                            Button*
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input type="text" className="form-control"
                                                               placeholder="Button text here"
                                                               name="thumbnail_button"
                                                               id="thumbnail_button"
                                                               readOnly
                                                               value={params.thumbnail_button}
                                                               required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="checkout" title={<CustomTabCheckout/>}>
                                    <div className="checkout-section">
                                        <div className="image-section row align-items-center mt-3 mt-sm-4 mt-lg-5">
                                            <div className="col-sm-5 col-xl-4 mb-3 mb-sm-0">
                                                <div className="step-number-holder d-flex align-items-center me-4">
                                                    <div
                                                        className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                        1
                                                    </div>
                                                    <div className="step-title main-dark-color fw-bold small">
                                                        Select image
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 col-xl-6">
                                                <div
                                                    className="img-input-holder border border-light-subtle rounded-2 p-3 d-flex align-items-center justify-content-between">
                                                    <div className="img-holder position-relative p-2 rounded-2">
                                                        {params.checkout_cover_image ? (
                                                            <img
                                                                src={`${baseURL}thedss/ebooks-images/${params.checkout_cover_image}`}
                                                                alt="digital downloads"/>
                                                        ) : (
                                                            <img src={checkoutCover}
                                                                 alt="digital downloads"/>
                                                        )}
                                                    </div>
                                                    <div className="input-holder text-center d-flex flex-column">
                                                        <div className="title text-real-small light-text-color">Cover
                                                        </div>
                                                        <div className="size text-black-50">1920x1080</div>
                                                        <label htmlFor="checkout_cover_image"
                                                               className="icon-image mt-auto">
                                                            <div
                                                                className="text-real-small btn-text fw-bold-500 rounded-2 c-pointer">
                                                                Choose image
                                                            </div>
                                                        </label>
                                                        {/*<input*/}
                                                        {/*    type="file"*/}
                                                        {/*    className="form-control py-2 d-none"*/}
                                                        {/*    readOnly*/}
                                                        {/*    disabled*/}
                                                        {/*    value=""*/}
                                                        {/*    name="checkout_cover_image"*/}
                                                        {/*    id="checkout_cover_image"*/}
                                                        {/*    accept="image/*"*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-section mt-3 mt-sm-4 mt-lg-5">
                                            <div className="step-number-holder d-flex align-items-center mb-3">
                                                <div
                                                    className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                    2
                                                </div>
                                                <div className="step-title main-dark-color fw-bold small">
                                                    Write Description
                                                </div>
                                            </div>
                                            <div className="short-description-holder col-xl-10 ps-xl-4">
                                                <div className="form-group mb-3">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label htmlFor="checkout_short_description"
                                                               className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                            Short description*
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input type="text" className="form-control"
                                                               placeholder="Title Here"
                                                               name="checkout_short_description"
                                                               id="checkout_short_description"
                                                               value={params.checkout_short_description}
                                                               readOnly
                                                               required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div
                                                        className="description-holder d-flex align-items-center">
                                                        <div className="description-input flex-grow-1">
                                                            <ReactQuill
                                                                value={params.checkout_description}
                                                                readOnly
                                                                modules={{
                                                                    toolbar: [
                                                                        ["bold", "italic"],
                                                                        [{header: 1}, {header: 2}],
                                                                        [{list: "ordered"}, {list: "bullet"}],
                                                                        ["link", "image", "video"],
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label htmlFor="checkout_bottom_title"
                                                               className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                            Bottom Title*
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input type="text" className="form-control"
                                                               placeholder="Your bottom title"
                                                               name="checkout_bottom_title"
                                                               readOnly
                                                               id="checkout_bottom_title"
                                                               value={params.checkout_bottom_title}
                                                               required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label htmlFor="checkout_call_to_action_button"
                                                               className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                            Call-to-Action Button*
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input type="text" className="form-control"
                                                               placeholder="Button text"
                                                               name="checkout_call_to_action_button"
                                                               id="checkout_call_to_action_button"
                                                               value={params.checkout_call_to_action_button}
                                                               readOnly
                                                               required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="price-section mt-3 mt-sm-4 mt-lg-5 position-relative">
                                            {!params.accessible && (
                                                <div className="custom-overlay-access-dss">
                                                    <div>
                                                        <div className="text-center fw-bold-600 main-dark-color mb-2">
                                                            Want to add your price to promote this ebook?
                                                        </div>
                                                        <Link to="/user/pricing" className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                                            <div className="d-flex align-items-center justify-content-center px-1">
                                                                <BiLockOpen size="18"/>
                                                            </div>
                                                            <div className="px-1 small fw-bold-500">Upgrade Now</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="step-number-holder d-flex align-items-center mb-3">
                                                <div
                                                    className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                    3
                                                </div>
                                                <div className="step-title main-dark-color fw-bold small">
                                                    Set Price
                                                </div>
                                            </div>
                                            <div className="price-holder col-xl-10 ps-xl-4">
                                                <div className="form-group mb-3">
                                                    {isSalePriceError && (
                                                        <div className="alert alert-danger py-1 small text-center">Sale
                                                            Price Must be a
                                                            number
                                                            between ${params.min_price} and ${params.max_price}</div>
                                                    )}
                                                    <label htmlFor="sale_price"
                                                           className="black-text-color mb-1 small fw-bold-500 ps-1">
                                                        Sale Price (${params.min_price}~${params.max_price}):
                                                    </label>
                                                    <div className="">
                                                        <input type="text" className="form-control"
                                                               placeholder="Your product price"
                                                               name="sale_price"
                                                               id="sale_price"
                                                               value={params.sale_price}
                                                               onChange={onChange}
                                                               required
                                                               disabled={!params.accessible}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="infos-section mt-3 mt-sm-4 mt-lg-5">
                                            <div className="step-number-holder d-flex align-items-center mb-3">
                                                <div
                                                    className="step-number gray-bg-color py-2 px-3 small fw-bold-600 rounded-circle me-2">
                                                    4
                                                </div>
                                                <div className="step-title main-dark-color fw-bold small">
                                                    Collect Info
                                                </div>
                                            </div>
                                            <div className="collect-info-holder col-xl-10 ps-xl-4">
                                                <div className="form-group mb-3">
                                                    <div className="black-text-color mb-1 small fw-bold-500">
                                                        Fields
                                                    </div>
                                                    <div className="position-relative mb-3">
                                                        <div
                                                            className="position-absolute top-50 translate-middle-y ps-2">
                                                            <BiUser size="25"
                                                                    className="gray-text-color social-logo-icon pe-1 border-end"/>
                                                        </div>
                                                        <input type="text" className="ps-5 form-control"
                                                               placeholder="Name"
                                                               value="Name"
                                                               disabled
                                                               readOnly
                                                        />
                                                    </div>
                                                    <div className="position-relative">
                                                        <div
                                                            className="position-absolute top-50 translate-middle-y ps-2">
                                                            <BiEnvelope size="25"
                                                                        className="gray-text-color social-logo-icon pe-1 border-end"/>
                                                        </div>
                                                        <input type="text" className="ps-5 form-control"
                                                               placeholder="Email"
                                                               value="Email"
                                                               disabled
                                                               readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                            {isCreating ? (
                                <span className="text-success">Loading...</span>
                            ) : (
                                <>
                                    {activeTab === 'thumbnail' ? (
                                        <div className="publish-button-holder mt-4 text-end col-xl-10 ps-xl-4">
                                            <button type="button"
                                                    className="btn btn-outline-secondary me-3 py-2 border-0"
                                                    onClick={setFormHidden}>
                                                Cancel
                                            </button>
                                            <button type="button"
                                                    className="main-button-color border-0 rounded-3 py-2 p-5 text-white"
                                                    onClick={nextToCheckout}>
                                                Next
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="publish-button-holder mt-4 text-end col-xl-10 ps-xl-4">
                                            <button type="button"
                                                    className="btn btn-outline-secondary me-3 py-2 border-0"
                                                    onClick={setFormHidden}>
                                                Cancel
                                            </button>
                                            {params.accessible && props.userProductsLength < 3 ? (
                                                <button type="submit"
                                                        className="main-button-color py-2 px-5 border-0 rounded-2 text-white">
                                                    Promote
                                                </button>
                                            ) : (
                                                <button type="button" onClick={goToPricing}
                                                        className="main-button-color py-2 small px-4 border-0 rounded-2 text-white">
                                                    Upgrade To Promote
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            {activeTab === 'thumbnail' && (
                <div className="col-xl-4 mb-3 mb-xl-0 main-virtual-tel-holder">
                    <div
                        className="border virtual-tel border-3 border-bottom-0 rounded-top-5 h-100 p-3 mx-auto d-flex flex-column align-items-center justify-content-center">
                        <div className="main-virtual-product-preview light-shadow p-2 w-100 rounded-2">
                            {params.thumbnail_style_option === "button" ? (
                                <div className="d-flex align-items-center">
                                    <div className="img-holder p-2 rounded-2 me-2">
                                        {params.thumbnail_icon_image ? (
                                            <img
                                                src={`${baseURL}thedss/ebooks-images/${params.thumbnail_icon_image}`}
                                                alt="Digital Download"/>
                                        ) : (
                                            <img src={digitalDownloadIcon}
                                                 alt="Digital Download"/>
                                        )}
                                    </div>
                                    <div className="button-text small fw-bold-600">
                                        {params.thumbnail_button}
                                    </div>
                                </div>
                            ) : (
                                <div className="callout-preview">
                                    <div className="d-flex align-items-center">
                                        <div className="img-holder p-2 rounded-2 me-2">
                                            {params.thumbnail_icon_image ? (
                                                <img
                                                    src={`${baseURL}thedss/ebooks-images/${params.thumbnail_icon_image}`}
                                                    alt="Digital Download"/>
                                            ) : (
                                                <img src={digitalDownloadIcon}
                                                     alt="Digital Download"/>
                                            )}
                                        </div>
                                        <div>
                                            <div className="title-text small fw-bold-600">
                                                {params.thumbnail_title}
                                            </div>
                                            <div className="thumbnail_subtitle-text text-black-50 small">
                                                {params.thumbnail_subtitle}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="main-button-color text-white rounded-3 text-center py-2 mt-2 small fw-bold-500">
                                        {params.thumbnail_button}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {activeTab === 'checkout' && (
                <div className="col-xl-4 mb-3 mb-xl-0 main-virtual-tel-holder-tall">
                    <div
                        className="border border-3 border-bottom-0 virtual-tel rounded-top-5 h-100 mx-auto overflow-y-scroll">
                        <div className="main-virtual-product-preview w-100 rounded-top-5">
                            <div className="cover-holder position-relative">
                                {params.checkout_cover_image ? (
                                    <img src={`${baseURL}thedss/ebooks-images/${params.checkout_cover_image}`}
                                         alt="digital downloads"
                                         className="rounded-top-5 cover-img"/>
                                ) : (
                                    <img src={checkoutCover} alt="digital downloads"
                                         className="rounded-top-5 cover-img"/>
                                )}
                                <div
                                    className="checkout-short-description position-absolute bottom-0 start-0 fw-bold-500 text-white w-100 p-2">
                                    {params.checkout_short_description}
                                </div>
                            </div>
                            <div className="checkout-description-holder p-3 small">
                                <div dangerouslySetInnerHTML={{__html: params.checkout_description}}></div>
                            </div>
                            <div className="text-center main-dark-color fw-bold-600 fs-5 px-3 mb-3">
                                {params.checkout_bottom_title}
                            </div>
                            <div className="inputs-holder px-4 mb-3">
                                <input type="text" placeholder="Enter your name" className="form-control"
                                       readOnly disabled/>
                            </div>
                            <div className="inputs-holder px-4 mb-3">
                                <input type="text" placeholder="Enter your email" className="form-control"
                                       readOnly disabled/>
                            </div>
                            <div className="inputs-holder px-4 mb-3">
                                <input type="text" placeholder="Enter your phone" className="form-control"
                                       readOnly disabled/>
                            </div>
                            <div
                                className="price-holder d-flex align-items-center justify-content-between px-4">
                                <div className="text-black-50 fs-5">
                                    Total:
                                </div>
                                <div className="black-text-color fw-bold-600 fs-5">
                                    ${params.sale_price}
                                </div>
                            </div>
                            <div className="call-to-action-btn px-4 mb-3 mt-4">
                                <div
                                    className="main-button-color text-white rounded-3 text-center py-2 mt-2 small fw-bold-500">
                                    {params.checkout_call_to_action_button}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EbookForm;