import Axios from "../ServiceCaller";

// E-books
let getEbooks = async (category_id) => {
    const {data} = await Axios.get(`/products/ebooks/${category_id}`);
    return data.Ebooks;
};
let promoteEbook = async (ebookData) => {
    const {data} = await Axios.post("/products/ebooks/promote", ebookData);
    return data;
};
let getEbookCategories = async () => {
    const {data} = await Axios.get("/products/ebooks/categories");
    return data.EbookCategories;
};

// User Products
let getUserProducts = async () => {
    const {data} = await Axios.get("/products/user-products");
    return data.UserProducts;
};
let deleteUserProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/user-products", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}
let deleteUserAffProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/user-aff-products", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}

// Digital Download Products
let getDigitalDownloadProducts = async () => {
    const {data} = await Axios.get("/products/digital-download");
    return data.DigitalDownloadProducts;
}
let createDigitalDownloadProduct = async (productData) => {
    const {data} = await Axios.post("/products/digital-download", productData);
    return data;
}
let updateDigitalDownloadProduct = async (productData) => {
    const {data} = await Axios.put("/products/digital-download", productData);
    return data;
}
let deleteDigitalDownloadProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/digital-download", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}
let getDigitalDownloadProductById = async (productId) => {
    const {data} = await Axios.get(`/products/digital-download/${productId}`);
    return data.DigitalDownloadProduct;
}

// Book Appointment Products
let getBookAppointmentProducts = async () => {
    const {data} = await Axios.get("/products/book-appointment");
    return data.BookAppointmentProducts;
}
let createBookAppointmentProduct = async (productData) => {
    const {data} = await Axios.post("/products/book-appointment", productData);
    return data;
}
let updateBookAppointmentProduct = async (productData) => {
    const {data} = await Axios.put("/products/book-appointment", productData);
    return data;
}
let deleteBookAppointmentProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/book-appointment", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}
let getBookAppointmentProductById = async (productId) => {
    const {data} = await Axios.get(`/products/book-appointment/${productId}`);
    return data.BookAppointmentProduct;
}

// Ask Me Anything Products
let getAskMeAnyThingProducts = async () => {
    const {data} = await Axios.get("/products/ask-me-anything");
    return data.AskMeAnyThingProducts;
}
let createAskMeAnyThingProduct = async (productData) => {
    const {data} = await Axios.post("/products/ask-me-anything", productData);
    return data;
}
let updateAskMeAnyThingProduct = async (productData) => {
    const {data} = await Axios.put("/products/ask-me-anything", productData);
    return data;
}
let deleteAskMeAnyThingProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/ask-me-anything", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}
let getAskMeAnyThingProductById = async (productId) => {
    const {data} = await Axios.get(`/products/ask-me-anything/${productId}`);
    return data.AskMeAnyThingProduct;
}

// Ask Me Anything Products
let getExternalLinkProducts = async () => {
    const {data} = await Axios.get("/products/external-link");
    return data.ExternalLinkProducts;
}
let createExternalLinkProduct = async (productData) => {
    const {data} = await Axios.post("/products/external-link", productData);
    return data;
}
let updateExternalLinkProduct = async (productData) => {
    const {data} = await Axios.put("/products/external-link", productData);
    return data;
}
let deleteExternalLinkProduct = async (productId) => {
    try {
        const response = await Axios.delete("/products/external-link", {
            data: {id: productId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting product:", error);
        throw error;
    }
}
let getExternalLinkProductById = async (productId) => {
    const {data} = await Axios.get(`/products/external-link/${productId}`);
    return data.ExternalLinkProduct;
}

export const productService = {
    // E-books
    getEbooks,
    promoteEbook,
    getEbookCategories,
    // User Products
    getUserProducts,
    deleteUserProduct,
    deleteUserAffProduct,
    // Digital Download Products
    getDigitalDownloadProducts,
    createDigitalDownloadProduct,
    updateDigitalDownloadProduct,
    deleteDigitalDownloadProduct,
    getDigitalDownloadProductById,
    // Book Appointment Products
    getBookAppointmentProducts,
    createBookAppointmentProduct,
    updateBookAppointmentProduct,
    deleteBookAppointmentProduct,
    getBookAppointmentProductById,
    // Ask Me Anything Products
    getAskMeAnyThingProducts,
    createAskMeAnyThingProduct,
    updateAskMeAnyThingProduct,
    deleteAskMeAnyThingProduct,
    getAskMeAnyThingProductById,
    // External Link Products
    getExternalLinkProducts,
    createExternalLinkProduct,
    updateExternalLinkProduct,
    deleteExternalLinkProduct,
    getExternalLinkProductById,
};