import defaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";

const EbookRow = (props) => {
    const handleEditEbook = () => {
        props.handleEditEbook(props);
        props.handleEditEbook(props);
    };
    const handleModalEbook = () => {
        props.handleShow(true);
        props.handleModalEbook(props);
    };
    let baseURL = 'https://api.sellinb.io/';

    return (
        <div className="rounded-3 ebook-box c-pointer pb-2"
             onClick={() => {
                 handleEditEbook()
                 handleModalEbook()
             }}>
            <div className="ebook-image mb-2 position-relative">
                {props.access_type === 'Pro' && (
                    <div className="badge pro">Pro</div>
                )}
                {props.access_type === 'Premium' && (
                    <div className="badge premium">Premium</div>
                )}
                {props.promoted && (
                    <div
                        className="position-absolute end-0 top-0 px-2 py-1 text-real-small main-bg-color main-text-light-color"
                        style={{borderRadius: '0 0.5rem 0 0'}}
                    >Promoted</div>
                )}
                {props.book_cover ? (
                    <img className="ebook-bg rounded-3" alt={props.title}
                         style={{
                             width: "100%",
                             height: "240px",
                             objectFit: "contain",
                             maxHeight: "275px",
                             maxWidth: "100%",
                             border: "0.01px solid #ebebeb"
                         }}
                         src={`${baseURL}thedss/images/ebooks/${props.book_cover}`}
                    />
                ) : (
                    <img className="ebook-bg rounded-3" alt={props.title}
                         style={{
                             width: "100%",
                             height: "240px",
                             objectFit: "contain",
                             maxHeight: "275px",
                             maxWidth: "100%",
                             border: "0.01px solid #ebebeb"
                         }}
                         src={defaultEbookBg}
                    />
                )}
            </div>
            <div className="ebook-info text-real-small px-1 px-sm-2">
                <div className="small-margin-bottom">
                    <span className="ebook-title fw-bold-600 black-text-color text-nowrap">
                        {`${props.title.substring(0, 20)}${props.title.length > 20 ? '..' : ''}`}
                    </span>
                </div>
                <div className="small-margin-bottom">
                    <span className="ebook-title text-black-50 text-nowrap">
                        {`${props.category.substring(0, 20)}${props.category.length > 20 ? '..' : ''}`}
                    </span>
                </div>
                <div className="black-text-color small-margin-bottom">
                    ${props.min_price}<span className="gray-text-color text-nowrap"> ~ </span>${props.max_price}
                </div>
                <div>
                    <span className="text-black-50">Commission: </span>
                    <span className="ebook-title fw-bold-500 black-text-color text-nowrap">
                        {props.access_type === 'Premium' && props.aff_percentage_premium + '%'}
                        {props.access_type === 'Pro' && (
                            props.user_access === 'premium_access' ? props.aff_percentage_premium + '%' : (
                                props.user_access === 'pro_access' ? props.aff_percentage_pro + '%' :
                                    props.aff_percentage_pro + ' - ' + props.aff_percentage_premium + '%'
                            )
                        )}
                        {props.access_type === 'Free' && (
                            props.user_access === 'premium_access' ? props.aff_percentage_premium + '%' : (
                                props.user_access === 'pro_access' ? props.aff_percentage_pro + '%' : (
                                    props.user_access === 'free_access' ? props.aff_percentage_free + '%' :
                                        props.aff_percentage_free + ' - ' + props.aff_percentage_premium + '%'
                                )
                            )
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default EbookRow;