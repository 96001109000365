import ChooseTemplate from "./ChooseTemplate";

const EditDesignSection = () => {

    return (
        <div className="edit-design-section bg-white p-3 rounded-3">
            <div className="template-choose-section">
                <ChooseTemplate/>
            </div>
        </div>
    );
};

export default EditDesignSection;