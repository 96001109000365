import 'bootstrap/dist/css/bootstrap.min.css';
import "react-quill/dist/quill.snow.css";
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import PrivateRouter from "./pages/private/PrivateRouter";
import AuthRouter from "./pages/auth/AuthRouter";
import AuthGuard from "./_helpers/AuthGuard";
import IsLogged from "./_helpers/IsLogged";
import AdminRouter from "./pages/admin/AdminRouter";
import AdminGuard from "./_helpers/AdminGuard";
import {Checkout, Home, UserProfile, CheckoutAff, ThankYou} from "./pages/public/Public";
import Error from "./_utils/Error";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {/* Public Pages */}
                    <Route index element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/thank-you" element={<ThankYou/>}/>

                    {/* Authentication Pages */}
                    <Route path="/auth/*" element={
                        <IsLogged>
                            <AuthRouter/>
                        </IsLogged>
                    }/>

                    {/* Private Pages */}
                    <Route path="/user/*" element={
                        <AuthGuard>
                            <PrivateRouter/>
                        </AuthGuard>
                    }/>

                    {/* Admin Pages */}
                    <Route path="/admin/*" element={
                        <AuthGuard>
                            <AdminGuard>
                                <AdminRouter/>
                            </AdminGuard>
                        </AuthGuard>
                    }/>

                    {/* User Profile Page */}
                    <Route path="/:username/ebook/:pid" element={<CheckoutAff />} />
                    <Route path="/:username" element={<UserProfile />} />
                    {/*<Route path="/:username/:pid/:product?" element={<Checkout />} />*/}
                    <Route path="/:username/:pid" element={<Checkout />} />

                    {/* Catch-All 404 Page */}
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;