import externalLinkIcon from "../../../assets/images/products/external_link_icon.png"
import askMeAnyThingIcon from "../../../assets/images/products/ask_me_anything_icon.png"
import bookAppointmentIcon from "../../../assets/images/products/book_appointment_icon.png"
import digitalDownloadIcon from "../../../assets/images/products/digital_download_icon.png"
import {BiEditAlt, BiTrash} from "react-icons/bi";
import {Link} from "react-router-dom";
import {productService} from "../../../_services";
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";

const StoreUserProducts = ({userProducts, setUserProducts, preview}) => {
    let baseURL= 'https://api.sellinb.io';

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [productTypeToDelete, setProductTypeToDelete] = useState(null);

    const handleDeleteUserProduct = async (productId, productType) => {
        try {
            if (productType === 'admin_ebook') {
                await productService.deleteUserAffProduct(productId);
                setUserProducts(userProducts.filter(product => product.product_id !== productId));
            } else {
                await productService.deleteUserProduct(productId);
                setUserProducts(userProducts.filter(product => product.id !== productId));
            }
        } catch (error) {
            console.error("Error deleting user product:", error);
        } finally {
            setShowConfirmationModal(false);
        }
    };

    const openDeleteConfirmationModal = (productId, productType) => {
        setProductToDelete(productId);
        setProductTypeToDelete(productType)
        setShowConfirmationModal(true);
    };

    const closeDeleteConfirmationModal = () => {
        setProductToDelete(null);
        setShowConfirmationModal(false);
    };

    return (
        <div className="store-user-products-holder mt-4">
            {userProducts.length > 0 ? (
                userProducts.map((product, i) => (
                    <div className="light-shadow bg-light-subtle p-3 rounded-2 mb-4" key={i}>
                        <div>{product.createdDate}</div>
                        <div className="d-flex align-items-center">
                            <div
                                className={`prod-icon-holder me-3 d-flex justify-content-center align-items-center bg-white rounded-2 ${preview && 'smaller'}`}>
                                {product.thumbnail_icon_image ? (
                                    <>
                                        {product.product_type === 'external_link' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                 alt="External Link Icon"/>
                                        )}
                                        {product.product_type === 'ask_me_anything' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                 alt="Ask Me Any Thing Icon"/>
                                        )}
                                        {product.product_type === 'book_appointment' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                 alt="Book Appointment Icon"/>
                                        )}
                                        {product.product_type === 'digital_download' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                 alt="Digital Download Icon"/>
                                        )}
                                        {product.product_type === 'admin_ebook' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                 alt="Digital Download Icon"/>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {product.product_type === 'external_link' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={externalLinkIcon}
                                                 alt="External Link Icon"/>
                                        )}
                                        {product.product_type === 'ask_me_anything' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={askMeAnyThingIcon}
                                                 alt="Ask Me Any Thing Icon"/>
                                        )}
                                        {product.product_type === 'book_appointment' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={bookAppointmentIcon}
                                                 alt="Book Appointment Icon"/>
                                        )}
                                        {product.product_type === 'digital_download' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={digitalDownloadIcon}
                                                 alt="Digital Download Icon"/>
                                        )}
                                        {product.product_type === 'admin_ebook' && (
                                            <img className="rounded-2 w-100 h-100"
                                                 src={digitalDownloadIcon}
                                                 alt="Digital Download Icon"/>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={`content-holder fw-bold-500 black-text-color ${preview && 'small'}`}>
                                {product.thumbnail_style_option === 'callout' ? (
                                    product.thumbnail_title || product.thumbnail_button
                                ) : (
                                    product.thumbnail_button
                                )}
                            </div>
                            <div className={`edit-link-btn ms-auto me-2 ${preview && 'd-none'}`}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="px-1">
                                        <BiTrash onClick={() => openDeleteConfirmationModal(product.product_type === 'admin_ebook' ? product.product_id : product.id, product.product_type)} size="25" className="remove-link-btn c-pointer"/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center px-1">
                                        {product.product_type === 'external_link' && (
                                            <Link to={`/user/store/external-links/${product.id}`}>
                                                <BiEditAlt size="25" className="edit-link-btn c-pointer"/>
                                            </Link>
                                        )}
                                        {product.product_type === 'ask_me_anything' && (
                                            <Link to={`/user/store/ask-me-anything/${product.id}`}>
                                                <BiEditAlt size="25" className="edit-link-btn c-pointer"/>
                                            </Link>
                                        )}
                                        {product.product_type === 'book_appointment' && (
                                            <Link to={`/user/store/book-appointment/${product.id}`}>
                                                <BiEditAlt size="25" className="edit-link-btn c-pointer"/>
                                            </Link>
                                        )}
                                        {product.product_type === 'digital_download' && (
                                            <Link to={`/user/store/digital-downloads/${product.id}`}>
                                                <BiEditAlt size="25" className="edit-link-btn c-pointer"/>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <></>
            )}
            {/* Confirmation Modal */}
            <Modal show={showConfirmationModal} onHide={closeDeleteConfirmationModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Action cannot be reversed</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closeDeleteConfirmationModal}>Cancel</button>
                    <button className="btn btn-danger" onClick={() => handleDeleteUserProduct(productToDelete, productTypeToDelete)}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default StoreUserProducts;