import {BiLockOpen, BiTime} from "react-icons/bi";
import {Swiper, SwiperSlide} from "swiper/react";
import {FcClapperboard} from "react-icons/fc";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import React, {useEffect, useState} from "react";
import {academyService} from "../../../_services";
import DefaultEbookBg from "../../../assets/images/global/default_ebook_bg.png";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";

const ForYou = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [academyData, setAcademyData] = useState([]);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await academyService.getAcademyVideos(0);
                setAcademyData(data);
            } catch (error) {
                console.error("Error fetching academy data:", error);
            }
        };

        fetchData();
    }, []);

    let baseURL = 'https://api.sellinb.io';

    return (
        <div className="py-3">
            <div className="title-holder d-flex align-items-center ps-xl-3 mb-4">
                <div className="icon-holder d-flex align-items-center">
                    <BiTime size="20" className="gray-text-color"/>
                </div>
                <div className="gray-text-color px-2">Learn so you can Earn</div>
            </div>
            <div className="ps-xl-3">
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={50}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                >
                    {academyData.length > 0 ? (
                        academyData.map((academy) => (
                            <SwiperSlide key={academy.id}>
                                <div
                                    className="card-video-box video-holder text-center text-xl-start c-pointer position-relative"
                                    onClick={() => {
                                        if (academy.accessible) {
                                            props.setShowOne(true);
                                            props.setAcademyVideoData({
                                                title: academy.title,
                                                name: academy.name,
                                                description: academy.description,
                                                video_url: academy.video_url,
                                            });
                                        } else {
                                            handleShow()
                                        }
                                    }}
                                >
                                    {academy.access_type === 'Pro' && (
                                        <div className="badge pro">Pro</div>
                                    )}
                                    {academy.access_type === 'Premium' && (
                                        <div className="badge premium">Premium</div>
                                    )}
                                    {academy.video_cover ? (
                                        <img
                                            height="210"
                                            width="440"
                                            style={{objectFit: "cover"}}
                                            src={`${baseURL}/thedss/academy/${academy.video_cover}`}
                                            alt="Video Cover"
                                            className="object-fit-cover mx-auto mx-xl-0 rounded-2"
                                        />
                                    ) : (
                                        <img
                                            height="210"
                                            width="440"
                                            style={{objectFit: "cover"}}
                                            src={DefaultEbookBg}
                                            alt="Video Cover"
                                            className="object-fit-cover mx-auto mx-xl-0 rounded-2"
                                        />
                                    )}
                                    <div className="d-xl-flex p-1">
                                        <div className="d-flex align-items-center me-3 d-none d-xl-flex">
                                            <FcClapperboard size="40" className="gray-bg-color p-2 rounded-3"/>
                                        </div>
                                        <div>
                                            <div className="black-text-color fw-bold-600 mt-2 mb-0">
                                                {academy.title}
                                            </div>
                                            <div className="text-black-50 fw-bold-500 small mb-1">
                                                {academy.name}
                                            </div>
                                            <div className="gray-text-color small">
                                                {`${academy.description.substring(0, 45)}${academy.description.length > 45 ? '...' : ''}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <></>
                    )}
                </Swiper>
            </div>
            <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="py-3 upgrade-holder">
                        <div className="text-center fw-bold-600 main-dark-color mb-2">
                            Want to view Pro & Premium Trainings?
                        </div>
                        <Link to="/user/pricing"
                              className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                            <div className="d-flex align-items-center justify-content-center px-1">
                                <BiLockOpen size="18"/>
                            </div>
                            <div className="px-1 small fw-bold-500">Upgrade Now</div>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default ForYou;