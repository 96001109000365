// import "./auth.css"
import React, {useEffect, useId} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {accountService, orderService} from "../../_services";
import {useState} from "react";
import {Link} from "react-router-dom";

import iconLogo from "../../assets/images/global/Inbio_logo.png"
import {FaCircleCheck} from "react-icons/fa6";
import {ThreeDots} from "react-loader-spinner";
import {useQuery} from "react-query";

const ThankYou = () => {
    useEffect(() => {
        document.title = "Sellinbio - Thank You";
    }, []);

    const [notVerified, setNotVerified] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const navigate = useNavigate();
    const username = localStorage.getItem('paid_to_username');

    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => {
        if (transactionId) {
            orderService.verifyTransaction(transactionId).then(res => {
                if (res && res.status && res.status === 'order_status_success') {
                    localStorage.setItem('paid_to_username', res.username);
                    setNotVerified(false)
                } else {
                    navigate('/')
                }
            })
        }
    });

    if (transactionId && notVerified) {
        return (
            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        )
    }
    if (username) {
        return (
            <div className="authentication-page h-100">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-8 col-xl-6">
                            <div
                                className="authentication-content bg-white px-1 px-md-2 py-3 py-md-4 rounded-3 shadow-lg">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form p-3 p-lg-4 p-xxl-5">
                                            <div
                                                className="text-center mb-1 d-flex justify-content-center align-items-center">
                                                <Link to="/home"><img width="150" src={iconLogo} className="img-fluid"
                                                                      alt="Sellinbio"/></Link>
                                            </div>
                                            <div className="text-center my-3"><FaCircleCheck size="40" color="#21C45C"/>
                                            </div>
                                            <div className="text-center black-text-color fw-bold fs-3">Thank You for
                                                Your
                                                Purchase!
                                            </div>
                                            <div className="text-center mb-4 gray-text-color">
                                                We're delighted you've chosen to purchase from us. Your transaction was
                                                successful.
                                            </div>
                                            <div className="text-center mb-4 gray-text-color small">
                                                Please check your email inbox for further instructions on accessing your
                                                purchased product. If you don't see the email in your inbox, please
                                                check
                                                your spam or junk folder.
                                            </div>
                                            <div className="text-center">
                                                <Link className="link-as-button px-5 py-2 rounded-3"
                                                      to={`/${username}`}>Return to store</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            navigate('/home', {replace: true})
        );
    }

}

export default ThankYou;