import ReactApexChart from 'react-apexcharts';

const ColumnChart = (props) => {
    const bg_color = "#aca6ff";
    const series = props.series;
    const categories = props.categories;

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    fontSize: '10px',
                    colors: '#74767e',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#74767e'],
                    fontSize: '11px',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            colors: [bg_color],
            opacity: 1,
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                borderRadiusApplication: 'top',
            }
        },
        tooltip: {
            enabled: true
        }
    };

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" height="350"/>
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ColumnChart;