import "./auth.css"
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {accountService} from "../../_services";
import iconLogo from "../../assets/images/global/icon_inbio.png";
import {BiData, BiLock, BiLogoInstagram, BiLogoTiktok, BiMailSend, BiUser} from "react-icons/bi";
import 'react-phone-number-input/style.css'
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';


const Signup = () => {
    useEffect(() => {
        document.title = "Sellinbio - Sign Up";
    }, []);

    let navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({
        username: '',
        full_name: '',
        email: '',
        tiktok_username: '',
        instagram_username: '',
        phone_number: '',
        password: '',
    });

    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/home', {replace: true})
            );
        }
    }, [navigate]);

    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("username", userDetails.username);
        formData.append("full_name", userDetails.full_name);
        formData.append("email", userDetails.email);
        formData.append("tiktok_username", userDetails.tiktok_username);
        formData.append("instagram_username", userDetails.instagram_username);
        formData.append("phone_number", phoneNumber);
        formData.append("password", userDetails.password);
        accountService.signup(formData)
            .then(res => {
                if (res.data.status === 201) {
                    accountService.login({
                        "email": userDetails.email,
                        "password": userDetails.password
                    })
                        .then(res => {
                            accountService.saveToken(res.data.jwt);
                            navigate('/user/dashboard', {replace: true});
                        })
                        .catch(error => console.log(error))
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="authentication-page h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-8 col-xl-6">
                        <div className="authentication-content bg-white px-1 px-lg-2 py-3 rounded-3 shadow-lg">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form p-3">
                                        <div
                                            className="text-center mb-1 d-flex justify-content-center align-items-center">
                                            <Link to="/home"><img width="75" src={iconLogo} className="img-fluid"
                                                                  alt="Sellinbio"/></Link>
                                        </div>
                                        <div className="text-center black-text-color fw-bold fs-5 mb-3">Hey! let’s
                                            monetize your following!
                                        </div>
                                        <form onSubmit={onSubmit} encType="multipart/form-data">
                                            <div className="row m-0">
                                                <div className="col-lg-6 p-0 pe-lg-2">
                                                    <div className="form-group mb-3 position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                            <BiData size="25" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text" className="form-control" required
                                                               placeholder="Your username" name="username" id="username"
                                                               value={userDetails.username} onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 p-0 ps-lg-2">
                                                    <div className="form-group mb-3 position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                            <BiUser size="25" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text" className="form-control"
                                                               placeholder="Your full name" name="full_name"
                                                               id="full_name" required
                                                               value={userDetails.full_name} onChange={onChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 position-relative">
                                                <div
                                                    className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                    <BiMailSend size="25" color="#b7b7b7"/>
                                                </div>
                                                <input type="text" className="form-control" required
                                                       placeholder="Your email address" name="email" id="email"
                                                       value={userDetails.email} onChange={onChange}/>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-lg-6 p-0 pe-lg-2">
                                                    <div className="form-group mb-3 position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                            <BiLogoTiktok size="25" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text" className="form-control"
                                                               placeholder="Tiktok username (Optional)"
                                                               name="tiktok_username" id="tiktok_username"
                                                               value={userDetails.tiktok_username} onChange={onChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 p-0 ps-lg-2">
                                                    <div className="form-group mb-3 position-relative">
                                                        <div
                                                            className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                            <BiLogoInstagram size="25" color="#b7b7b7"/>
                                                        </div>
                                                        <input type="text" className="form-control"
                                                               placeholder="Instagram username (Optional)"
                                                               name="instagram_username" id="instagram_username"
                                                               value={userDetails.instagram_username}
                                                               onChange={onChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <PhoneInput
                                                    className="custom-phone-input"
                                                    defaultCountry="ua"
                                                    value={phoneNumber}
                                                    onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                                                />
                                            </div>
                                            <div className="form-group mb-3 position-relative">
                                                <div
                                                    className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                                    <BiLock size="25" color="#b7b7b7"/>
                                                </div>
                                                <input type="password" className="form-control"
                                                       placeholder="Your Password" required
                                                       name="password" id="pass" value={userDetails.password}
                                                       onChange={onChange}/>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit"
                                                        className="btn btn-block submit-button w-100 fw-bold">
                                                    Create My Free Account
                                                </button>
                                            </div>
                                        </form>
                                        <div className="terms-text mt-3 text-center gray-text-color small px-3">
                                            By signing up, you agree to our <Link to="#"
                                                                                  className="terms-link main-text-color text-decoration-underline fw-bold">Terms
                                            of
                                            Service</Link> and <Link to="#"
                                                                     className="terms-link main-text-color text-decoration-underline fw-bold">Privacy
                                            Policy.</Link>
                                        </div>
                                        <div className="new-account mt-3">
                                            <p className="gray-text-color">Already have an account?
                                                <Link to="/auth/login" className="auth-link fw-bold"> Sign in</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;