import {Link} from "react-router-dom";
import React, {useId} from "react";
import {useQuery} from "react-query";
import {accountService} from "../../../_services";
import {BiSolidCircle} from "react-icons/bi";
import {ThreeDots} from "react-loader-spinner";
import {Tab, Tabs} from "react-bootstrap";
import SubscriptionHistory from "./SubscriptionHistory";

const formatDate = (dateString, type) => {
    const date = new Date(dateString);
    if (type === 'short') {
        const options = {month: 'long', day: 'numeric'};
        return date.toLocaleDateString('en-US', options);
    } else {
        const options = {month: 'long', day: 'numeric', year: 'numeric'};
        return date.toLocaleDateString('en-US', options);
    }
};

const BillingSection = (props) => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => accountService.getUserSubscriptionData());

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <div className="billing-section bg-white pt-4 pb-3 px-4 rounded-3 light-shadow">
            {props.user_access === "free_access" && (
                <div className="pb-2">
                    <div className="title fs-5 black-text-color fw-bold-500 mb-2">
                        No valid subscription found!
                    </div>
                    <div className="gray-text-color">
                        Don't miss out on premium features and exclusive content.
                        <Link to="/user/pricing"
                              className="black-text-color fw-bold"
                              style={{width: "fit-content"}}>
                            <span> Upgrade to Pro </span>
                        </Link>
                        and elevate your experience!
                    </div>
                </div>
            )}
            {props.user_access === "pro_access" && (
                <div className="pb-2">
                    <div className="title fs-5 main-dark-color fw-bold-600 mb-2">
                        Pro Subscription
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center justify-content-center">
                            <BiSolidCircle size="15" color="#30c2a6"/>
                        </div>
                        <div className="gray-text-color small ps-1 pe-2 border-end">Active</div>
                        <div className="gray-text-color small ps-2">Next belling
                            on {`${formatDate(data.end_date, 'short')}`}</div>
                    </div>
                </div>
            )}
            {props.user_access === "premium_access" && (
                <div className="pb-2">
                    <div className="title fs-5 main-dark-color fw-bold-600 mb-2">
                        Premium Subscription
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center justify-content-center">
                            <BiSolidCircle size="15" color="#30c2a6"/>
                        </div>
                        <div className="gray-text-color small ps-1 pe-2 border-end">Active</div>
                        <div className="gray-text-color small ps-2">Next belling
                            on {`${formatDate(data.end_date, 'short')}`}</div>
                    </div>
                </div>
            )}
            <div className="secondary-custom-tabs-holder mt-4">
                <Tabs
                    variant="pills"
                    defaultActiveKey={`${props.user_access === "free_access" ? 'history' : 'details'}`}
                    className="mb-3"
                >
                    <Tab eventKey="details" title="Details" disabled={props.user_access === "free_access"}>
                        {props.user_access !== "free_access" && (
                            <div className="row">
                                <div className="col-6 col-xl-3 mb-3">
                                    <div>
                                        <div className="gray-text-color text-real-small">
                                            Start date
                                        </div>
                                        <div className="main-dark-color small">
                                            {`${formatDate(data.start_date, 'tall')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-3 mb-3">
                                    <div>
                                        <div className="gray-text-color text-real-small">
                                            End date
                                        </div>
                                        <div className="main-dark-color small">
                                            {`${formatDate(data.end_date, 'tall')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-3 mb-3">
                                    <div>
                                        <div className="gray-text-color text-real-small">
                                            Amount
                                        </div>
                                        <div className="main-dark-color small">
                                            ${data.price} / month
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-3 mb-3">
                                    <div>
                                        <div className="gray-text-color text-real-small">
                                            Payments
                                        </div>
                                        <div className="main-dark-color small">
                                            {data.payment_method.charAt(0).toUpperCase() + data.payment_method.slice(1)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="history" title="History">
                        <SubscriptionHistory/>
                    </Tab>
                    <Tab eventKey="settigns" title="Settings">
                        <div className="alert alert-danger text-center">HERE MUST BE CHANGE CURRENCY</div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default BillingSection;