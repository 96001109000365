import Axios from "../ServiceCaller";

let getAcademyCategories = async () => {
    const { data } = await Axios.get("/academy/categories");
    return data.AcademyCategories;
};
let getAcademyVideos = async (category_id) => {
    const { data } = await Axios.get(`/academy/${category_id}`);
    return data.AcademyVideos;
};
export const academyService = {
    getAcademyCategories,
    getAcademyVideos,
};