import {EditDesignSection, Footer, FreeAccessAlert, Header, StoreSection} from "./Private";
import {Tab, Tabs} from "react-bootstrap";
import {BiStore} from "react-icons/bi";
import {MdOutlineDesignServices} from "react-icons/md";

const Store = () => {

    function CustomTabStore() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><BiStore className="tab-icon"/></div>
                <div className="tab-title px-1">Store</div>
            </div>
        );
    }

    function CustomTabDesign() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1"><MdOutlineDesignServices className="tab-icon"/></div>
                <div className="tab-title px-1">Edit Design</div>
            </div>
        );
    }

    return (
        <>
            <Header title="Store"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="my-store-page py-3 px-lg-3 rounded-3">
                    <div className="main-custom-tabs-holder">
                        <Tabs
                            variant="pills"
                            defaultActiveKey="store"
                            className="mb-3"
                        >
                            <Tab eventKey="store" title={<CustomTabStore/>}>
                                <StoreSection/>
                            </Tab>
                            <Tab eventKey="editDesign" title={<CustomTabDesign/>}>
                                <EditDesignSection/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default Store;